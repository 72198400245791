body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.__react_component_tooltip.show {
  opacity: 1 !important;
}

/* Load custom fonts */
@font-face {
  font-family: 'Londrina Solid';
  src: url(/static/media/LondrinaSolid-Black.ec88da3c.ttf);
  src: url(/static/media/LondrinaSolid-Regular.0d5e1206.ttf);
}
@font-face {
  font-family: 'PT Root UI';
  src: url(/static/media/PT-Root-UI_Regular.51b7ac2a.woff2) format('woff2'),
    url(/static/media/PT-Root-UI_Regular.c8b6805d.woff) format('woff');
}
@font-face {
  font-family: 'PT Root UI';
  font-weight: 500;
  src: url(/static/media/PT-Root-UI_Medium.942457a3.woff2) format('woff2'),
    url(/static/media/PT-Root-UI_Medium.dab54de6.woff) format('woff');
}

@font-face {
  font-family: 'PT Root UI';
  font-weight: bold;
  src: url(/static/media/PT-Root-UI_Bold.b951c0ef.woff2) format('woff2'),
    url(/static/media/PT-Root-UI_Bold.3028ce78.woff) format('woff');
}

:root {
  --brand-bg-green: #edf2f0;
  --brand-dark-red: #d63c5e;
  --brand-light-green: #6da886;
  --brand-black: #212529;
  /* Colors from Figma  */
  --brand-cool-background: #d5d7e0;
  --brand-cool-border: rgb(189, 192, 207);
  --brand-cool-dark-text: #151c3b;
  --brand-cool-light-text: #79809c;
  --brand-cool-accent: #e9ebf3;
  --brand-warm-background: #d5d7e0;
  --brand-warm-border: rgb(207, 189, 186);
  --brand-warm-dark-text: #221b1a;
  --brand-warm-light-text: #8f7e7c;
  --brand-warm-accent: #f9f1f1;
  --brand-gray-dark-text: #14161b;
  --brand-gray-border: #e2e3eb;
  --brand-gray-background: #f4f4f8;
  --brand-gray-light-text: #8c8d92;
  --brand-gray-light-text-translucent: rgb(140, 141, 146, 0.1);
  --brand-gray-hover: #fafafb;
  --brand-color-red: #e40536;
  --brand-color-blue: #4965f0;
  --brand-color-green: #43b369;
  --brand-color-red-translucent: rgba(214, 60, 94, 0.1);
  --brand-color-blue-translucent: rgba(73, 101, 240, 0.1);
  --brand-color-green-translucent: rgba(67, 179, 105, 0.1);
}

.App_wrapper__2Rh_f {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-width: 100vw;
  overflow-x: hidden;
}

@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-xl,
  .container-xxl {
    max-width: 1140px !important;
  }
}

body {
  overflow-x: hidden;
}
.Modal_backdrop__3FAPo {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: rgba(0, 0, 0, 0.6);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
}

.Modal_modal__P3_V5 {
  font-family: 'PT Root UI', sans-serif;
  font-weight: bold;
  position: fixed;
  top: 30vh;
  left: 10%;
  z-index: 100;
  background-color: rgba(255, 255, 255, 1);
  /*background-color: rgba(255, 255, 255, 0.6);*/
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  padding: 3rem 2rem;
  text-align: center;
  border-radius: 15px;
  left: calc(50% - 17.5rem);
  width: 35rem;
}

.Modal_modal__P3_V5 button {
  border-radius: 10px;
  min-height: 44px;
  padding-top: 7px;
  background-color: rgba(255, 255, 255, 0.7);
}

.Modal_modal__P3_V5 button:hover {
  color: black;
  background-color: rgba(255, 255, 255, 1);
}

.Modal_modal__P3_V5 h3 {
  font-size: xx-large;
  font-weight: bold;
}

.Modal_content__1VIbZ {
  padding: 1rem;
  max-height: 50vh;
  overflow-y: auto;
}

.Modal_modal__P3_V5 .Modal_closeButton__840KP {
  position: absolute;
  top: 2rem;
  right: 2rem;
  border: 0 none;
  background-color: transparent;
  width: 40px;
  height: 40px;
  padding-top: 2px;
}

.Modal_modal__P3_V5 .Modal_closeButton__840KP img {
  height: 1rem;
  width: 1rem;
  opacity: 0.5;
  transition: all 0.125s ease-in-out;
  margin-top: -4px;
}

.Modal_modal__P3_V5 .Modal_closeButton__840KP img:hover {
  opacity: 1;
}

.Modal_modal__P3_V5 button img {
  margin-top: -2px;
  width: 1.5rem;
  height: auto;
}

@media (max-width: 992px) {
  .Modal_modal__P3_V5 {
    top: auto;
    left: 0;
    bottom: 0;
    width: 100%;
    max-height: 100%;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.NavBar_navBarBrand__1hw3w {
  position: relative;
  z-index: 2;
  /*padding: 1rem 0rem;*/
  padding: 0.5rem 0rem;
  transition: all 0.15s ease-in-out;
}

.NavBar_navBarBrand__1hw3w:hover {
  transform: scale(0.95);
}

.NavBar_navBarLogo__XETFz {
  width: 300px;
  /*height: 75px;*/
}

.NavBar_navBarCustom__3zRE7 {
  padding-bottom: 0.5rem; /*1rem*/
}

@media (max-width: 992px) {
  .NavBar_navBarLogo__XETFz {
    width: 150px;
    /*height: 65px;*/
  }

  .NavBar_navBarCustom__3zRE7 {
    margin-bottom: -1px;
    margin-right: -5px;
    padding-bottom: 0rem;
  }
}

/* connect button displays differently to default NavBarItems, thus the !importants */
.NavBar_connectBtn__1uOYH {
  cursor: pointer;
}

.NavBar_connectBtn__1uOYH:hover,
.NavBar_connectBtn__1uOYH:active,
.NavBar_connectBtn__1uOYH:focus {
  cursor: pointer !important;
}

.NavBar_disconnectBtn__1B1Vs {
  cursor: pointer;
  font-family: 'PT Root UI';
  font-weight: bold;
}

.NavBar_disconnectBtn__1B1Vs:hover,
.NavBar_disconnectBtn__1B1Vs:active,
.NavBar_disconnectBtn__1B1Vs:focus {
  cursor: pointer !important;
}

.NavBar_greenStatusCircle__1zBA7 {
  display: relative;
  width: 10px;
  height: 10px;
  background-color: #7fc59c;
  border-radius: 50%;
  margin-right: -30px;
  margin-left: 20px;
  margin-top: -20px;
  z-index: 3;
}

.NavBar_nounsNavLink__1_qpP,
.NavBar_nounsNavLink__1_qpP:active,
.NavBar_nounsNavLink__1_qpP:visited {
  font-family: 'PT Root UI';
  font-weight: bold;
  font-size: 0.9rem;
  padding: 0.3rem 0.3rem !important;
  color: black !important;
}

.NavBar_testnetImg__322vL {
  width: auto;
  height: 45px;
}

.NavBar_addressNavLink__2TScO {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.NavBar_brandAndTreasuryWrapper__VzR5S {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
}

.NavBar_navBarToggle__1JXBT {
  border-radius: 10px;
  height: 44px;
  padding: 0.25rem 0.5rem;
  margin-right: 12px;
}

.NavBarButton_wrapper__3Q7Yp {
  height: 40px;
  border-radius: 10px;
  font-size: 16px;
  line-height: 16px;
  font-family: 'PT Root UI';
  font-weight: bold;
  padding: 0px 12px 0px 12px;
  transition: all 0.125s ease-in-out;
  box-shadow: none !important;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.NavBarButton_button__1lFYe {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.NavBarButton_icon__2DPCd {
  margin-right: 0.4rem;
}

.NavBarButton_icon__2DPCd > svg {
  max-height: 14px;
  opacity: 0.5;
}

@media (max-width: 992px) {
  .NavBarButton_icon__2DPCd > svg {
    max-height: 17px;
  }
}

.NavBarButton_btnEnabled__3wJVe {
  cursor: pointer;
}

.NavBarButton_btnDisabled__3pkkp {
  cursor: not-allowed;
}

.NavBarButton_coolInfo__1VN2i {
  border: 1px solid var(--brand-cool-border);
  border-radius: 10px;
  color: var(--brand-cool-dark-text);
}

.NavBarButton_coolInfo__1VN2i:hover {
  background-color: var(--brand-cool-accent);
  color: black;
}

.NavBarButton_coolWallet__WExa4 {
  background-color: var(--brand-cool-accent);
  border: 1px solid var(--brand-cool-border);
  border-radius: 10px;
  color: var(--brand-warm-dark-text);
  padding: 0px 12px 0px 12px;
  transition: all 0.2s ease-in-out;
}

.NavBarButton_coolWallet__WExa4:hover {
  background-color: var(--brand-color-blue);
  color: white;
  filter: brightness(110%);
}

.NavBarButton_warmInfo__8leyD {
  border: 1px solid var(--brand-warm-border);
  border-radius: 10px;
  color: var(--brand-warm-dark-text);
}

.NavBarButton_warmInfo__8leyD:hover {
  background-color: var(--brand-warm-accent);
  color: black;
}

.NavBarButton_warmWallet__7g2Ta {
  background-color: var(--brand-warm-accent);
  border: 1px solid var(--brand-warm-border);
  border-radius: 10px;
  color: var(--brand-warm-dark-text);
  padding: 0px 12px 0px 12px;
  transition: all 0.2s ease-in-out;
}

.NavBarButton_warmWallet__7g2Ta:hover {
  background-color: var(--brand-color-red);
  color: white;
  filter: brightness(110%);
}

.NavBarButton_whiteInfo__1Zu8n,
.NavBarButton_whiteWallet__2ykTg {
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.1);
  color: rgb(95, 95, 95);
}

.NavBarButton_whiteInfo__1Zu8n:hover,
.NavBarButton_whiteWallet__2ykTg:hover {
  background-color: #e2e3e8;
  color: black;
}

.NavBarButton_whiteActive__3FVcf {
  background-color: #f4f4f8;
  color: black;
}

.NavBarButton_whiteActive__3FVcf .NavBarButton_icon__2DPCd {
  color: #d63c5e;
}

.NavBarButton_whiteActiveVoteSubmit__47nTT {
  background-color: #e2e3e8;
  color: black;
}

@media (max-width: 992px) {
  .NavBarButton_wrapper__3Q7Yp,
  .NavBarButton_button__1lFYe {
    height: 48px;
    font-size: 18px;
  }
}

.NavBarButton_delegateBack__PfKU8 {
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.1);
  color: var(--brand-cool-dark-text);
  width: 100px;
}

.NavBarButton_delegateBack__PfKU8:hover {
  background-color: #e2e3e8;
  color: var(--brand-cool-dark-text);
}

.NavBarButton_delegatePrimary__X54kM {
  background-color: var(--brand-cool-dark-text);
  color: white;
  width: 315px;
}

.NavBarButton_delegatePrimary__X54kM:hover {
  filter: brightness(200%);
}

.NavBarButton_delegateSecondary__1RBXu {
  background-color: var(--brand-color-blue);
  color: white;
  width: 315px;
  border: none;
}

.NavBarButton_delegateSecondary__1RBXu:hover {
  filter: brightness(200%);
}

.NavBarButton_delegateDisabled__oFcrN {
  background-color: var(--brand-color-blue);
  opacity: 0.5;
  color: white;
  width: 315px;
  border: none;
}

@media (max-width: 992px) {
  .NavBarButton_delegatePrimary__X54kM {
    width: 210px;
  }

  .NavBarButton_delegateSecondary__1RBXu {
    width: 210px;
  }

  .NavBarButton_delegateDisabled__oFcrN {
    width: 210px;
  }
}

.NavBarTreasury_wrapper__1utbs {
  height: 40px;
  border-radius: 10px;
  font-size: 16px;
  font-family: 'PT Root UI';
  font-weight: bold;
  padding: 0px 10px 0px 10px;
  transition: all 0.125s ease-in-out;
  box-shadow: none;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.NavBarTreasury_button__2dw3R {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.NavBarTreasury_treasuryHeader__1nJhV {
  font-size: 16px;
  margin-left: 0.25rem;
  margin-right: 0.4rem;
  margin-top: 1px;
}

.NavBarTreasury_warmInfo__XAMmy .NavBarTreasury_icon__1x2yt {
  color: #b6a9a7;
  border-left: 1px solid #e1d7d5;
}

.NavBarTreasury_treasuryBalance__3phNO {
  font-size: 16.5px;
  letter-spacing: 0.3px;
  margin-left: 0.4rem;
  margin-right: 0.25rem;
}

@media (max-width: 400px) {
  .NavBarTreasury_wrapper__1utbs {
    margin-left: -0.5rem;
    padding: 0px 7px 0px 6px;
  }

  .NavBarTreasury_treasuryHeader__1nJhV {
    display: none;
  }
}

.NavBarTreasury_warmInfo__XAMmy .NavBarTreasury_treasuryBalance__3phNO {
  color: var(--brand-warm-dark-text);
}

.NavBarTreasury_coolInfo__ABgBg .NavBarTreasury_treasuryBalance__3phNO {
  color: var(--brand-cool-dark-text);
}

.NavBarTreasury_treasuryHeader__1nJhV {
  opacity: 0.5;
}

.NavBarTreasury_button__2dw3R:hover {
  cursor: pointer;
}

.NavBarTreasury_coolInfo__ABgBg {
  border: 1px solid var(--brand-cool-border);
  border-radius: 10px;
  color: var(--brand-cool-dark-text);
}

.NavBarTreasury_coolInfo__ABgBg:hover {
  background-color: var(--brand-cool-accent);
  color: black;
}

.NavBarTreasury_warmInfo__XAMmy {
  border: 1px solid var(--brand-warm-border);
  border-radius: 10px;
  color: var(--brand-warm-light-text);
}

.NavBarTreasury_warmInfo__XAMmy:hover {
  background-color: var(--brand-warm-accent);
  color: var(--brand-warm-dark-text);
  color: black;
}

.NavBarTreasury_whiteInfo__3nH1I {
  background: white;
}

.NavBarTreasury_whiteTreasuryHeader__1EEHp {
  opacity: 0.5;
}

.NavBarTreasury_whiteInfo__3nH1I:hover {
  background-color: #e2e3e8;
}

.NavWallet_icon__2NlNR {
  min-width: 24px;
  margin-right: 0.5rem;
}

/* connect button displays differently to default NavBarItems, thus the !importants */
.NavWallet_connectBtn__1Hs3c {
  cursor: pointer;
}

.NavWallet_connectBtn__1Hs3c:hover,
.NavWallet_connectBtn__1Hs3c:active,
.NavWallet_connectBtn__1Hs3c:focus {
  cursor: pointer !important;
}

.NavWallet_connectContentMobileWrapper__2jXfy {
  margin-left: 0.25rem;
  margin-top: 0.3rem;
}

.NavWallet_connectContentMobileText__2Pvey {
  font-family: 'PT Root UI';
  font-weight: bold;
  font-size: 18px;
  margin-top: 1rem;
  height: 2rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.NavWallet_disconnectText__2P9P3 {
  color: var(--brand-color-red) !important;
}

.NavWallet_disconnectText__2P9P3:hover {
  color: var(--brand-color-red) !important;
  transition: all 0.125s ease-in-out;
  cursor: pointer;
}

.NavWallet_mobileSwitchWalletText__1h6E9 {
  margin-right: 0.25rem;
  padding-right: 0.25rem;
}

.NavWallet_mobileProfileText__KAjbn {
  margin-right: 0.25rem;
  padding-right: 0.25rem;
}

.NavWallet_mobileProfileText__KAjbn a {
  text-decoration: none;
  color: inherit;
}

.NavWallet_dropDownTop__3nX2O {
  border-radius: 10px 10px 0px 0px;
  padding-top: 0.65rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  justify-content: flex-start;
  margin-left: 4px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  transition: all 0.125s ease-in-out;
}

.NavWallet_dropDownTop__3nX2O:hover,
.NavWallet_dropDownMiddle__1vSBZ:hover {
  color: black;
}

.NavWallet_dropDownMiddle__1vSBZ {
  padding-top: 0.65rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  justify-content: flex-start;
  margin-left: 4px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  transition: all 0.125s ease-in-out;
}

.NavWallet_dropDownMiddle__1vSBZ a {
  text-decoration: none;
  color: inherit;
}

.NavWallet_dropDownBottom__TDZAX {
  border-radius: 0px 0px 10px 10px;
  padding-bottom: 0.65rem;
  padding-top: 0.5rem;
  padding-left: 1rem;
  justify-content: flex-start;
  color: var(--brand-color-red);
  margin-left: 4px;
}

.NavBarDropdown_wrapper__UJDod {
  height: 40px;
  border-radius: 10px;
  font-size: 16px;
  line-height: 16px;
  font-family: 'PT Root UI';
  font-weight: bold;
  padding: 0px 0px 0px 14px;
  transition: all 0.125s ease-in-out;
  box-shadow: none;
}

.NavBarDropdown_whiteInfo__2hIXV {
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.1);
  color: rgb(95, 95, 95);
}

.NavBarDropdown_whiteInfo__2hIXV:hover {
  background-color: #e2e3e8;
  color: black;
}

.NavBarDropdown_coolInfo__Mao73 {
  color: #79809c;
  background-color: #e9ebf3;
}

.NavBarDropdown_coolInfo__Mao73:hover {
  background-color: white;
  color: black;
}

.NavBarDropdown_warmInfo__3ARDf {
  color: #b6a9a7;
  background-color: #fdf9f9;
}

.NavBarDropdown_warmInfo__3ARDf:hover {
  background-color: white;
  color: black;
}

.NavBarDropdown_whiteInfoSelected__12CKB {
  background-color: #f4f4f8;
  border: 1px solid rgba(0, 0, 0, 0.1);
  color: #8c8d92;
}

.NavBarDropdown_whiteInfoSelectedTop__Jso35 {
  background-color: #f4f4f8;
  border-top: 1.5px solid #e2e3e8;
  border-right: 1.5px solid #e2e3e8;
  border-left: 1.5px solid #e2e3e8;
  color: #8c8d92;
}

.NavBarDropdown_whiteInfoSelectedBottom__3KxVO {
  background-color: #f4f4f8;
  border-bottom: 1.5px solid #e2e3e8;
  border-right: 1.5px solid #e2e3e8;
  border-left: 1.5px solid #e2e3e8;
  color: #8c8d92;
}

.NavBarDropdown_dropdownActive__34pY_ {
  background-color: white;
  color: black;
}
.NavBarDropdown_coolInfoSelected__3olh3 {
  color: #79809c;
  background-color: #e9ebf3;
}

.NavBarDropdown_warmInfoSelected__1oOa5 {
  background-color: #fdf9f9;
  color: rgba(142, 129, 127, 1);
}

.NavBarDropdown_button__1LFFf {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  padding-right: 14px;
}

.NavBarDropdown_dropdownBtnContent__3pBbO {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  min-height: 18px;
  line-height: 20px;
  color: black !important;
}

.NavBarDropdown_arrowDown__8N96i {
  margin-bottom: 0.25rem;
  margin-left: 0.5rem;
}

.NavBarDropdown_arrowUp__369rR {
  margin-left: 0.5rem;
  margin-top: 0.5rem;
}

.NavBarDropdown_button__1LFFf:hover {
  cursor: pointer;
}

.NavBarDropdown_nounsNavLink__2asPe,
.NavBarDropdown_nounsNavLink__2asPe:active,
.NavBarDropdown_nounsNavLink__2asPe:visited {
  font-family: 'PT Root UI';
  font-weight: bold;
  font-size: 0.9rem;
  padding: 0.3rem 0.3rem !important;
  color: var(--brand-black) !important;
  transition: all 0.125s ease-in-out;
}

.NavBarDropdown_dropdownPrimaryText__er63w:hover {
  background-color: white;
  transition: all 0.125s ease-in-out;
  cursor: pointer;
}

.NavBarDropdown_desktopDropdown__1NHoC {
  background-color: inherit !important;
  border: 0px !important;
}

@media (max-width: 992px) {
  .NavBarDropdown_wrapper__UJDod,
  .NavBarDropdown_button__1LFFf {
    height: 48px;
    font-size: 18px;
  }
}

@media (max-width: 330px) {
  .NavBarDropdown_button__1LFFf {
    width: 70px;
  }
}

@media (max-width: 370px) {
  .NavBarDropdown_button__1LFFf {
    width: 90px;
  }
}

@media (min-width: 400px) {
  .NavBarDropdown_button__1LFFf {
    width: auto;
  }
}

.WalletButton_walletButton__Q_aK0 {
  border: none;
  margin: 5px;
  padding: 5px 20px;
  border-radius: 5px;
  background-color: rgba(211, 211, 211, 0.664);
  color: var(--brand-black);
  border: none;
}
.WalletButton_walletButton__Q_aK0 img {
  margin-right: 10px;
  border-radius: 5px;
}
.WalletButton_walletButton__Q_aK0:hover,
.WalletButton_walletButton__Q_aK0:active,
.WalletButton_walletButton__Q_aK0:focus,
.WalletButton_walletButton__Q_aK0:disabled {
  outline: none !important;
  box-shadow: none !important;
  background-color: lightgray !important;
  color: var(--brand-dark-green);
  color: white;
}

.WalletConnectModal_clickable__2UYOs {
  cursor: pointer;
}

.WalletConnectModal_walletConnectData__1PDIT {
  font-size: 15px;
  padding-top: 1.5rem;
  padding-bottom: 0rem;
  margin-bottom: -1.25rem;
  opacity: 0.5;
  transition: all 0.15s ease-in-out;
}

.WalletConnectModal_walletConnectData__1PDIT:hover {
  opacity: 1;
}

.ResponsiveUIUtils_mobileOnly__1tQCt {
  display: none !important;
}

@media (max-width: 1200px) {
  .ResponsiveUIUtils_desktopOnly__2Fw6J {
    display: none;
  }

  .ResponsiveUIUtils_mobileOnly__1tQCt {
    display: inherit !important;
  }
}

@media (max-width: 414px) {
  .ResponsiveUIUtils_disableSmallScreens__1rorn {
    display: none;
  }
}

.Footer_wrapper__1UzXs {
  display: flex;
  flex: 1 1;
}

.Footer_container__st7Zp {
  display: flex;
}

.Footer_footerSignature__3KHrj {
  margin: auto auto 0 auto;
  font-family: 'PT Root UI' !important;
  font-weight: 500;
  font-size: 18px;
  padding-bottom: 4rem;
  padding-top: 2rem;
  color: var(--brand-black) !important;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-start;
}

.Footer_footerSignature__3KHrj a {
  text-decoration: none;
  color: black;
  margin: 8px 14px;
  transition: all 0.15s ease-in-out;
}

.Footer_footerSignature__3KHrj a:hover {
  text-decoration: none;
  font-weight: bold;
}

.Footer_footerSignature__3KHrj img {
  width: 32px;
  height: 32px;
  margin: 0 8px;
}

@media (max-width: 992px) {
  .Footer_footerSignature__3KHrj a {
    font-size: 16px;
  }
}

@keyframes Footer_slidein__1VljP {
  from {
    margin-left: 100%;
    width: 300%;
  }

  to {
    margin-left: 0%;
    width: 100%;
  }
}

.Link_link__3euZY,
.Link_link__3euZY:hover,
.Link_link__3euZY:active {
  color: var(--brand-dark-red);
}
.Link_link__3euZY:hover {
  text-decoration: underline;
}

.Banner_wrapper__1YbFc h1 {
  font-family: 'Londrina Solid';
  font-size: 5rem;
}

.Banner_bannerSection__15FiP h4 {
  font-family: 'PT Root UI';
  font-weight: bold;
  font-size: 16px;
  opacity: 1;
  color: rgb(95, 95, 95);  
  margin-bottom: 0px !important;
}

.Banner_bannerSection__15FiP h2 {
  font-family: 'PT Root UI';
  font-weight: bold;
  font-size: 28px;
  margin-bottom: 0px !important;
}

.Banner_bannerSection__15FiP {
  padding: 0 !important;
}

.Banner_homeSection__1Gw9W {
  padding: 0 !important;
}

.Banner_bannerRow__3WhT2 {
  justify-content: center;
}

.Banner_bannerSection__15FiP img {
  /*width: auto;*/
}

.Banner_sectionHeader__1CXjM {
  font-family: 'Londrina Solid';
  font-weight: bold;
  font-size: 24px;
  opacity: 1;
  color: rgb(95, 95, 95);  
}

.Banner_sectionFooter__2Zurf a {
  text-decoration: none;
  font-weight: bold;
}

.Banner_bannerItems__3jQRI {
	max-width: 150px;
	text-align: center;
}

@media (max-width: 992px) {
  .Banner_wrapper__1YbFc {
    padding: 2rem;
  }

  .Banner_wrapper__1YbFc h1 {
    font-size: 3.75rem;
  }  
}

@media (min-width: 992px) {
  .Banner_wrapper__1YbFc h1 {
    font-size: 6rem;
    margin-left: 2rem;
  }
  
  .Banner_homeSection__1Gw9W {
  	padding: 0 !important;
  }
}
.Section_container__1zQTr {
  padding: 2rem 0rem 0rem 0rem;
}

.ComposableItemCollectionRow_collectionRows__HJWst {
}

.ComposableItemCollectionRow_collectionRow__1MI9q {
  border: 1px solid #e2e3e8;
  margin: 0.5rem 0;
  border-radius: 12px;  
  padding: 0;
  min-height: 350px;
}

.ComposableItemCollectionRow_itemGroup__1NYrI {
  /*
  padding-right: 5px;
  padding-left: 5px;
  */
  padding: 5px;
}
.ComposableItemCollectionRow_itemGroup__1NYrI a {
  font-size: 1rem;
}

.ComposableItemCollectionRow_itemGroup__1NYrI img {
  /*border-radius: 50%;*/
  width: 50%;
  max-width: 100px;

  border: 1px solid #000;
  box-shadow: 2px 5px 5px 2px rgb(0 0 0 / 25%);
  border-radius: 12px;  
}

.ComposableItemCollectionRow_itemGroup__1NYrI {
  margin-bottom: 1rem;
  text-align: center;
  /* 
  margin: 0.5rem;
  border: 1px solid #000;
  box-shadow: 2px 5px 5px 2px rgb(0 0 0 / 25%);
  border-radius: 12px;  
  */
}
.ComposableItemCollectionRow_itemGroup__1NYrI a:hover svg {
  fill: var(--brand-dark-red);
}
.ComposableItemCollectionRow_itemGroup__1NYrI img {
  margin-bottom: 0.5rem;
}

.ComposableItemCollectionRow_twitterIcon__cY_KR {
  width: 20px;
  color: #aaa;
  margin-right: 5px;
}

.ComposableItemCollectionRow_card__u6mTq {
  border: none;
}

.ComposableItemCollectionRow_card__u6mTq,
.ComposableItemCollectionRow_headerWrapper__3oe62 {
  font-size: 1.3rem;
}
.ComposableItemCollectionRow_cardHeader__2t0pX {
  border: none;
  background-color: transparent;
  font-family: 'Londrina Solid';
  font-size: 2.5rem;
  cursor: pointer;
}

.ComposableItemCollectionRow_cardImage__3eGzo {
	height: 125px;
	object-fit: cover;
}

.ComposableItemCollectionRow_cardTitle__sJMbw {
    margin-bottom: 0;
    font-size: 1.25rem;
    font-weight: bold;
    padding-bottom: 0.25rem;
}

.ComposableItemCollectionRow_cardText__1nQt6 {
    padding-top: 0;
}

.ComposableItemCollectionRow_primaryBtnItem__2N9gs {
  margin-top: 10px;
  font-size: small;
}

.ComposableItemCollectionRow_primaryBtnItem__2N9gs, .ComposableItemCollectionRow_primaryBtnCollection__3JBt4 {
  width: 50%;
  max-width: 100px;
  font-weight: bold;
  border-radius: 12px;
  background-color: var(--brand-dark-red);
  border: var(--brand-dark-red);
}

.ComposableItemCollectionRow_primaryBtnItem__2N9gs:focus,
.ComposableItemCollectionRow_primaryBtnItem__2N9gs:hover,
.ComposableItemCollectionRow_primaryBtnCollection__3JBt4:focus,
.ComposableItemCollectionRow_primaryBtnCollection__3JBt4:hover 
{
  background-color: var(--brand-dark-red) !important;
  box-shadow: 0 0 0 0.2rem rgb(214, 60, 94, 0.75);
}

.ComposableItemCollectionRow_primaryBtnItem__2N9gs:active,
.ComposableItemCollectionRow_primaryBtnCollection__3JBt4:active {
  background-color: var(--brand-dark-red) !important;
}

.ComposableItemCollectionRow_primaryBtnItem__2N9gs:disabled,
.ComposableItemCollectionRow_primaryBtnCollection__3JBt4:disabled {
  color: var(--brand-gray-dark-text) !important;
  background-color: var(--brand-gray-background) !important;
  border-color: var(--brand-gray-border) !important;  
  border-style: solid;
}


.ComposableItemCollectionRow_traitForm__5NJyI {
  height: 4rem;
}
.ComposableItemCollectionRow_traitFormBtn__jTBUl {
  height: 100% !important;
  width: 100%;
  margin: 0.5rem 0;
  border-radius: 12px;
  background-color: white !important;
  border-color: #e2e3e8 !important;
  font-size: 18px;
  font-weight: bold;
  color: #14161b;
}
.ComposableItemCollectionRow_traitFormBtn__jTBUl:hover,
.ComposableItemCollectionRow_traitFormBtn__jTBUl:focus {
  border-color: #e2e3e8 !important;
  background-color: #f4f4f8 !important;
  box-shadow: none !important;
}
.ComposableItemCollectionRow_floatingLabel__uNV1f,
.ComposableItemCollectionRow_fileUpload__1jBUH {
  font-size: 15px;
  color: #8c8d92;
}
.ComposableItemCollectionRow_nounYearsFooter__3MZLS {
  font-style: italic;
}
.ComposableItemCollectionRow_nounImg__36Cy9 {
  border-radius: 16px;
}
.ComposableItemCollectionRow_nounImg__36Cy9:hover {
  cursor: pointer;
  transform: scale(1.01);
}
.ComposableItemCollectionRow_nounWrapper__6R7xz {
  margin-bottom: 1rem;
}

.ComposableItemCollectionRow_spinner__1NKhN {
  display: flex;
  justify-content: center;
  color: var(--brand-gray-light-text);
}

@media (max-width: 992px) {
  .ComposableItemCollectionRow_collectionRow__1MI9q {
  	min-height: 235px;
  }

  .ComposableItemCollectionRow_collectionRowItems__1NNJr {
    display: none;
    visibility: hidden;
  }
}

.ComposableItemCard_collectionRows__27aGy {
}

.ComposableItemCard_collectionRow__2fRoB {
  border: 1px solid #e2e3e8;
  margin: 0.5rem 0;
  border-radius: 12px;  
  padding: 0.5rem;
  min-height: 250px;
}

.ComposableItemCard_itemGroup__3T8BE {
  /*
  padding-right: 5px;
  padding-left: 5px;
  */
  padding: 5px;
}
.ComposableItemCard_itemGroup__3T8BE a {
  font-size: 1rem;
}

.ComposableItemCard_itemGroup__3T8BE img {
  /*border-radius: 50%;*/
  width: 50%;
  max-width: 100px;

  border: 1px solid #000;
  box-shadow: 2px 5px 5px 2px rgb(0 0 0 / 25%);
  border-radius: 12px;  
}

.ComposableItemCard_itemGroup__3T8BE {
  margin-bottom: 0.5rem;
  text-align: center;
  /* 
  margin: 0.5rem;
  border: 1px solid #000;
  box-shadow: 2px 5px 5px 2px rgb(0 0 0 / 25%);
  border-radius: 12px;  
  */
}
.ComposableItemCard_itemGroup__3T8BE a:hover svg {
  fill: var(--brand-dark-red);
}
.ComposableItemCard_itemGroup__3T8BE img {
  margin-bottom: 0.5rem;
}

.ComposableItemCard_itemGroup__3T8BE img:hover {
  cursor: pointer;
  transform: scale(1.1);
}

.ComposableItemCard_twitterIcon__2PxD0 {
  width: 20px;
  color: #aaa;
  margin-right: 5px;
}

.ComposableItemCard_card__1u5fb {
  border: none;
}

.ComposableItemCard_card__1u5fb,
.ComposableItemCard_headerWrapper__4kAKS {
  font-size: 1.3rem;
}
.ComposableItemCard_cardHeader__2JKgT {
  border: none;
  background-color: transparent;
  font-family: 'Londrina Solid';
  font-size: 2.5rem;
  cursor: pointer;
}

.ComposableItemCard_cardTitle__3fRPV {
    margin-bottom: 0;
    font-size: 0.75rem;
}

.ComposableItemCard_cardText__15P_z {
    padding-top: 0;
}

.ComposableItemCard_primaryBtnItem__SJht5 {
  margin-top: 10px;
  font-size: small;
}

.ComposableItemCard_primaryBtnItem__SJht5, .ComposableItemCard_primaryBtnCollection__W1R96 {
  width: 50%;
  max-width: 100px;
  font-weight: bold;
  border-radius: 12px;
  background-color: var(--brand-dark-red);
  border: var(--brand-dark-red);
}

.ComposableItemCard_primaryBtnItem__SJht5:focus,
.ComposableItemCard_primaryBtnItem__SJht5:hover,
.ComposableItemCard_primaryBtnCollection__W1R96:focus,
.ComposableItemCard_primaryBtnCollection__W1R96:hover 
{
  background-color: var(--brand-dark-red) !important;
  box-shadow: 0 0 0 0.2rem rgb(214, 60, 94, 0.75);
}

.ComposableItemCard_primaryBtnItem__SJht5:active,
.ComposableItemCard_primaryBtnCollection__W1R96:active {
  background-color: var(--brand-dark-red) !important;
}

.ComposableItemCard_primaryBtnItem__SJht5:disabled,
.ComposableItemCard_primaryBtnCollection__W1R96:disabled {
  color: var(--brand-gray-dark-text) !important;
  background-color: var(--brand-gray-background) !important;
  border-color: var(--brand-gray-border) !important;  
  border-style: solid;
}


.ComposableItemCard_traitForm__dGO_f {
  height: 4rem;
}
.ComposableItemCard_traitFormBtn__sxwVz {
  height: 100% !important;
  width: 100%;
  margin: 0.5rem 0;
  border-radius: 12px;
  background-color: white !important;
  border-color: #e2e3e8 !important;
  font-size: 18px;
  font-weight: bold;
  color: #14161b;
}
.ComposableItemCard_traitFormBtn__sxwVz:hover,
.ComposableItemCard_traitFormBtn__sxwVz:focus {
  border-color: #e2e3e8 !important;
  background-color: #f4f4f8 !important;
  box-shadow: none !important;
}
.ComposableItemCard_floatingLabel__2Irzr,
.ComposableItemCard_fileUpload__252w- {
  font-size: 15px;
  color: #8c8d92;
}
.ComposableItemCard_nounYearsFooter__2MrNU {
  font-style: italic;
}
.ComposableItemCard_nounImg__1as0e {
  border-radius: 16px;
}
.ComposableItemCard_nounImg__1as0e:hover {
  cursor: pointer;
  transform: scale(1.01);
}
.ComposableItemCard_nounWrapper__2f4Qz {
  margin-bottom: 1rem;
}
.ComposableItemModal_modal__hvRDD {
  position: fixed;
  top: 10vh;
  z-index: 100;
  padding: 1rem;
  border-radius: 12px;
  left: calc(50% - 15rem);
  width: 30rem;
  
  height: 450px;
  background: white;
  overflow: hidden;
}

.ComposableItemModal_modal__hvRDD .ComposableItemModal_title__3BN2D {
  font-family: 'Londrina Solid';
  display: flex;
  flex-direction: column;
}

.ComposableItemModal_modal__hvRDD .ComposableItemModal_title__3BN2D h2 {
  font-size: 24px;
  margin-top: 0.5rem;
  color: rgba(140, 141, 146, 1);
}

.ComposableItemModal_modal__hvRDD .ComposableItemModal_title__3BN2D h1 {
  line-height: 25px;
  height: 2rem;
  font-size: 42px;
}

.ComposableItemModal_formSection__1cFAJ {
	/*margin: 5px;*/
}

.ComposableItemModal_imageSection__demj4 img {
  border: 1px solid #000;
  box-shadow: 2px 5px 5px 2px rgb(0 0 0 / 25%);
  border-radius: 12px;  
}


.ComposableItemModal_primaryBtn__3kvMz {
  width: 50%;
}

.ComposableItemModal_primaryBtn__3kvMz {
  font-weight: bold;
  margin-bottom: 0.5rem;
  border-radius: 12px;
  background-color: var(--brand-dark-red);
  border: var(--brand-dark-red);
}
.ComposableItemModal_primaryBtn__3kvMz:focus,
.ComposableItemModal_primaryBtn__3kvMz:hover
{
  background-color: var(--brand-dark-red) !important;
  box-shadow: 0 0 0 0.2rem rgb(214, 60, 94, 0.75);
}

.ComposableItemModal_primaryBtn__3kvMz:active {
  background-color: var(--brand-dark-red) !important;
}

.ComposableItemModal_primaryBtn__3kvMz:disabled {
  color: var(--brand-gray-dark-text) !important;
  background-color: var(--brand-gray-background) !important;
  border-color: var(--brand-gray-border) !important;  
  border-style: solid;
}


.ComposableItemModal_displayNounFooter__v-UB2 {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ComposableItemModal_displayNounFooter__v-UB2 span {
  color: white;
  font-weight: bold;
  margin-bottom: 1rem;
}
.ComposableItemModal_displayNounFooter__v-UB2 button {
  width: 50%;
  background: rgba(255, 255, 255, 0.3);
  border: none;
}

.ComposableItemModal_displayNounFooter__v-UB2 button:hover,
.ComposableItemModal_displayNounFooter__v-UB2 button:active,
.ComposableItemModal_displayNounFooter__v-UB2 button:focus {
  background: rgba(255, 255, 255, 0.4) !important;
  box-shadow: none !important;
}

.ComposableItemModal_nounContainer__1n72J {
  margin-top: 10px;
}

.ComposableItemModal_nounImg__3PmE8 {
  border-radius: 16px;
}
.ComposableItemModal_nounWrapper__2eUpu {
  margin-bottom: 0.1rem;
}
.ComposableItemModal_nounOuterWrapper__1k8s4 {
  font-size: small;
  margin-bottom: 0.5rem;
}

.ComposableItemModal_nounImg__3PmE8:hover {
  cursor: pointer;
  transform: scale(1.01);
}

@media (max-width: 992px) {
  .ComposableItemModal_modal__hvRDD {
    width: 80% !important;
    left: 10% !important;
  }
}

.ShortAddress_shortAddress__16h3M {
  display: flex;
  flex-flow: row nowrap;
  grid-gap: 6px;
  gap: 6px;
  align-items: center;
}

.ShortAddress_shortAddressLink__2ljwB {
  display: flex;
  flex-flow: row nowrap;
  grid-gap: 6px;
  gap: 6px;
  align-items: center;
  margin-bottom: 5px;
}


.ShortAddress_shortAddress__16h3M > div {
  padding-top: -2px;
}

.ShortAddress_shortAddress__16h3M, .ShortAddress_shortAddressLink__2ljwB span {
  letter-spacing: 0.2px;
  font-family: 'PT Root UI';
  font-weight: bold;
}

.ShortAddress_shortAddress__16h3M, .ShortAddress_shortAddressLink__2ljwB a {
  text-decoration: none;
  color: inherit;
}

.Noun_img__1GJxo {
  -ms-interpolation-mode: nearest-neighbor;
      image-rendering: pixelated;
  image-rendering: -moz-crisp-edges;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  vertical-align: middle;
}

.Noun_imgWrapper__1EdkS {
  position: relative;
  padding-top: 100%;
  width: 100%;
  height: 0;
}

.Noun_circular__2GVbf {
  border-radius: 50%;
}

.Noun_delegateViewCircular__16he3 {
  border-radius: 50%;
  margin-top: 13px;
}

.Noun_circleWithBorder__3BxRG {
  border-radius: 50%;
  border: 2px solid #ffffff;
}

.Noun_rounded__185en {
  border-radius: 15px;
}

.Noun_circularNounWrapper__-CM3i {
  width: 42px;
  height: 42px;
}

.Noun_delegateViewCircularNounWrapper__2lP2w {
  width: 42px;
  height: 42px;
  margin-left: 12px;
}

@media (max-width: 1200px) {
  .Noun_circularNounWrapper__-CM3i {
    height: 70%;
    width: 70%;
  }
}

.StandaloneNoun_clickableNoun__15g6a {
  cursor: pointer;
}

.AuctionActivity_wrapper__3UpQR {
  padding: 1rem 1rem;
  color: var(--brand-black);
  align-self: flex-start !important;
}

.AuctionActivity_informationRow__2BOSj {
  margin-bottom: 0.5rem;
}

.AuctionActivity_activityRow__1xuKY {
  margin-bottom: 0rem;
}

h1 {
  font-size: 3rem;
}

h2 {
  font-size: 2.5rem;
}

h4 {
  font-size: 1rem;
  font-weight: 400;
}

.AuctionActivity_modalHeader__2pkxA {
  border-bottom: none;
  justify-content: flex-start;
  align-items: center;
}

.AuctionActivity_modalTitleWrapper__2w2pt {
  color: var(--brand-black);
}

.AuctionActivity_modalTitleWrapper__2w2pt h1 {
  font-size: xx-large;
  margin-left: 2rem;
}

.AuctionActivity_modalHeader__2pkxA button {
  align-self: flex-start;
}

.AuctionActivity_modalHeaderNounImgWrapper__3boIZ {
  width: 150px;
  height: 150px;
}

.AuctionActivity_currentBidCol__3vgXb {
  border-right: 1px solid #79809c49;
  margin-left: 6px;
  margin-top: 6px;
}

.AuctionActivity_auctionTimerCol__2oKfX {
  padding-left: 0rem;
}

.AuctionActivity_section__hyo3B h4 {
  font-family: 'PT Root UI';
  font-size: 17px;
  line-height: 25px;
}

.AuctionActivity_section__hyo3B h2 {
  font-family: 'PT Root UI';
  font-weight: bold;
  font-size: 32px;
}

@media (max-width: 992px) {
  h1 {
    padding-top: 12px;
  }

  .AuctionActivity_wrapper__3UpQR {
    padding: 1rem;
  }

  .AuctionActivity_auctionTimerCol__2oKfX {
    margin-left: 6px;
    margin-top: 6px;
    padding-left: 0rem;
  }

  .AuctionActivity_currentBidCol__3vgXb {
    border-right: 0px;
    padding-left: 0rem;
  }

  .AuctionActivity_fomoNounsLink__1DC-a {
    padding-left: 14px;
  }
}

@media (max-width: 400px) {
  .AuctionActivity_fomoNounsLink__1DC-a {
    font-size: 14px;
  }
}

/* Fix Firefox navigation arrow alignment issues */
@-moz-document url-prefix() {
  .AuctionActivity_colAlignCenter__3SaC2 {
    display: flex;
    align-items: center;
  }
}

.AuctionActivity_fomoNounsLink__1DC-a {
  margin-top: 0rem;
  margin-bottom: 1rem;
  transition: 0.2s ease-in-out;
  display: none;
  visibility: hidden;
}

.AuctionActivity_fomoNounsLink__1DC-a svg {
  opacity: 0.6;
}

.AuctionActivity_fomoNounsLink__1DC-a a,
.AuctionActivity_fomoNounsLink__1DC-a:active a {
  font-family: 'PT Root UI', sans-serif;
  font-weight: 500;
  padding-left: 0.5rem;
  text-decoration: none;
  opacity: 1;
  color: rgb(95, 95, 95);
}

.AuctionActivity_fomoNounsLink__1DC-a:hover,
.AuctionActivity_fomoNounsLink__1DC-a:hover a {
  text-decoration: none;
  color: black;
  opacity: 1;
}

.BidHistory_altWrapper__a-MQ4 {
  text-align: center;
}

.BidHistory_bidCollection__2FxcB {
  display: grid;
  text-align: start;
  list-style-type: none;
  grid-row-gap: 0.5rem;
  row-gap: 0.5rem;
  padding: 0;
  margin-bottom: 8px;
  margin-top: 1rem;
}

.BidHistory_bidRowCool__1YU1Y {
  font-family: 'PT Root UI', sans-serif;
  padding: 0.8rem;
  border-bottom: 1px solid var(--brand-cool-border);
  font-size: 1.1rem;
  font-weight: bold;
  transition: all 0.2s ease-in-out;
}

.BidHistory_bidRowWarm__1W2Fo {
  font-family: 'PT Root UI', sans-serif;
  padding: 0.25rem 0.75rem 0.5rem 0.75rem;
  border-bottom: 1px solid var(--brand-warm-border);
  font-size: 0.95rem;
  font-weight: bold;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
}

.BidHistory_bidRowWarm__1W2Fo a {
  color: var(--brand-color-red) !important;
  font-weight: bold;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
}

.BidHistory_bidRowCool__1YU1Y a {
  color: var(--brand-color-blue) !important;
  font-weight: bold;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
}

.BidHistory_bidRowWarm__1W2Fo a:hover,
.BidHistory_bidRowCool__1YU1Y a:hover {
  filter: brightness(110%);
}

.BidHistory_bidRowCool__1YU1Y:hover,
.BidHistory_bidRowWarm__1W2Fo:hover {
  filter: brightness(105%);
}

.BidHistory_bidItem__13g5O {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.BidHistory_leftSectionWrapper__2T29z {
  display: flex;
  flex-direction: column;
}

.BidHistory_bidder__1hPgQ {
  font-family: 'PT Root UI';
  font-weight: bold;
}

.BidHistory_bidder__1hPgQ > div > div {
  font-family: 'PT Root UI';
  font-weight: bold;
  font-size: 18px;
}

.BidHistory_bidRowCool__1YU1Y .BidHistory_bidder__1hPgQ > div > div {
  color: var(--brand-cool-dark-text);
}

.BidHistory_bidRowWarm__1W2Fo .BidHistory_bidder__1hPgQ > div > div {
  color: var(--brand-warm-dark-text);
}

.BidHistory_bidDate__32l9k {
  display: none;
  color: gray;
}

.BidHistory_rightSectionWrapper__3oem5 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.BidHistory_bidAmount__7W-Hz {
  font-family: 'PT Root UI';
  font-weight: bold;
  margin-right: 1rem;
  font-size: 18px;
  padding-top: 2px;
}

.BidHistory_bidRowCool__1YU1Y .BidHistory_bidAmount__7W-Hz {
  color: var(--brand-cool-dark-text);
}

.BidHistory_bidRowWarm__1W2Fo .BidHistory_bidAmount__7W-Hz {
  color: var(--brand-warm-dark-text);
}

.BidHistory_linkSymbol__29ypX {
  color: var(--brand-cool-light-text);
}

.BidHistory_linkSymbol__29ypX:hover {
  color: var(--brand-cool-dark-text);
}

@media (max-width: 992px) {
  .BidHistory_bidRow__bc1Zf {
    border-radius: 10px;
  }

  .BidHistory_bidAmount__7W-Hz .BidHistory_linkSymbol__29ypX .BidHistory_bidder__1hPgQ {
    font-size: 16px;
  }
}

.Bid_bidWrapper__1NsAz {
  margin: 0;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
}

.Bid_bidBtn__2MzFj {
  font-family: 'PT Root UI';
  border-radius: 12px !important;
  margin-left: 0.6rem !important;
  margin-top: 3px;
  width: auto;
  padding: 10px 16px;
  height: 3rem;
  color: white;
  border: transparent;
  background-color: var(--brand-black);
  font-weight: bold;
  letter-spacing: normal;
  font-size: 19px;
  transition: all 0.2s ease-in-out;
}

.Bid_bidBtn__2MzFj:disabled {
  cursor: not-allowed;
}

.Bid_bidBtn__2MzFj:hover,
.Bid_bidBtn__2MzFj:active,
.Bid_bidBtn__2MzFj:focus .Bid_bidBtn__2MzFj:disabled {
  outline: none !important;
  box-shadow: none !important;
  background-color: #2125298a;
}

.Bid_bidBtn__2MzFj:disabled {
  background-color: gray !important;
}

.Bid_minBidCopy__1WI1j {
  padding-top: 0;
  font-size: small;
  margin-left: 0.25rem;
  margin-bottom: 0.3rem;
}

.Bid_bidInput__39un5 {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  width: auto;
  height: 54px;
  color: black;
  border-radius: 12px !important;
  box-shadow: inset 0px 0px 0px 1px #fff;
  background-color: white;
  outline: none !important;
  box-shadow: none !important;
  font-family: 'PT Root UI';
  font-weight: bold;
  font-size: 25px;
  transition: all 0.2s ease-in-out;
  border: none;
}

.Bid_bidInput__39un5:focus {
  box-shadow: inset 0px 0px 0px 1px var(--brand-cool-dark-text) !important;
}

.Bid_customPlaceholder__3KOvn {
  color: var(--brand-cool-light-text);
  font-family: 'PT Root UI';
  font-weight: bold;
  z-index: 3;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.Bid_customPlaceholderBidAmt__3s0HL {
  position: absolute;
  opacity: 0.3;
  top: 15%;
  left: 3%;
  color: var(--brand-cool-light-text);
  font-family: 'PT Root UI';
  font-weight: bold;
  font-size: 25px;
  z-index: 1;
  pointer-events: none;
}

.Bid_activityRow__ltD0z .Bid_form-floating__n80tb {
  display: flex !important;
  flex-flow: row nowrap !important;
  justify-content: space-between !important;
}

.Bid_bidBtnAuctionEnded__1zL5T {
  width: 100%;
  height: 3rem;
  color: white;
  border: transparent;
  background-color: var(--brand-black);
  font-weight: bold;
  font-family: 'PT Root UI';
  font-size: 18px;
  border-radius: 10px;
}

.Bid_bidBtnAuctionEnded__1zL5T:hover,
.Bid_bidBtnAuctionEnded__1zL5T:active,
.Bid_bidBtnAuctionEnded__1zL5T:focus,
.Bid_bidBtnAuctionEnded__1zL5T:disabled {
  background-color: gray !important;
  color: rgb(209, 207, 207);
  outline: none !important;
  box-shadow: none;
}

.Bid_bidInputAuctionEnded__3sHIA {
  display: none;
}

.Bid_bidInput__39un5:focus {
  outline: none;
}

/* REMOVE UP/DOWN ARROWS FROM INPUT */
/* Chrome, Safari, Edge, Opera */
.Bid_bidInput__39un5::-webkit-outer-spin-button,
.Bid_bidInput__39un5::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.Bid_bidInput__39un5[type='number'] {
  -moz-appearance: textfield;
}

.Bid_voteForNextNounBtnWrapper__1PquX {
  display: none;
  visibility: hidden;
}

@media (max-width: 992px) {
  .Bid_bidInput__39un5 {
    border: none;
    border: 1px solid #8c8d9275;
  }

  .Bid_customPlaceholder__3KOvn {
    left: 40%;
  }
  .Bid_customPlaceholderBidAmt__3s0HL {
    left: 3.5%;
    top: 16%;
  }
  .Bid_emergencySettleWrapper__3occd {
    text-align: center;
  }

  .Bid_voteForNextNounBtnWrapper__1PquX {
    width: 100%;
  }
}

.Bid_emergencySettleButton__2qTtx {
  color: var(--brand-dark-red);
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
}

@media (max-width: 992px) {
  .SettleManuallyBtn_emergencySettleWrapper__1_H1Q {
    text-align: center;
    margin-left: 0.5rem;
  }
}

@media (max-width: 660px) {
  .SettleManuallyBtn_emergencySettleWrapper__1_H1Q {
    text-align: center;
    margin-left: 0rem;
  }
}

.SettleManuallyBtn_emergencySettleButton__2ov3p {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
}

.SettleManuallyBtn_emergencySettleButton__2ov3p:disabled {
  color: #8c8d92;
  text-decoration: none;
  cursor: default;
}

.SettleManuallyBtn_emergencySettleWrapper__1_H1Q {
  padding-top: 0;
  margin-bottom: 0;
}
.AuctionTimer_section__1mqHL span {
  font-size: 3rem;
  font-weight: bold;
  font-family: 'Londrina Solid';
}

.AuctionTimer_section__1mqHL h4 {
  font-family: 'PT Root UI';
  font-weight: bold;
  font-size: 18px;
}

.AuctionTimer_section__1mqHL h2 {
  font-family: 'PT Root UI';
  font-weight: bold;
  font-size: 32px;
  margin-bottom: 0px !important;
  margin-top: 3px;
}

.AuctionTimer_wrapper__FLota {
  padding-left: 2.5rem;
  padding-right: 0rem;
  cursor: pointer;
  margin-top: 0.3rem;
  width: -webkit-max-content;
  width: max-content;
}

.AuctionTimer_timerWrapper__3c10Z {
  display: flex;
  margin-top: 1px;
}

.AuctionTimer_timerSection__2RlJK span {
  font-family: 'PT Root UI';
  font-weight: bold;
  font-size: 32px;
}

.AuctionTimer_timerSection__2RlJK {
  margin-right: 0.5rem;
}

.AuctionTimer_timerSectionFinal__ZRiyN {
  margin-right: 0rem;
}

.AuctionTimer_timerSectionFinal__ZRiyN span {
  font-family: 'PT Root UI';
  font-weight: bold;
  font-size: 32px;
}

.AuctionTimer_leftCol__2xmED {
  margin-top: 1px;
}

@media (max-width: 992px) {
  .AuctionTimer_section__1mqHL h4 {
    font-size: 18px;
    margin-bottom: 0px;
    margin-top: 6px;
  }

  .AuctionTimer_section__1mqHL h2 {
    font-size: 23px;
  }

  .AuctionTimer_section__1mqHL {
    justify-content: space-between;
  }

  .AuctionTimer_wrapper__FLota {
    width: auto;
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
  }

  .AuctionTimer_leftCol__2xmED {
    margin-top: 0px;
    padding-left: 0.5rem;
  }

  .AuctionTimer_timerSection__2RlJK span {
    font-size: 23px;
  }

  .AuctionTimer_timerSectionFinal__ZRiyN span {
    font-size: 23px;
  }

  .AuctionTimer_title__1TwqG {
    font-size: 18px;
    margin-top: 1.4rem;
  }

  .AuctionTimer_timeLeft__-MeOt {
    padding-right: 0.5rem;
  }
}

.CurrentBid_section__2oRi6 span {
  font-size: 3rem;
  font-weight: bold;
  font-family: 'Londrina Solid';
}

.CurrentBid_section__2oRi6 h4 {
  font-family: 'PT Root UI';
  font-weight: bold;
  font-size: 18px;
}

.CurrentBid_section__2oRi6 h2 {
  font-family: 'PT Root UI';
  font-weight: bold;
  font-size: 32px;
  margin-bottom: 0px !important;
  margin-top: 3px;
}

.CurrentBid_wrapper__2-jFi {
  padding-left: 0rem;
  padding-right: 0rem;
}

@media (max-width: 992px) {
  .CurrentBid_section__2oRi6 h4 {
    font-size: 18px;
    margin-bottom: 0px;
    margin-top: 6px;
  }

  .CurrentBid_section__2oRi6 h2 {
    font-size: 23px;
  }

  .CurrentBid_section__2oRi6 {
    justify-content: space-between;
  }

  .CurrentBid_currentBid__3k-Hq {
    margin-right: 0.5rem;
  }

  .CurrentBid_wrapper__2-jFi {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }

  .CurrentBid_leftCol__39uuw {
    padding-left: 0.5rem;
  }
}

.Winner_wrapper__3kdqN {
  margin-left: 0.5rem;
  margin-top: 2px;
  padding-left: 1.5rem;
}

.Winner_youCopy__4J8BH {
  margin-top: 0.25rem;
}

.Winner_winnerCopy__QFc-n {
  min-width: 250px;
}

.Winner_link__1Xu57,
.Winner_link__1Xu57:hover,
.Winner_link__1Xu57:active {
  color: black;
  text-decoration: none;
  display: flex;
  cursor: pointer;
}

.Winner_verifyButton__2tAXA {
  height: 3rem;
  color: white;
  border: transparent;
  background-color: var(--brand-black);
  font-family: 'PT Root UI';
  font-weight: bold;
  border-radius: 8px;
  font-size: 16px;
  
  display: none;
  visibility: hidden;
}

.Winner_verifyButton__2tAXA:hover,
.Winner_verifyButton__2tAXA:active,
.Winner_verifyButton__2tAXA:focus,
.Winner_verifyButton__2tAXA:disabled {
  background-color: gray !important;
  outline: none !important;
  box-shadow: none !important;
}

.Winner_section__saqU7 h4 {
  font-family: 'PT Root UI';
  font-size: 18px;
  line-height: 27px;
}

.Winner_section__saqU7 h2 {
  font-family: 'PT Root UI';
  font-weight: bold;
  font-size: 32px;
}

.Winner_leftCol__2JIIV {
  font-family: 'PT Root UI';
}

.Winner_leftCol__2JIIV h4 {
  font-weight: bold;
}

@media (max-width: 992px) {
  .Winner_section__saqU7 h4 {
    font-size: 18px;
  }

  .Winner_section__saqU7 h2 {
    font-size: 23px;
  }

  .Winner_section__saqU7 {
    justify-content: space-between;
  }

  .Winner_wrapper__3kdqN {
    margin-top: 0px;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .Winner_leftCol__2JIIV {
    padding-left: 0.5rem;
  }

  .Winner_winnerContent__2GSn2 {
    margin-right: 0.5rem;
  }

  .Winner_wrapper__3kdqN {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }

  .Winner_verifyButton__2tAXA {
    width: 100%;
    height: 3rem;
    border-radius: 10px;
    font-weight: bold;
    font-size: 18px;
  }

  .Winner_verifyLink__2gbHQ {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    display: none;
    visibility: hidden;
  }

  .Winner_youCopy__4J8BH {
    padding-right: 0.25rem;
  }
}

.Tooltip_hover__3UWp4 {
  border-radius: 8px !important;
}

.AuctionNavigation_navArrowsContainer__3hTLA {
  position: absolute;
}

.AuctionNavigation_leftArrowCool__ZifA- {
  -webkit-appearance: none;
  padding: 0;
  display: inline-block;
  width: 2rem;
  height: 2rem;
  border: none;
  background-size: contain;
  background-repeat: no-repeat;
  font-size: large;
  border-radius: 50%;
  color: var(--brand-cool-dark-text);
  background-color: var(--brand-cool-accent);
  font-weight: bold;
}

.AuctionNavigation_rightArrowCool__z7pDj {
  -webkit-appearance: none;
  padding: 0;
  margin-left: 0.3rem;
  display: inline-block;
  width: 2rem;
  height: 2rem;
  border: none;
  background-size: contain;
  background-repeat: no-repeat;
  font-size: large;
  color: var(--brand-cool-dark-text);
  background-color: var(--brand-cool-accent);
  border-radius: 50%;
  font-weight: bold;
}

.AuctionNavigation_leftArrowWarm__1kQgX {
  -webkit-appearance: none;
  padding: 0;
  display: inline-block;
  width: 2rem;
  height: 2rem;
  border: none;
  background-size: contain;
  background-repeat: no-repeat;
  font-size: large;
  border-radius: 50%;
  color: var(--brand-warm-dark-text);
  background-color: var(--brand-warm-accent);
  font-weight: bold;
}

.AuctionNavigation_rightArrowWarm__3ZiRq {
  -webkit-appearance: none;
  padding: 0;
  margin-left: 0.3rem;
  display: inline-block;
  width: 2rem;
  height: 2rem;
  border: none;
  background-size: contain;
  background-repeat: no-repeat;
  font-size: large;
  color: var(--brand-warm-dark-text);
  background-color: var(--brand-warm-accent);
  border-radius: 50%;
  font-weight: bold;
}

/* Disable hover on mobile because it was leading to
buttons looking disabled when they were not */
@media (min-width: 992px) {
  .AuctionNavigation_leftArrowWarm__1kQgX:hover,
  .AuctionNavigation_leftArrowCool__ZifA-:hover,
  .AuctionNavigation_rightArrowWarm__3ZiRq:hover,
  .AuctionNavigation_rightArrowCool__z7pDj:hover {
    opacity: 0.9;
    background-color: var(--brand-gray-hover);
  }
}

.AuctionNavigation_leftArrowWarm__1kQgX:disabled,
.AuctionNavigation_leftArrowCool__ZifA-:disabled,
.AuctionNavigation_rightArrowWarm__3ZiRq:disabled,
.AuctionNavigation_rightArrowCool__z7pDj:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

@-moz-document url-prefix() {
  .AuctionNavigation_navArrowsContainer__3hTLA {
    margin-top: 0;
    margin-bottom: 0.5rem;
  }
}

@media (max-width: 992px) {
  .AuctionActivityWrapper_wrapper__1JNe0 {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}

.AuctionTitleAndNavWrapper_auctionTitleAndNavContainer__2dUbM {
  display: flex;
}

@media (max-width: 992px) {
  .AuctionTitleAndNavWrapper_auctionTitleAndNavContainer__2dUbM h1 {
    font-size: 2.75rem;
  }
}

/* Fix Firefox navigation arrow alignment issues */
@-moz-document url-prefix() {
  .AuctionTitleAndNavWrapper_auctionTitleAndNavContainer__2dUbM {
    display: flex;
    align-items: center;
  }
}

.AuctionActivityNounTitle_wrapper__2ia4b {
  display: inline-block;
}

.AuctionActivityNounTitle_wrapper__2ia4b h1 {
  font-family: 'Londrina Solid';
  font-size: 56px; /*68px*/
  margin-bottom: 5px; /*10px*/
  line-height: 1;
}

@media (max-width: 992px) {
  .AuctionActivityNounTitle_wrapper__2ia4b h1 {
    font-size: 56px;
  }
}

.AuctionActivityDateHeadline_date__3MSCC {
  font-family: 'PT Root UI';
  font-weight: bold;
  font-size: 17px;
  line-height: 27px;
  margin-top: 0.22rem;
}

.AuctionActivityDateHeadline_wrapper__2YbIC {
  margin-left: 5rem;
  width: auto;
}

.BidHistoryBtn_bidHistoryWrapper__3Zsy9 {
  background-color: var(--brand-cool-accent);
  display: flex;
  border-radius: 10px !important;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.BidHistoryBtn_bidHistoryWrapperCool__3tt85 {
  display: flex;
  border-radius: 10px !important;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  color: var(--brand-cool-light-text);
}

.BidHistoryBtn_bidHistoryWrapperWarm__2AkZO {
  display: flex;
  border-radius: 10px !important;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  color: var(--brand-warm-light-text);
}

.BidHistoryBtn_bidHistory__2lmSd {
  margin-left: 0.5rem;
  color: var(--brand-color-cool);
  font-family: 'PT Root UI';
  font-weight: bold;
  font-size: 1rem;
  font-size: 16px;
}

.BidHistoryBtn_bidHistoryWarm__2sAuu {
  margin-left: 0.5rem;
  color: var(--brand-color-red);
  font-family: 'PT Root UI';
  font-weight: bold;
  font-size: 1rem;
  font-size: 16px;
}

.BidHistoryBtn_bidHistoryCool__2cAxM {
  margin-left: 0.5rem;
  color: var(--brand-color-blue);
  font-family: 'PT Root UI';
  font-weight: bold;
  font-size: 1rem;
  font-size: 16px;
}

.BidHistoryBtn_bidHistoryWarm__2sAuu,
.BidHistoryBtn_bidHistoryCool__2cAxM {
  text-decoration: none;
  transition: all 0.2s ease-in-out;
}

.BidHistoryBtn_bidHistoryWarm__2sAuu:hover {
  filter: brightness(110%);
  color: var(--brand-color-red);
}

.BidHistoryBtn_bidHistoryCool__2cAxM:hover {
  filter: brightness(110%);
  color: var(--brand-color-blue);
}

.BidHistoryBtn_bidHistoryWrapperWarm__2AkZO:hover {
  color: var(--brand-color-warm);
}

.BidHistoryBtn_bidHistoryWrapperCool__3tt85:hover {
  color: var(--brand-color-blue);
}

.BidHistoryBtn_bidHistoryWrapper__3Zsy9:hover {
  background-color: var(--brand-gray-hover);
}

.NounInfoCard_nounInfoHeader__r6K6B {
  display: inline-block;
  padding-left: 0rem;
  font-family: 'Londrina Solid';
}

@media only screen and (max-width: 600px) {
  .NounInfoCard_nounInfoHeader__r6K6B {
    padding-top: 1.5rem;
    margin-bottom: 0;
  }
}

.NounInfoCard_nounInfoHeader__r6K6B h2 {
  font-size: 56px;
  margin-bottom: 18px;
}

.NounInfoCard_nounInfoHeader__r6K6B h3 {
  font-size: 24px;
  margin-bottom: '0px';
  color: #79809c;
}

.NounInfoCard_nounInfoRow__1iqd0 {
  font-family: 'PT Root UI';
  font-weight: 500;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  font-size: 18px;
  display: flex;
}

.NounInfoCard_nounInfoRowText__tGZn_ {
  padding-left: 4;
  font-family: 'PT Root UI';
  font-family: bold;
}

.NounInfoRowBirthday_nounInfoRowBirthday__LxZeH {
  font-family: 'PT Root UI';
  font-weight: bold;
  margin-left: 5px;
}

.NounInfoRowBirthday_birthdayInfoContainer__20t05 {
  display: inline;
  width: 350px;
  height: 25px;
}

.NounInfoRowBirthday_birthdayIcon__1Ud68 {
  margin-bottom: 4px;
  margin-right: 7px;
}

.NounInfoRowHolder_nounHolderEtherscanLinkCool__1dIFu {
  font-family: 'PT Root UI';
  font-weight: bold;
  color: var(--brand-color-blue);
  margin-left: 5px;
  text-decoration: none;
  transition: all 0.15s ease-in-out;
}

.NounInfoRowHolder_nounHolderEtherscanLinkWarm__3G3Ei {
  font-family: 'PT Root UI';
  font-weight: bold;
  color: var(--brand-color-red);
  margin-left: 5px;
  text-decoration: none;
  transition: all 0.15s ease-in-out;
}

.NounInfoRowHolder_nounHolderEtherscanLinkWarm__3G3Ei:hover {
  color: var(--brand-color-red) !important;
  filter: brightness(110%);
}

.NounInfoRowHolder_nounHolderEtherscanLinkCool__1dIFu:hover {
  color: var(--brand-color-blue) !important;
  filter: brightness(110%);
}

.NounInfoRowHolder_nounHolderInfoContainer__2_HY- {
  display: inline;
  width: 350px;
  height: 35px;
}

.NounInfoRowHolder_nounHolderLoading__1TOqk {
  opacity: 0.5;
}

.NounInfoRowHolder_linkIconSpan__1qf4h {
  margin-left: 5px;
}

.NounInfoRowHolder_linkIcon__2dEEO {
  margin-bottom: 5px;
}

.NounInfoRowHolder_heartIcon__2eIiK {
  margin-bottom: 4px;
  margin-right: 7px;
}

.NounInfoRowButton_nounButtonCool__1Fw_Q {
  height: 40px;
  color: var(--brand-cool-dark-text);
  background-color: var(--brand-cool-accent);
  border-radius: 10px;
  margin-right: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-family: 'PT Root UI';
  font-weight: bold;
  cursor: pointer;
  vertical-align: middle;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 10px;
  transition: all 0.15s ease-in-out;
}

.NounInfoRowButton_nounButtonWarm__10sxf {
  height: 40px;
  color: var(--brand-warm-dark-text);
  background-color: var(--brand-warm-accent);
  border-radius: 10px;
  margin-right: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-family: 'PT Root UI';
  font-weight: bold;
  cursor: pointer;
  vertical-align: middle;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 10px;
  transition: all 0.15s ease-in-out;
}

.NounInfoRowButton_nounButton__3kRR_ {
  height: 40px;
  color: var(--brand-cool-dark-text);
  background-color: var(--brand-cool-accent);
  border-radius: 10px;
  margin-right: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-family: 'PT Root UI';
  font-weight: bold;
  cursor: pointer;
  vertical-align: middle;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 10px;
  transition: all 0.15s ease-in-out;
}

.NounInfoRowButton_nounButtonCool__1Fw_Q:hover,
.NounInfoRowButton_nounButtonWarm__10sxf:hover,
.NounInfoRowButton_nounButton__3kRR_:hover {
  text-decoration: none;
  background-color: var(--brand-gray-hover);
}

.NounInfoRowButton_nounButton__3kRR_:active {
  color: black;
}

.NounInfoRowButton_nounButtonContents__33b3h {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.NounInfoRowButton_buttonIcon__RtQbH {
  margin-right: 5px;
}

.BidHistoryModal_backdrop__1Lb6Z {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: rgba(75, 75, 75, 0.5);
  -webkit-backdrop-filter: blur(24px);
          backdrop-filter: blur(24px);
}

.BidHistoryModal_content__3bPMZ {
  padding: 1rem;
  max-height: 50vh;
  overflow-y: hidden;
}

.BidHistoryModal_modal__1XM9S {
  font-family: 'PT Root UI', sans-serif;
  font-weight: bold;
  position: fixed;
  top: 15vh;
  left: 10%;
  z-index: 100;
  background-color: rgba(244, 244, 248, 1);
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.05);
  padding: 1rem;
  border-radius: 24px;
  left: calc(50% - 20rem);
  width: 40rem;
}

.BidHistoryModal_modal__1XM9S .BidHistoryModal_header__1_73Y {
  display: flex;
}

.BidHistoryModal_modal__1XM9S .BidHistoryModal_title__1Kyqj {
  font-family: 'Londrina Solid';
  display: flex;
  flex-direction: column;
}

.BidHistoryModal_modal__1XM9S .BidHistoryModal_title__1Kyqj h2 {
  font-size: 24px;
  margin-top: 0.5rem;
  color: rgba(140, 141, 146, 1);
}

.BidHistoryModal_modal__1XM9S .BidHistoryModal_title__1Kyqj h1 {
  line-height: 25px;
  height: 2rem;
  font-size: 42px;
}

.BidHistoryModal_nounWrapper__j_bT5 {
  height: 96px;
  width: 96px;
  margin-right: 1rem;
}

.BidHistoryModal_bidWrapper__2b-ho {
  background-color: rgba(224, 224, 231, 1);
  overflow-y: scroll;
  box-shadow: inset 0px -12px 16px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  margin-top: 1rem;

  height: 35vh;
  padding: 0.75rem;
}

.BidHistoryModal_bidWrapper__2b-ho ul {
  list-style: none;
  padding: 0;
}

.BidHistoryModal_closeBtnWrapper__2q7QA {
  padding: 1rem 2rem;
  display: flex;
  justify-content: flex-end;
}

.BidHistoryModal_closeBtn__1XSZ2 {
  z-index: 100;
  position: fixed;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  transition: all 0.125s ease-in-out;
  border: 0;
}

.BidHistoryModal_closeBtn__1XSZ2:hover {
  background-color: rgba(255, 255, 255, 0.5);
  cursor: pointer;
}

.BidHistoryModal_closeBtn__1XSZ2 .BidHistoryModal_icon__ioce8 {
  height: 24px;
  width: 24px;
}

.BidHistoryModal_nullStateText__3Fax- {
  font-size: 24px;
  text-align: center;
  margin-top: 3rem;
}

@media (max-width: 992px) {
  .BidHistoryModal_backdrop__1Lb6Z {
    background: rgba(0, 0, 0, 0.74);
  }

  .BidHistoryModal_content__3bPMZ {
    max-height: 100%;
    height: 100%;
  }

  .BidHistoryModal_modal__1XM9S {
    top: 5vh;
    left: 0;
    bottom: 0;
    width: 100%;
    max-height: 100%;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    background-color: transparent;
    box-shadow: none;
  }

  .BidHistoryModal_modal__1XM9S .BidHistoryModal_header__1_73Y h2 {
    color: rgba(140, 141, 146, 1);
  }

  .BidHistoryModal_modal__1XM9S .BidHistoryModal_header__1_73Y h1 {
    color: white;
    line-height: 0px;
  }

  .BidHistoryModal_bidWrapper__2b-ho {
    background-color: transparent;
    box-shadow: none;
    border-radius: 0;
    padding: 0rem;
    height: 100%;
  }

  .BidHistoryModal_nullStateText__3Fax- {
    color: white;
  }
}

.BidHistoryModalRow_bidRow___Gzh7 {
  border-radius: 14px;
  height: 72px;
  width: 100%;
  padding: 1rem;
  margin-top: 0.75rem;
  border-bottom: 0px;
  background-color: white;
}

.BidHistoryModalRow_bidderInfoWrapper__3X5Vl {
  display: flex;
}

.BidHistoryModalRow_bidderInfoText__3X6ON {
  margin-left: 0.5rem;
  display: inline-block;
  padding: 0;
  line-height: 23px;
}

.BidHistoryModalRow_bidDate__2I3JU {
  color: rgba(140, 141, 146, 1);
  font-weight: 500;
  font-family: 'PT Root UI';
  font-size: 13px;
}

.BidHistoryModalRow_trophy__1XvXd {
  margin-left: 0.5rem;
  margin-bottom: 0.1rem;
}

.BidHistoryModalRow_linkIcon__2f8xw {
  color: var(--brand-gray-light-text);
  transition: all 0.125s ease-in-out;
  margin-bottom: 0.1rem;
}

.BidHistoryModalRow_linkIcon__2f8xw:hover {
  color: black;
  cursor: pointer;
}

.BidHistoryModalRow_bidAmount__arRw- {
  white-space: nowrap;
}

.Holder_wrapper__1C3nB {
  margin-left: 0.5rem;
  margin-top: 2px;
  padding-left: 1.5rem;
}

.Holder_youCopy__2W_wo {
  margin-top: 0.25rem;
}

.Holder_holderCopy__21_3- {
  min-width: 250px;
}

.Holder_section__kmw-U h4 {
  font-family: 'PT Root UI';
  font-size: 18px;
  line-height: 27px;
}

.Holder_section__kmw-U h2 {
  font-family: 'PT Root UI';
  font-weight: bold;
  font-size: 32px;
}

.Holder_leftCol__261m5 {
  font-family: 'PT Root UI';
}

.Holder_leftCol__261m5 h4 {
  font-weight: bold;
}

.Holder_link__1MvJv,
.Holder_link__1MvJv:hover,
.Holder_link__1MvJv:active {
  color: black;
  text-decoration: none;
  display: flex;
  cursor: pointer;
}

.Holder_holderContent__2eFV8 {
  white-space: nowrap;
}

@media (max-width: 992px) {
  .Holder_section__kmw-U h4 {
    font-size: 18px;
  }

  .Holder_section__kmw-U h2 {
    font-size: 23px;
  }

  .Holder_section__kmw-U {
    justify-content: space-between;
  }

  .Holder_wrapper__1C3nB {
    margin-top: 0px;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .Holder_leftCol__261m5 {
    padding-left: 0.5rem;
  }

  .Holder_holderContent__2eFV8 {
    margin-right: 0.5rem;
  }

  .Holder_wrapper__1C3nB {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}

.Auction_nounWrapper__3JSNc {
  align-self: flex-end;
  width: 100%;
}

.Auction_nounContentCol__1o5ER {
  display: flex;
}

.Auction_nounWrapper__3JSNc img {
  /*
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  */
  position: static;
  position: initial;
  top: auto;
  left: auto;
  width: 100%;
  height: 100%;
  max-height: 450px; 
  max-width: 450px; 
  vertical-align: middle;
}

.Auction_nounWrapper__3JSNc div {
  /*
  position: relative;
  padding-top: 100%;
  width: 100%;
  height: 0;
  */
  position: static;
  position: initial;
  padding-top: 0;
  width: 100%;
  height: auto;
  text-align: center;
}

.Auction_auctionActivityCol__3U2jw {
  padding-right: 5rem;
  padding-bottom: 0rem;
  min-height: 450px; /*520px*/
  align-self: flex-end !important;
}

@media (max-width: 992px) {
  .Auction_nounWrapper__3JSNc {
    width: 70%;
    margin-left: 15%;
    margin-right: 15%;
  }
  .Auction_auctionActivityCol__3U2jw {
    padding-top: 5%;
    padding-right: 5%;
    padding-left: 5%;
    width: 100%;
    background-color: white;
  }
}

@media (max-width: 568px) {
  .Auction_auctionActivityCol__3U2jw {
    width: 100%;
    margin-left: unset;
    margin-right: unset;
    padding-bottom: 2rem;
    padding-top: 2rem;
    padding-right: unset;
    padding-left: 0;
  }

  .Auction_nounWrapper__3JSNc {
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 2rem;
  }

  .Auction_nounContentCol__1o5ER {
    padding: 0rem;
  }
}

.NounderNounContent_link__3MuKL,
.NounderNounContent_link__3MuKL:visited,
.NounderNounContent_link__3MuKL:active {
  color: var(--brand-dark-green);
  text-decoration: underline;
}

.NounderNounContent_link__3MuKL:hover {
  color: var(--brand-dark-red);
}

.NounderNounContent_bidRow__2CVAB {
  font-weight: 500;
  font-size: 15.5px;
  line-height: 21px;
}

.NounderNounContent_currentBidCol__3VQRX {
  border-right: 0 !important;
}

.AuctionHeroText_wrapper__2wJIp {
  padding: 1rem 1rem;
  color: var(--brand-black);
  align-self: flex-start !important;
}

h1 {
  font-family: 'Londrina Solid';
  font-size: 56px; /*68px*/
  margin-bottom: 5px; /*10px*/
  line-height: 1;
}

.AuctionHeroText_bidBtn__1FgDw {
  font-family: 'PT Root UI';
  border-radius: 12px !important;
  margin-left: 0.6rem !important;
  margin-top: 3px;
  width: auto;
  padding: 10px 16px;
  height: 3rem;
  color: white;
  border: transparent;
  background-color: var(--brand-black);
  font-weight: bold;
  letter-spacing: normal;
  font-size: 19px;
  transition: all 0.2s ease-in-out;
}

.AuctionHeroText_bidBtn__1FgDw:disabled {
  cursor: not-allowed;
}

.AuctionHeroText_bidBtn__1FgDw:hover,
.AuctionHeroText_bidBtn__1FgDw:active,
.AuctionHeroText_bidBtn__1FgDw:focus .AuctionHeroText_bidBtn__1FgDw:disabled {
  outline: none !important;
  box-shadow: none !important;
  background-color: #2125298a;
}

.AuctionHeroText_bidBtn__1FgDw:disabled {
  background-color: gray !important;
}



.AuctionHeroText_informationRow__2SWJh {
  margin-bottom: 0.5rem;
}

.AuctionHeroText_activityRow__1R8CG {
  margin-bottom: 0rem;
}

h2 {
  font-size: 2.5rem;
}

h4 {
  font-size: 1rem;
  font-weight: 400;
}

.AuctionHeroText_modalHeader__1oyeQ {
  border-bottom: none;
  justify-content: flex-start;
  align-items: center;
}

.AuctionHeroText_modalTitleWrapper__3hhgX {
  color: var(--brand-black);
}

.AuctionHeroText_modalTitleWrapper__3hhgX h1 {
  font-size: xx-large;
  margin-left: 2rem;
}

.AuctionHeroText_modalHeader__1oyeQ button {
  align-self: flex-start;
}

.AuctionHeroText_modalHeaderNounImgWrapper__2527_ {
  width: 150px;
  height: 150px;
}

.AuctionHeroText_currentBidCol__3e5HH {
  border-right: 1px solid #79809c49;
  margin-left: 6px;
  margin-top: 6px;
}

.AuctionHeroText_auctionTimerCol__3JOvy {
  padding-left: 0rem;
}

.AuctionHeroText_section__2_wqL h4 {
  font-family: 'PT Root UI';
  font-size: 17px;
  line-height: 25px;
}

.AuctionHeroText_section__2_wqL h2 {
  font-family: 'PT Root UI';
  font-weight: bold;
  font-size: 32px;
}

@media (max-width: 992px) {
  h1 {
    padding-top: 12px;
  }

  .AuctionHeroText_wrapper__2wJIp {
    padding: 1rem;
  }

  .AuctionHeroText_auctionTimerCol__3JOvy {
    margin-left: 6px;
    margin-top: 6px;
    padding-left: 0rem;
  }

  .AuctionHeroText_currentBidCol__3e5HH {
    border-right: 0px;
    padding-left: 0rem;
  }

  .AuctionHeroText_fomoNounsLink__25f-c {
    padding-left: 14px;
  }
}

@media (max-width: 400px) {
  .AuctionHeroText_fomoNounsLink__25f-c {
    font-size: 14px;
  }
}

/* Fix Firefox navigation arrow alignment issues */
@-moz-document url-prefix() {
  .AuctionHeroText_colAlignCenter__3yt7t {
    display: flex;
    align-items: center;
  }
}

.AuctionHeroText_subTagline__2dYZz {
  margin-top: 0rem;
  margin-bottom: 1rem;
  transition: 0.2s ease-in-out;

  font-size: 18px;
  font-family: 'PT Root UI', sans-serif;
  font-weight: 500;
  text-decoration: none;
  opacity: 1;
  color: rgb(95, 95, 95);

}
.AuctionHero_nounWrapper__3bCAB {
  align-self: flex-end;
  width: 100%;
}

.AuctionHero_nounContentCol__Wi8g- {
  display: flex;
}

.AuctionHero_nounWrapper__3bCAB img {
  /*
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  */
  position: static;
  position: initial;
  top: auto;
  left: auto;
  width: 100%;
  height: 100%;
  max-height: 450px; 
  max-width: 450px; 
  vertical-align: middle;
}

.AuctionHero_nounWrapper__3bCAB div {
  /*
  position: relative;
  padding-top: 100%;
  width: 100%;
  height: 0;
  */
  position: static;
  position: initial;
  padding-top: 0;
  width: 100%;
  height: auto;
  text-align: center;
}

.AuctionHero_auctionActivityCol__3Crli {
  padding-left: 5rem;
  padding-bottom: 0rem;
  min-height: 450px; /*520px*/
  align-self: flex-end !important;
  padding-top: 150px;

}

@media (max-width: 992px) {
  .AuctionHero_nounWrapper__3bCAB {
    width: 70%;
    margin-left: 15%;
    margin-right: 15%;
  }
  .AuctionHero_auctionActivityCol__3Crli {
    padding-top: 5%;
    padding-right: 5%;
    padding-left: 5%;
    width: 100%;
    text-align: center;
    min-height: 250px; /*520px*/
  }
}

@media (max-width: 568px) {
  .AuctionHero_auctionActivityCol__3Crli {
    width: 100%;
    margin-left: unset;
    margin-right: unset;
    padding-bottom: 0rem;
    padding-top: 2rem;
    padding-left: unset;
    padding-right: 0;
  }

  .AuctionHero_nounWrapper__3bCAB {
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 0rem;
  }

  .AuctionHero_nounContentCol__Wi8g- {
    padding: 0rem;
  }
}

.Documentation_headerWrapper__xln_n h1 {
  font-family: 'Londrina Solid';
  font-size: 4rem;
}

.Documentation_headerWrapper__xln_n p {
  font-family: 'PT Root UI', sans-serif;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 28px;
  margin: 3rem 0;
}

.Documentation_accordionItem__1PEt2 {
  font-family: 'PT Root UI', sans-serif;
  font-weight: 500;
  letter-spacing: -0.25px;
  line-height: 28px;
  font-size: 1.2rem !important;
}

@media (max-width: 992px) {
  .Documentation_headerWrapper__xln_n {
    padding: 1rem;
  }
}

.Documentation_accordionItem__1PEt2 {
  margin-bottom: 2.5rem;
  border: none;
}

.Documentation_aboutText__2Ma74 {
  margin: 0px !important;
}

.Documentation_accordionItem__1PEt2,
.Documentation_headerWrapper__xln_n {
  font-size: 1.3rem;
}

.Documentation_accordionHeader__2Pk8x button:hover {
  color: var(--brand-dark-red) !important;
}

.Documentation_accordionHeader__2Pk8x button {
  border: none;
  background-color: transparent;
  font-family: 'Londrina Solid';
  font-size: 2.5rem !important;
  cursor: pointer;
  line-height: normal;
  padding-top: 0;
  padding-bottom: 0;
}

.Documentation_accordionHeader__2Pk8x button:not(.Documentation_collapsed__3KbMo) {
  color: #212529;
  background-color: transparent;
  box-shadow: none !important;
}

/* override bootstrap color */
.Documentation_accordionHeader__2Pk8x button:not(.Documentation_collapsed__3KbMo)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
}

.Documentation_accordionHeader__2Pk8x button:focus {
  color: var(--brand-dark-red) !important;
  box-shadow: none !important;
}

@media (max-width: 992px) {
  .Documentation_accordionHeader__2Pk8x button {
    padding-left: 20px;
  }
}

.ProfileActivityFeed_headerWrapper__33MV0 h1 {
  font-family: 'Londrina Solid';
  font-size: 32px;
}

.ProfileActivityFeed_headerWrapper__33MV0 p {
  margin: 3rem 0;
}

@media (min-width: 992px) {
  .ProfileActivityFeed_headerWrapper__33MV0 {
    padding: 1rem;
    display: block;
    padding-bottom: 16px;
  }
}

.ProfileActivityFeed_nounInfoPadding__2Om27 {
  padding-bottom: 1rem;
  font-size: 18px;
}

.ProfileActivityFeed_expandCollapseCopy__3l2EY {
  color: var(--brand-cool-light-text);
  font-family: 'PT Root UI';
  font-weight: bold;
  font-size: 20px;
  text-align: center;
  cursor: pointer;
  margin-top: 1rem;
}

.ProfileActivityFeed_aboveTheFoldEventsTable__2fL2g {
  margin-bottom: 0rem;
}

.ProfileActivityFeed_aboveTheFoldEventsTable__2fL2g td {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 12px;
}

.ProfileActivityFeed_nullStateCopy__1ObAe {
  opacity: 0.5;
  margin-left: 1rem;
  font-family: 'PT Root UI';
  font-weight: 500;
  font-size: 18px;
}

.ProfileActivityFeed_spinner__3jpZC {
  display: flex;
  justify-content: center;
  margin-top: 3rem;
  color: var(--brand-gray-light-text);
}

.ProfileActivityFeedToggle_expandCollapseCopy__y9obe {
  color: var(--brand-cool-light-text);
  font-family: 'PT Root UI';
  font-weight: bold;
  font-size: 20px;
  text-align: center;
  cursor: pointer;
  margin-top: 1rem;
}

.BrandSpinner_spinner__1s9t7 {
  animation: BrandSpinner_rotate__QdcCR 1.25s linear infinite;
  transform-origin: center center !important;
}

@keyframes BrandSpinner_rotate__QdcCR {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.DesktopDelegationEvent_delegateHover__3Ra5G {
  border-radius: 8px !important;
  background-color: var(--brand-gray-dark-text) !important;
  color: white;
  opacity: 0.75 !important;
  font-weight: 500;
  transition: ease-in-out 125ms;
}

.DesktopDelegationEvent_scaleIconWrapper__3L1bb {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--brand-gray-light-text-translucent);
  color: var(--brand-gray-light-text);
  border-radius: 100%;
  height: 38px;
  width: 38px;
  margin-left: 0.4rem;
}

.DesktopDelegationEvent_scaleIcon__1t2DG {
  height: 22px;
  width: 22px;
}

.DesktopDelegationEvent_address__1u_yL {
  font-weight: bold;
  cursor: pointer;
}

.DesktopDelegationEvent_externalLinkIcon__3tsMy {
  height: 16px;
  width: 16px;
  margin-right: 0.3rem;
}

.DesktopDelegationEvent_transactionHashWrapper__2RiSM {
  background-color: var(--brand-gray-light-text-translucent);
  color: var(--brand-gray-light-text);
  height: 28px;
  border-radius: 8px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-family: 'PT Root UI';
  font-weight: bold;
  vertical-align: middle;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 8px;
  font-size: 14px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.DesktopNounActivityRow_wrapper__1J9tT {
  transition: all 0.2s ease-in-out;
}

.DesktopNounActivityRow_pointer__1NDkC {
  cursor: pointer;
}

.DesktopNounActivityRow_wrapper__1J9tT:hover {
  --bs-table-hover-bg: rgba(0, 0, 0, 0.03);
}

.DesktopNounActivityRow_icon__3eTWD {
  width: 38px;
  margin-right: 0px;
  margin-bottom: 4px;
  margin-left: 6px;
}

.DesktopNounActivityRow_activityTableCell__3XHtk {
  max-width: 50vw;
}

.DesktopNounActivityRow_infoContainer__2k2-M {
  display: inline-block;
  margin-top: 4px;
  width: 100%;
}
@media (max-width: 992px) {
  .DesktopNounActivityRow_infoContainer__2k2-M {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.DesktopNounActivityRow_secondaryContentWrapper__3KWUl {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.DesktopNounActivityRow_secondaryContentContainer__1atvn {
  text-align: right;
  width: -webkit-max-content;
  width: max-content;
}

.TransactionHashPill_transactionHashWrapper__2OCwj {
  background-color: var(--brand-gray-light-text-translucent);
  color: var(--brand-gray-light-text);
  height: 28px;
  border-radius: 8px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-family: 'PT Root UI';
  font-weight: bold;
  vertical-align: middle;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 8px;
  font-size: 14px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  transition: all 0.15s ease-in-out;
  cursor: pointer;
}
.TransactionHashPill_transactionHashWrapper__2OCwj:hover {
  transform: scale(0.95);
}

.TransactionHashPill_externalLinkIcon__g68m_ {
  height: 16px;
  width: 16px;
  margin-right: 0.3rem;
}

@media (max-width: 992px) {
  .TransactionHashPill_externalLinkIcon__g68m_ {
    margin-bottom: 0.135rem;
  }
}

.DesktopNounWinEvent_delegateHover__3OI0v {
  border-radius: 8px !important;
  background-color: var(--brand-gray-dark-text) !important;
  color: white;
  opacity: 0.75 !important;
  font-weight: 500;
  transition: ease-in-out 125ms;
}

.DesktopNounWinEvent_switchIconWrapper__8IcRT {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--brand-gray-light-text-translucent);
  color: var(--brand-gray-light-text);
  border-radius: 100%;
  height: 38px;
  width: 38px;
  margin-left: 0.4rem;
}

.DesktopNounWinEvent_switchIcon__3dp2b {
  height: 22px;
  width: 22px;
}

.DesktopNounWinEvent_address__107Lk {
  font-weight: bold;
  cursor: pointer;
}

.DesktopNounWinEvent_externalLinkIcon__z8oDr {
  height: 16px;
  width: 16px;
  margin-right: 0.3rem;
}

.DesktopNounWinEvent_transactionHashWrapper__3WlI_ {
  background-color: var(--brand-gray-light-text-translucent);
  color: var(--brand-gray-light-text);
  height: 28px;
  border-radius: 8px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-family: 'PT Root UI';
  font-weight: bold;
  vertical-align: middle;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 8px;
  font-size: 14px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.DesktopNounWinEvent_bold__ud3km {
  font-weight: bold;
}

.DesktopProposalVoteEvent_voteIcon__2wOOw {
  width: 38px;
  margin-right: 0px;
  margin-bottom: 4px;
  margin-left: 6px;
}

.DesktopProposalVoteEvent_proposalTitle__lgVjZ {
  text-decoration: none;
  color: black;
  font-family: 'PT Root UI';
  font-weight: bold;
  cursor: pointer;
}

.DesktopProposalVoteEvent_delegateHover__1psVG {
  border-radius: 8px !important;
  background-color: var(--brand-gray-dark-text) !important;
  color: white;
  opacity: 0.75 !important;
  font-weight: 500;
  transition: ease-in-out 125ms;
}

.VoteStatusPill_nounButton__1VHQj {
  height: 28px;
  border-radius: 8px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-family: 'PT Root UI';
  font-weight: bold;
  vertical-align: middle;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 8px;
  color: var(--brand-color-green);
  background-color: var(--brand-color-green-translucent);
  font-size: 14px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.VoteStatusPill_pass__3EIR8 {
  color: var(--brand-color-blue);
  background-color: var(--brand-color-blue-translucent);
}

.VoteStatusPill_fail__2wF4b {
  color: var(--brand-color-red);
  background-color: var(--brand-color-red-translucent);
}

.VoteStatusPill_pending__3d_z7 {
  color: var(--brand-color-green);
  background-color: var(--brand-color-green-translucent);
}

.ProposalVoteInfoPills_wrapper__cn13c {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

@media (max-width: 992px) {
  .ProposalVoteInfoPills_wrapper__cn13c {
    justify-content: flex-start;
  }
}

.ProposalVoteInfoPills_voteStatusWrapper__3eD-a {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
}

.ProposalVoteHeadline_voterLink__1zFRt {
  font-weight: bold;
  cursor: pointer;
}

.ProposalVoteHeadline_delegateHover__3LKm0 {
  border-radius: 8px !important;
  background-color: var(--brand-gray-dark-text) !important;
  color: white;
  opacity: 0.75 !important;
  font-weight: 500;
  transition: ease-in-out 125ms;
}

.DesktopTransferEvent_delegateHover__1bpHn {
  border-radius: 8px !important;
  background-color: var(--brand-gray-dark-text) !important;
  color: white;
  opacity: 0.75 !important;
  font-weight: 500;
  transition: ease-in-out 125ms;
}

.DesktopTransferEvent_switchIconWrapper__19id- {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--brand-gray-light-text-translucent);
  color: var(--brand-gray-light-text);
  border-radius: 100%;
  height: 38px;
  width: 38px;
  margin-left: 0.4rem;
}

.DesktopTransferEvent_switchIcon__2BBFN {
  height: 22px;
  width: 22px;
}

.DesktopTransferEvent_address__1MPkM {
  font-weight: bold;
  cursor: pointer;
}

.DesktopTransferEvent_externalLinkIcon__7NBuC {
  height: 16px;
  width: 16px;
  margin-right: 0.3rem;
}

.DesktopTransferEvent_transactionHashWrapper__1TUl_ {
  background-color: var(--brand-gray-light-text-translucent);
  color: var(--brand-gray-light-text);
  height: 28px;
  border-radius: 8px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-family: 'PT Root UI';
  font-weight: bold;
  vertical-align: middle;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 8px;
  font-size: 14px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.DesktopProfileActivityFeed_aboveTheFoldEventsTable__2UIky {
  margin-bottom: 0rem;
}

.DesktopProfileActivityFeed_aboveTheFoldEventsTable__2UIky td {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 12px;
}

.DesktopProfileActivityFeed_nounInfoPadding__2Bhvn {
  padding-bottom: 1rem;
  font-size: 18px;
}

.MobileDelegationEvent_scaleIconWrapper__1sALi {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--brand-gray-light-text-translucent);
  color: var(--brand-gray-light-text);
  border-radius: 100%;
  height: 38px;
  width: 38px;
  margin-left: 0.4rem;
}

.MobileDelegationEvent_scaleIcon__3bHM7 {
  height: 22px;
  width: 22px;
}

.MobileDelegationEvent_bold__1Y83q {
  font-weight: bold;
}

.MobileNounActivityRow_wrapper__3-L7o {
  border-bottom: 1px solid var(--brand-gray-border);
  padding-bottom: 0.5rem;
  font-family: PT Root UI;
  font-size: 18px;
  margin-top: 1rem;
  display: flex;
}

.MobileNounActivityRow_icon__3n-VO {
  width: 38px;
  margin-right: 0px;
  margin-top: 0.25rem;
  margin-left: 6px;
}

.MobileNounActivityRow_content__cEDSP {
  margin-left: 1.5rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.MobileNounWinEvent_iconWrapper__17_yv {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--brand-gray-light-text-translucent);
  color: var(--brand-gray-light-text);
  border-radius: 100%;
  height: 38px;
  width: 38px;
  margin-left: 0.4rem;
}

.MobileNounWinEvent_switchIcon__UKYG_ {
  height: 22px;
  width: 22px;
}

.MobileNounWinEvent_bold__2J-F1 {
  font-weight: bold;
}

.MobileProposalVoteEvent_voteIcon__rlh4E {
  width: 38px;
  margin-right: 0px;
  margin-bottom: 4px;
  margin-left: 6px;
}

.MobileProposalVoteEvent_proposalTitle__3enqs {
  text-decoration: none;
  color: black;
  font-family: 'PT Root UI';
  font-weight: bold;
}

.MobileTransferEvent_switchIconWrapper__SSRX1 {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--brand-gray-light-text-translucent);
  color: var(--brand-gray-light-text);
  border-radius: 100%;
  height: 38px;
  width: 38px;
  margin-left: 0.4rem;
}

.MobileTransferEvent_switchIcon__W1ac2 {
  height: 22px;
  width: 22px;
}

.MobileTransferEvent_bold__3gKys {
  font-weight: bold;
}

.ProposalStatus_proposalStatus__3RcqE {
  font-family: 'PT Root UI';
  font-weight: bold;
  color: white;
  border-radius: 8px;
  font-size: 14px;
  border: 0rem;
  padding-left: 0.65rem;
  padding-right: 0.65rem;
  padding-top: 0.36rem;
  padding-bottom: 0.36rem;
}

.ProposalStatus_primary__32vJB {
  background-color: var(--brand-color-green);
}

.ProposalStatus_success__1OL1A {
  background-color: var(--brand-color-blue);
}

.ProposalStatus_danger__12g7A {
  background-color: var(--brand-color-red);
}

.ProposalStatus_secondary__3jdRV {
  background-color: var(--brand-gray-light-text);
}

.ProposalStatus_votePageProposalStatus__1OEbX {
  margin-top: 0.8rem;
}

.Proposals_proposals__2mg9W {
  display: flex;
  flex-direction: column;
}

.Proposals_proposals__2mg9W > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.Proposals_heading__26OQ5 {
  font-family: 'Londrina Solid';
  font-size: 40px;
  margin: 0 !important;
}

.Proposals_generateBtn__1iqIa {
  max-width: 10rem;
  height: 3rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  border-radius: 12px;
  background-color: var(--brand-color-green);
  border: var(--brand-color-green);
}
.Proposals_generateBtn__1iqIa:focus,
.Proposals_generateBtn__1iqIa:hover {
  background-color: var(--brand-color-green) !important;
  box-shadow: 0 0 0 0.2rem rgb(67, 179, 105, 0.75);
}
.Proposals_generateBtn__1iqIa:active {
  background-color: var(--brand-color-green) !important;
}

.Proposals_generateBtnDisabled__qJ8Gm {
  max-width: 10rem;
  height: 3rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  border-radius: 12px;
  background-color: #f4f4f8;
  border: #f4f4f8;
  color: #8c8d92;
  border: 1px solid #e2e3e8;
}
.Proposals_generateBtnDisabled__qJ8Gm:focus,
.Proposals_generateBtnDisabled__qJ8Gm:hover,
.Proposals_generateBtnDisabled__qJ8Gm:active {
  cursor: not-allowed;
  background-color: #f4f4f8 !important;
  border: #f4f4f8 !important;
  color: #8c8d92 !important;
  border: 1px solid #e2e3e8 !important;
  outline: none;
  box-shadow: none;
}

.Proposals_proposalLink__3idLu {
  padding: 1rem;
  margin-top: 0.4rem;
  display: flex;
  flex-direction: column;
  border: 1px solid #e2e3e8;
  box-sizing: border-box;
  border-radius: 16px;
  background: #f4f4f8;
  font-size: 22px;
  font-family: 'PT Root UI';
  font-weight: bold;
  text-decoration: none;
  color: inherit;
  margin-bottom: 1rem;
}

.Proposals_proposalInfoWrapper__DGaov {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.Proposals_proposalLink__3idLu:hover {
  background: white;
  color: inherit !important;
  cursor: pointer;
}

.Proposals_markdown__31Gdt h1 {
  font-size: 1.7rem;
  margin-top: 1rem;
  font-weight: bold;
}

.Proposals_markdown__31Gdt h2 {
  font-size: 1.5rem;
  margin-top: 1rem;
  font-weight: bold;
}

.Proposals_markdown__31Gdt h3 {
  font-size: 1.3rem;
}

.Proposals_markdown__31Gdt img {
  max-width: 100%;
  height: auto;
}

.Proposals_nullStateCopy__3x1zf {
  margin-top: 0.9rem;
  margin-right: 1rem;
  font-family: 'PT Root UI';
  font-weight: 500;
  color: var(--brand-gray-light-text);
  font-size: 16px;
  line-height: 22.4px;
}

.Proposals_mobileCountdownWrapper__32WRj {
  display: none;
}

.Proposals_desktopCountdownWrapper__ebOCx {
  display: flex;
}

.Proposals_nounInWalletBtnWrapper__2Qyfk {
  display: flex;
  padding-top: 0.8rem;
}

@media (max-width: 992px) {
  .Proposals_desktopCountdownWrapper__ebOCx {
    display: none;
  }

  .Proposals_mobileCountdownWrapper__32WRj {
    display: flex;
    width: 100%;
    margin-top: 1rem;
  }

  .Proposals_nullStateCopy__3x1zf {
    margin-top: 0rem;
  }

  .Proposals_proposalTitle__VkUVe {
    max-width: 65%;
    overflow-wrap: break-word;
  }

  .Proposals_nounInWalletBtnWrapper__2Qyfk {
    width: 100%;
  }

  .Proposals_headerWrapper__3uJ1w {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .Proposals_forceFlexRow__3WvC2 {
    flex-direction: row;
  }

  .Proposals_heading__26OQ5 {
    width: 100%;
  }

  .Proposals_nullStateSubmitProposalBtnWrapper__lUOAJ {
    text-align: right;
  }
}

.Proposals_nullBtnWrapper__3-6kN {
  min-width: 10rem;
}

.Proposals_proposalId__2GVQA {
  color: #8c8d92;
  margin-right: 0.5rem;
}

.Proposals_proposalTitle__VkUVe {
  width: 80%;
}

.Proposals_proposalStatusWrapper__2axTx {
  margin-left: 0.5rem;
  min-width: -webkit-max-content;
  min-width: max-content;
}

.Proposals_submitProposalButtonWrapper__31ZLW {
  text-align: right;
}

.Proposals_nullStateSubmitProposalBtnWrapper__lUOAJ {
  text-align: right;
}

.Proposals_votePillWrapper__SYS_O {
  max-width: 5rem;
}

.Proposals_countdownPill__2oBM3 {
  background-color: var(--brand-gray-light-text-translucent);
  color: #00000080;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: -0.5rem;
}

.Proposals_countdownPillContentWrapper__1TmPe {
  display: flex;
  flex-direction: row;
}

.Proposals_countdownPillClock__2r4fL {
  display: flex;
  flex-direction: column;
  margin-top: auto;
  margin-bottom: auto;
}

.Proposals_countdownPillText__1Zkp4 {
  margin-left: 0.25rem;
}

.Proposals_delegateBtnWrapper__1jw2- {
  padding-left: 0.5rem;
}

.Proposals_changeDelegateBtn__20KXt {
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.1);
  color: rgb(95, 95, 95);
  max-width: 10rem;
  height: 3rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  border-radius: 12px;
}

.Proposals_changeDelegateBtn__20KXt:focus,
.Proposals_changeDelegateBtn__20KXt:hover {
  background-color: #e2e3e8;
  color: black;
  box-shadow: none;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.Proposals_changeDelegateBtn__20KXt:active {
  background-color: #e2e3e8 !important;
}

.DelegationModal_backdrop__3GoKW {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 60;
  background: rgba(75, 75, 75, 0.5);
  -webkit-backdrop-filter: blur(24px);
          backdrop-filter: blur(24px);
}

.DelegationModal_content__3WRlu {
  max-height: 50vh;
  overflow-y: hidden;
}

.DelegationModal_modal__nbJ_I {
  font-family: 'PT Root UI', sans-serif;
  font-weight: bold;
  position: fixed;
  top: 15vh;
  z-index: 100;
  background-color: rgba(244, 244, 248, 1);
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.05);
  padding: 1.5rem;
  border-radius: 24px;
  left: calc(50% - 236px);
  width: 472px;
  max-height: 347px;
}

.DelegationModal_modal__nbJ_I .DelegationModal_header__lrDiO {
  display: flex;
}

.DelegationModal_modal__nbJ_I .DelegationModal_title__3uM-e {
  font-family: 'Londrina Solid';
  display: flex;
  flex-direction: column;
}

.DelegationModal_modal__nbJ_I .DelegationModal_title__3uM-e h2 {
  font-size: 24px;
  margin-top: 0.5rem;
  color: rgba(140, 141, 146, 1);
}

.DelegationModal_modal__nbJ_I .DelegationModal_title__3uM-e h1 {
  line-height: 42px;
  height: 2rem;
  font-size: 42px;
}

.DelegationModal_closeBtnWrapper__1mXLn {
  padding: 1rem 2rem;
  display: flex;
  justify-content: flex-end;
}

.DelegationModal_closeBtn__stSL4 {
  z-index: 100;
  position: fixed;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  transition: all 0.125s ease-in-out;
  border: 0;
}

.DelegationModal_closeBtn__stSL4:hover {
  background-color: rgba(255, 255, 255, 0.5);
  cursor: pointer;
}

.DelegationModal_closeBtn__stSL4 .DelegationModal_icon__2X23D {
  height: 24px;
  width: 24px;
}

@media (max-width: 992px) {
  .DelegationModal_backdrop__3GoKW {
    background: rgba(0, 0, 0, 0.74);
  }

  .DelegationModal_content__3WRlu {
    max-height: 100%;
    height: 100%;
  }

  .DelegationModal_modal__nbJ_I {
    top: auto;
    left: 0;
    bottom: 0;
    width: 100%;
    max-height: 100%;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    box-shadow: none;
  }
}

.CurrentDelegatePannel_header__2gUoN {
  padding: 1rem 0.75rem 0rem 0.75rem;
}

.CurrentDelegatePannel_title__91pZe {
  font-family: 'Londrina Solid';
  display: flex;
  flex-direction: column;
  line-height: 42px;
  height: 2rem;
  font-size: 42px;
  color: var(--brand-cool-dark-text);
}

.CurrentDelegatePannel_copy__16ALG {
  font-weight: 500;
  font-family: 'PT Root UI';
  color: var(--brand-cool-dark-text);
}

.CurrentDelegatePannel_copy__16ALG .CurrentDelegatePannel_emph__3du2_ {
  font-weight: bold;
}

.CurrentDelegatePannel_contentWrapper__1G2cJ {
  border-radius: 14px;
  background-color: white;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
}

.CurrentDelegatePannel_current__GCaIr {
  color: var(--brand-cool-light-text);
  font-size: 18px;
  margin-top: 1rem;
  font-weight: 500;
}

.CurrentDelegatePannel_delegateInfoWrapper__3fs7e {
  display: flex;
  flex-direction: column;
}

.CurrentDelegatePannel_ens__3NxOP {
  display: flex;
  flex-direction: row;
  font-size: 26px;
  color: var(--brand-cool-dark-text);
}

.CurrentDelegatePannel_shortAddress__1AQOc {
  color: var(--brand-cool-light-text);
  font-weight: 500;
  font-size: 16px;
  text-align: right;
}

.CurrentDelegatePannel_buttonWrapper__nmJQL {
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;
}

@media (max-width: 992px) {
  .CurrentDelegatePannel_wrapper__cZGNs {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .CurrentDelegatePannel_buttonWrapper__nmJQL {
    margin-bottom: 1rem;
  }
}

.DelegationCandidateVoteCountInfo_wrapper__33_OY {
  display: flex;
}

.DelegationCandidateVoteCountInfo_spinner__206ux {
  margin-right: 0.5rem;
  margin-top: 0.5rem;
}

.DelegationCandidateVoteCountInfo_voteInfoWrapper__12v0n {
  display: flex;
  flex-direction: column;
  font-weight: normal;
  color: var(--brand-gray-light-text);
  text-align: right;
}

.DelegationCandidateVoteCountInfo_voteCount__2Wck1 {
  color: black;
  font-weight: bold;
}

.DelegationCandidateInfo_spinner__qInM2 {
  display: flex;
  justify-content: center;
}

.DelegationCandidateInfo_wrapper__1Tkvz {
  display: flex;
  flex-direction: row;
  margin-top: 0.75rem;
  justify-content: space-between;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.DelegationCandidateInfo_delegateCandidateInfoWrapper__1p1rm {
  display: flex;
}

.DelegationCandidateInfo_avatarWrapper__1eNEU {
  margin-right: 1rem;
}

.DelegationCandidateInfo_ensText__wHrDa {
  color: var(--brand-cool-dark-text);
  font-weight: bold;
  font-size: 22px;
}

.DelegationCandidateInfo_shortAddress__2qjqH {
  font-weight: 500;
  font-size: 13px;
  color: var(--brand-cool-light-text);
}

.ChangeDelegatePannel_delegateInput__3ZZcJ {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  width: 100%;
  height: 54px;
  color: black;
  border-radius: 12px !important;
  box-shadow: inset 0px 0px 0px 1px #fff;
  background-color: white;
  outline: none !important;
  box-shadow: none !important;
  font-family: 'PT Root UI';
  font-weight: bold;
  font-size: 25px;
  transition: all 0.2s ease-in-out;
}

.ChangeDelegatePannel_delegteDeepLinkSpinner__2DMTn {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.ChangeDelegatePannel_delegateInput__3ZZcJ:focus {
  box-shadow: none !important;
}

.ChangeDelegatePannel_nonEnBottomMargin__2SPRI {
  margin-bottom: 2.1rem;
}

@media (max-width: 992px) {
  .ChangeDelegatePannel_nonEnBottomMargin__2SPRI {
    margin-bottom: 3.5rem;
  }
}

.ChangeDelegatePannel_empty__23o_z {
  border: none;
}

.ChangeDelegatePannel_valid__2ci4L {
  border: 2px solid var(--brand-color-green);
}

.ChangeDelegatePannel_invalid__2wACc {
  border: 2px solid var(--brand-color-red);
}

.ChangeDelegatePannel_buttonWrapper__OA5z3 {
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;
}

.ChangeDelegatePannel_btnVoteCount__3lF0X {
  align-items: center;
  margin: auto;
}

.ChangeDelegatePannel_delegateKVotesBtn__2H39_ {
  z-index: 101;
}

.ChangeDelegatePannel_delegateKVotesBtn__2H39_ span {
  margin-right: 0.3rem;
}

.ChangeDelegatePannel_highlightCircle__16ni1 {
  width: 22px;
  line-height: 22px;
  border-radius: 50%;
  text-align: center;
  background-image: radial-gradient(#ffffff70 15%, rgba(0, 0, 0, 0) 75%);
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 8px;
  padding-right: 8px;
  font-size: 20px;
}

.ChangeDelegatePannel_delegateCandidateInfoWrapper__4lBhM {
  padding: 0rem 0.25rem 0.25rem 0rem;
  margin-top: 0.5rem;
}

@media (max-width: 992px) {
  .ChangeDelegatePannel_customButtonHighlighter__XizH7 {
    top: 24.15%;
    left: 81.115%;
  }
}

.ChangeDelegatePannel_alreadyDelegatedCopy__3dc6b {
  margin-left: 0.25rem;
  color: var(--brand-cool-light-text);
}

.Governance_heading__MOhD3 {
  display: inline-block;
  font-weight: bold;
  font-size: 3rem;
}

.Governance_headerRow__ksau1 span {
  color: #8c8d92;
  font-size: 24px;
  font-family: 'Londrina Solid';
}

.Governance_headerRow__ksau1 h1 {
  color: #14161b;
  font-size: 56px;
  font-family: 'Londrina Solid';
}

.Governance_subheading__222R0 {
  color: var(--brand-dark-green);
  font-family: 'PT Root UI';
  font-weight: 500;
  font-size: 1.2rem;
}

.Governance_boldText__NTSL0 {
  font-family: 'PT Root UI';
  font-weight: bold;
}

p {
  padding-top: 1rem;
}

@media (max-width: 992px) {
  .Governance_section__2XsXU {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
}

.Governance_wrapper__2DDiu {
  margin-left: auto;
  margin-right: auto;
}

.Governance_treasuryInfoCard__KCyDP {
  margin-bottom: 3rem;
  border-radius: 20px;
  border: 1px solid #e2e3e8;
}

.Governance_treasuryAmtWrapper__1Mtsd {
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

.Governance_usdTreasuryAmt__HZORQ {
  padding-top: 0.2rem;
}

.Governance_ethTreasuryAmt__1yXCq {
  display: flex;
  height: 3rem;
  min-width: 9rem;
  padding-top: 0.2rem;
}

.Governance_ethTreasuryAmt__1yXCq h1 {
  font-size: 36px;
  font-family: 'Londrina Solid';
}

.Governance_usdBalance__1diQE {
  font-size: 36px;
  font-family: 'Londrina Solid';
}

.Governance_ethSymbol__2McN7 {
  font-family: 'PT Root UI' !important;
  margin-right: 0.5rem;
}

.Governance_usdBalance__1diQE {
  color: var(--brand-gray-light-text);
  font-family: 'Londrina Solid';
}

.Governance_treasuryInfoText__xxow6 {
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
  font-family: 'PT Root UI';
  font-weight: 500;
}

@media (min-width: 992px) {
  .Governance_treasuryAmtWrapper__1Mtsd {
    border-right: 2px solid #e2e3e8;
  }

  .Governance_ethTreasuryAmt__1yXCq {
    border-right: 2px solid #e2e3e8;
  }
}

.CreateProposal_createProposalPage__3qx3H a {
  color: var(--brand-dark-red);
}

.CreateProposal_createProposalForm__1UHgT {
  border-radius: 5px;
  padding: 1rem 2.5rem;
  margin-top: 1em;
  background-color: white;
}

.CreateProposal_heading__e7kWw {
  margin: 1rem 0;
}

.CreateProposal_section__2A56Y {
  border-top: 1px solid #e9ecef;
  word-wrap: break-word;
  padding-top: 2rem;
  margin-top: 2rem;
}

.CreateProposal_addTransactionButton__3U7s3,
.CreateProposal_createProposalButton__UHWVW {
  margin-top: 1rem;
}

.ProposalEditor_proposalEditor__19xXK {
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 0.5rem 1rem 1rem 1rem;
  border: 1px solid #aaa !important;
  border-radius: 0.25rem !important;
  outline: none !important;
  box-shadow: none !important;
}

.ProposalEditor_titleInput__2oS3A,
.ProposalEditor_bodyInput__20KWn {
  padding: 0;
  border: none;
  width: 100% !important;
  outline: none !important;
  box-shadow: none !important;
}

.ProposalEditor_titleInput__2oS3A {
  font-size: 1.25rem;
}

.ProposalEditor_propTitle__2PGkw {
  font-family: 'Londrina Solid';
}

.ProposalEditor_previewArea__1FpbY h3 {
  font-weight: 400;
  font-size: 14px;
  color: rgb(108, 117, 125);
}

.ProposalEditor_bodyInput__20KWn {
  min-height: 340px !important;
}

.ProposalEditor_divider__38QOx {
  width: 100%;
  margin: 0 0 0.5rem 0;
}

.ProposalEditor_previewArea__1FpbY {
  padding: 0.5rem 1rem 1rem 1rem;
  border: 1px solid #aaa !important;
  border-radius: 0.25rem !important;
  outline: none !important;
  box-shadow: none !important;
}

.ProposalEditor_markdown__2ReG4 h1 {
  font-size: 1.7rem;
  margin-top: 1rem;
  font-weight: bold;
}

.ProposalEditor_markdown__2ReG4 h2 {
  font-size: 1.5rem;
  margin-top: 1rem;
  font-weight: bold;
}

.ProposalEditor_markdown__2ReG4 h3 {
  font-size: 1.3rem;
}

.ProposalEditor_markdown__2ReG4 img {
  max-width: 100%;
  height: auto;
}

.ProposalTransactions_popover__3fRG9 {
  max-width: 600px;
}

.ProposalTransactions_transactionDetails__2EchO {
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  border: 1px solid #aaa !important;
  border-radius: 0.25rem !important;
}

.ProposalTransactions_removeTransactionButton__2dBl_ {
  border: none;
  background: none;
  outline: none !important;
  box-shadow: none !important;
}

.ProposalTransactions_removeTransactionButton__2dBl_ img {
  width: 1rem;
}

.ProposalTransactionFormModal_transactionFormModal__2GUdX {
  max-width: 600px;
}

.ProposalTransactionFormModal_stepProgressBar__4Na9W {
  padding: 0 0 2rem 0 !important;
}

.ProposalTransactionFormModal_inputGroupText__3ruwH {
  border-radius: 0.25rem 0 0 0.25rem;
  border-right: none;
}

.ProposalTransactionFormModal_functionName__2XKIf {
  white-space: nowrap;
}

.Vote_votePage__G1H10 a {
  color: var(--brand-dark-red);
}

.Vote_proposal__1pjGZ {
  margin-top: 1em;
  background-color: white;
}

.Vote_backArrow__3z9lF {
  height: 1rem;
}

.Vote_votingButton__3PrIp {
  margin-top: 1rem;
}

.Vote_voteCountCard__11har {
  margin-top: 1rem;
}

.Vote_proposalId__1ti1o {
  margin: 1rem 0;
}

.Vote_voteCountCard__11har p {
  display: flex;
  justify-content: space-between;
}

.Vote_section__3S8l3 {
  word-wrap: break-word;
  padding-top: 2rem;
  margin-top: 2rem;
}

.Vote_section__3S8l3 h5 {
  font-size: 1.7rem;
  margin-top: 1rem;
  font-family: 'Londrina Solid';
}

.Vote_voterIneligibleAlert__2jDfQ {
  margin: 1rem 0 0 0;
}

.Vote_blockRestrictionAlert__1X0x6 {
  margin: 1rem 0 0 0;
}

.Vote_wrapper__3XVzk {
  margin-left: auto;
  margin-right: auto;
}

.Vote_transitionStateButton__3qBjX {
  height: 50px;
  border-radius: 8px;
  font-family: 'PT Root UI';
  font-weight: bold;
  font-size: 24px;
  transition: all 0.125s ease-in-out;
}

.Vote_transitionStateButton__3qBjX:hover {
  opacity: 0.5;
  cursor: pointer;
}

.Vote_transitionStateButtonSection__1iavo {
  border-top: 0px;
}

.Vote_spinner__2e2Ue {
  margin-left: auto;
  margin-right: auto;
  color: var(--brand-gray-light-text);
}

/* Info section stuff */
.Vote_voteInfoCard__1X8sV {
  margin-top: 1rem;
  padding: 0.5rem;
  border-radius: 12px;
}

.Vote_voteMetadataRow__2OLhz {
  display: flex;
  justify-content: space-between;
}

.Vote_voteMetadataRow__2OLhz h1 {
  font-size: 20px;
  color: var(--brand-gray-light-text);
  font-family: 'Londrina Solid';
}

.Vote_voteMetadataRow__2OLhz span {
  font-size: 14px;
  font-family: 'PT Root UI';
  color: var(--brand-gray-light-text);
}

.Vote_voteMetadataRow__2OLhz h3 {
  font-size: 18px;
  font-family: 'PT Root UI';
  font-weight: bold;
}

.Vote_voteMetadataRowTitle__yUKZ0 {
  margin-top: 0.5rem;
  width: -webkit-max-content;
  width: max-content;
}

.Vote_voteMetadataTime__3unTi {
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  text-align: right;
}

.Vote_snapshotBlock__VMn7W {
  text-align: right;
}

.Vote_thresholdInfo__8xlb3 {
  text-align: right;
}

.Vote_toggleDelegateVoteView__8Pni3 {
  margin-top: 0.1rem;
  margin-bottom: 0.1rem;
  opacity: 0.5;
  font-size: 14px;
  cursor: pointer;
  transition: ease-in-out 125ms;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: 0.1rem;
}

.Vote_toggleDelegateVoteView__8Pni3:hover {
  text-decoration: underline;
}

@media (max-width: 1200px) {
  .Vote_toggleDelegateVoteView__8Pni3 {
    display: none;
  }
}

.Vote_delegateHover__2m6mm {
  border-radius: 8px !important;
  background-color: var(--brand-gray-dark-text) !important;
  color: white;
  opacity: 0.75 !important;
  font-weight: 500;
  transition: ease-in-out 125ms;
}

.Vote_dqIcon__1PiqK {
  opacity: 0.5;
  margin-left: 0.25rem;
  margin-bottom: 0.25rem;
  height: 18px;
  width: 18px;
}

.Vote_cursorPointer__OTmSW {
  cursor: pointer;
}

.VoteModal_voteModal__19gv1 {
  font-family: 'PT Root UI';
}

.VoteModal_propTitle__3x-Yf {
  font-family: 'Londrina Solid';
  font-size: 32px;
  margin-bottom: 5px;
}

.VoteModal_voteIcon__3Bqua {
  width: 38px;
  margin-right: 0px;
  margin-bottom: 4px;
  margin-left: 6px;
}

.VoteModal_submitBtn__3DsnJ {
  width: 100%;
  min-width: 8rem;
  height: 40px;
  font-weight: bold;
  margin-bottom: 0.5rem;
  border-radius: 12px;
  background-color: var(--brand-color-green) !important;
  border: var(--brand-color-green) !important;
  color: white !important;
}
.VoteModal_submitBtn__3DsnJ:focus,
.VoteModal_submitBtn__3DsnJ:hover {
  background-color: var(--brand-color-green) !important;
  box-shadow: 0 0 0 0.2rem rgb(67, 179, 105, 0.75);
  border: var(--brand-color-green) !important;
}
.VoteModal_submitBtn__3DsnJ:active {
  background-color: var(--brand-color-green) !important;
}

.VoteModal_submitBtnDisabled__2rMJV {
  background-color: var(--brand-gray-light-text) !important;
  width: 100%;
  min-width: 8rem;
  height: 40px;
  font-weight: bold;
  margin-bottom: 0.5rem;
  border-radius: 12px;
  border: var(--brand-gray-light-text);
  opacity: 0.5;
  box-shadow: none !important;
}

.VoteModal_submitBtnDisabled__2rMJV:hover,
.VoteModal_submitBtnDisabled__2rMJV:active,
.VoteModal_submitBtnDisabled__2rMJV:focus {
  cursor: not-allowed;
  box-shadow: none !important;
  color: white !important;
}

.VoteModal_header__11wDB {
  border-bottom: 0px;
}

.VoteModal_votingButtonsWrapper__2ZIR7 {
  transition: all 0.125s ease-in-out;
}

.VoteModal_loadingSpinner__3d36m {
  font-size: 18px;
  margin-bottom: 2rem;
  text-align: center;
  color: var(--brand-gray-light-text);
}

.VoteModal_transactionStatus__XIqRz {
  font-size: 18px;
  margin-bottom: 2rem;
  text-align: center;
  font-family: 'PT Root UI';
  font-weight: bold;
}

.VoteModal_disabled__2l6K5 {
  opacity: 0.5;
}

.VoteModal_voteSuccessBody__2Ilpn {
  margin-top: 1rem;
}

.VoteModal_voteFailureTitle__2i0IW {
  font-weight: normal;
  width: 100%;
}

.VoteModal_voteFailureBody__2ICmq {
  margin-top: 1rem;
  font-weight: bold;
}

.VoteModal_voteFailureErrorMessage__P-Il4 {
  font-weight: bold;
  color: var(--brand-color-red);
}

.VoteModal_voteReasonTextarea__23cgn {
  padding-top: 2rem !important;
  height: 6.5rem !important;
  border-radius: 10px;
  border: none;
  font-weight: bold;
  font-size: 1rem;
}

.VoteModal_voteReasonTextarea__23cgn:focus {
  box-shadow: none;
}

.ProposalHeader_backButton__3n9fs {
  -webkit-appearance: none;
  padding: 0;
  display: inline-block;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  font-weight: bold;
  margin-right: 1rem;
  margin-top: 0.1rem;
}

.ProposalHeader_headerRow__2s3d4 span {
  color: #8c8d92;
  font-size: 24px;
  font-family: 'Londrina Solid';
}
.ProposalHeader_headerRow__2s3d4 h1 {
  color: #14161b;
  font-size: 42px;
  font-family: 'Londrina Solid';
}

.ProposalHeader_proposalStatus__QaV8n {
  margin-left: 0.75rem;
  margin-top: 0.1rem;
}

.ProposalHeader_connectWalletText__1GGnk {
  color: var(--brand-gray-light-text);
  font-family: 'PT Root UI';
  font-weight: 500;
  font-size: 16px;
  min-width: 12rem;
  margin-bottom: 1.15rem;
}

.ProposalHeader_noVotesText__2GCQ9 {
  color: var(--brand-gray-light-text);
  font-family: 'PT Root UI';
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 1.15rem;
  min-width: 9.5rem;
}

.ProposalHeader_submitBtn__1dlEY {
  width: 100%;
  max-width: 8rem;
  min-width: 8rem;
  height: 3rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  border-radius: 12px;
  background-color: var(--brand-color-green);
  border: var(--brand-color-green);
}
.ProposalHeader_submitBtn__1dlEY:focus,
.ProposalHeader_submitBtn__1dlEY:hover {
  background-color: var(--brand-color-green) !important;
  box-shadow: 0 0 0 0.2rem rgb(67, 179, 105, 0.75);
}
.ProposalHeader_submitBtn__1dlEY:active {
  background-color: var(--brand-color-green) !important;
}
.ProposalHeader_submitBtnDisabled__1xAz_ {
  background-color: var(--brand-gray-light-text) !important;
  width: 100%;
  max-width: 8rem;
  min-width: 8rem;
  height: 3rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  border-radius: 12px;
  border: var(--brand-dark-red);
  opacity: 0.5;
  box-shadow: none !important;
}

.ProposalHeader_submitBtnDisabled__1xAz_:hover,
.ProposalHeader_submitBtnDisabled__1xAz_:active,
.ProposalHeader_submitBtn__1dlEY:focus {
  cursor: not-allowed;
  box-shadow: none !important;
}

.ProposalHeader_proposalTitleWrapper__3w5EB {
  display: flex;
  padding-right: 2rem;
}

.ProposalHeader_proposalTitle__20ZSL {
  margin-right: 0.5rem;
}

.ProposalHeader_mobileSubmitProposalButton__hmFGT {
  padding-left: 3rem;
  padding-right: 3rem;
}

@media (max-width: 992px) {
  .ProposalHeader_submitBtn__1dlEY {
    max-width: 100%;
  }

  .ProposalHeader_submitBtnDisabled__1xAz_ {
    max-width: 100%;
  }
}

.ProposalHeader_voterIneligibleAlert__31ibs {
  font-family: 'PT Root UI';
  font-weight: 500;
  background-color: #e2e3e8;
  color: black;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-top: 0.5rem;
}

.ProposalHeader_byLineWrapper__3eq3P {
  display: flex;
  flex-direction: row;
}

.ProposalHeader_byLineWrapper__3eq3P h3 {
  font-family: 'Londrina Solid';
  color: var(--brand-gray-light-text);
  margin-left: 3rem;
  font-size: 24px;
}

.ProposalHeader_byLineContentWrapper__1N1uw {
  display: flex;
  flex-direction: row;
  margin-left: 0;
}

.ProposalHeader_propTransactionWrapper__2lM2O {
  margin-left: 0.33rem;
  margin-right: 0.5rem;
  font-size: 18px;
  color: var(--brand-gray-light-text);
}
.ProposalHeader_propTransactionWrapperJp__2btkr {
  margin-left: 0.37rem;
  margin-right: 0.25rem;
  color: var(--brand-gray-light-text);
}

.ProposalHeader_proposedByJp__-DZiP {
  color: var(--brand-gray-light-text);
}

.ProposalHeader_proposerLinkJp__3zfxy {
  margin-right: 0.25rem;
}

.ProposalHeader_proposalByLineWrapperJp__2qCJT {
  font-weight: 500;
  margin-left: 2.5rem;
}

.ProposalHeader_propTransactionHash__1qOc8 {
  margin-left: 0.2rem;
}

.ProposalHeader_byLineContentWrapper__1N1uw h3 {
  margin-left: 0.5rem;
}

@media (max-width: 450px) {
  .ProposalHeader_byLineWrapper__3eq3P {
    flex-direction: column;
  }

  .ProposalHeader_byLineContentWrapper__1N1uw {
    margin-left: 2.5rem;
  }

  .ProposalHeader_proposalByLineWrapperJp__2qCJT {
    flex-direction: column;
    display: flex;
  }

  .ProposalHeader_proposerJp__3UNTk {
    margin-left: 0.5rem;
  }

  .ProposalHeader_proposedByJp__-DZiP {
    margin-left: 0.5rem;
  }
}

.ProposalContent_section__hGx6B {
  word-wrap: break-word;
  padding-top: 2rem;
  margin-top: 2rem;
}

.ProposalContent_section__hGx6B h5 {
  font-size: 1.7rem;
  margin-top: 1rem;
  font-family: 'Londrina Solid';
}

.ProposalContent_markdown__3dCs8 {
  font-family: 'PT Root UI';
  font-size: 1.1rem;
}

.ProposalContent_markdown__3dCs8 h1 {
  font-size: 1.7rem;
  margin-top: 1rem;
  font-weight: bold;
}

.ProposalContent_markdown__3dCs8 h2 {
  font-size: 1.5rem;
  margin-top: 1rem;
  font-weight: bold;
}

.ProposalContent_markdown__3dCs8 h3 {
  font-weight: bold;
  font-size: 1.3rem;
}

.ProposalContent_markdown__3dCs8 img {
  max-width: 100%;
  height: auto;
}

.HoverCard_hover__2x2iv {
  box-sizing: border-box;
  min-width: 217px;
  width: -webkit-max-content;
  width: max-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  left: 301px;
  top: 396px;

  /* White */
  background-color: #ffffff !important;

  /* Gray/Border */
  border: 1px solid #e2e3e8 !important;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.01) !important;
  border-radius: 14px !important;
  color: black !important;
}

.HorizontalStackedNouns_wrapper__2QZh9 {
  position: relative !important;
  margin-bottom: 50px;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.HorizontalStackedNouns_nounWrapper__1HsPJ {
  position: absolute;
}

.ByLineHoverCard_wrapper__2d-ze {
  display: flex;
  flex-direction: column;
}

.ByLineHoverCard_stackedNounWrapper__11GB1 {
  display: flex;
}

.ByLineHoverCard_address__1VV3Q {
  font-family: 'Londrina Solid';
  font-size: 24px;
  width: 100%;
  text-align: left;
}

.ByLineHoverCard_nounsRepresented__39R09 {
  color: var(--brand-gray-dark-text);
  display: flex;
  margin-top: 0.25rem;
  font-weight: 500;
  font-size: 15px;
  max-width: 11rem;
}

.ByLineHoverCard_bold__yLcyI {
  font-weight: bold;
}

.ByLineHoverCard_spinnerWrapper__3lw8h {
  color: var(--brand-gray-light-text);
  width: 100%;
  height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ByLineHoverCard_spinner__2demJ {
  display: flex;
  width: 100%;
  justify-content: center;
}

.ByLineHoverCard_icon__1SVbH {
  margin-bottom: 5px;
  margin-right: 6px;
}

.NounImageVoteTable_wrapper__TMwqB {
  margin-left: 0.25rem;
}

.GrayCircle_wrapper__3H09A {
  height: 55px;
  width: 55px;
}

.NounHoverCard_wrapper__3RT3_ {
  width: -webkit-max-content;
  width: max-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.NounHoverCard_spinnerWrapper__GUxDk {
  color: var(--brand-gray-light-text);
  width: 100%;
  height: 125px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.NounHoverCard_spinner__29rKW {
  display: flex;
  width: 100%;
  justify-content: center;
}

.NounHoverCard_titleWrapper__3cx2l {
  display: flex;
  flex-direction: row;
  margin-bottom: 0.75rem;
  margin-top: 0.75rem;
}

.NounHoverCard_titleWrapper__3cx2l h1 {
  color: black;
  font-family: 'Londrina Solid';
  font-size: 24px;
  margin-top: 0.45rem;
  margin-left: 0.5rem;
}

.NounHoverCard_nounWrapper__38kwt {
  height: 42px;
  width: 42px;
}

.NounHoverCard_nounInfoWrapper__2ZN1d {
  margin-top: 0.75rem;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 140%;
  /* identical to box height, or 21px */

  display: flex;
  align-items: center;
  font-feature-settings: 'tnum' on, 'lnum' on, 'ss06' on, 'ss01' on, 'liga' off;

  color: var(--brand-gray-dark-text);
}

.NounHoverCard_bold__3SWJp {
  font-weight: bold;
  margin-left: 0.25rem;
}

.NounHoverCard_icon__3NSBq {
  margin-right: 7px;
}

.NounHoverCard_cta__2RpFj {
  color: var(--brand-gray-light-text);
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 140%;
  /* identical to box height, or 21px */

  display: flex;
  align-items: center;
  font-feature-settings: 'tnum' on, 'lnum' on, 'ss06' on, 'ss01' on, 'liga' off;
}

.NounHoverCard_currentHolder__2YO2E {
  margin-bottom: 1rem;
}

.VoteCardPager_pageDots__2qKMa {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  color: var(--brand-gray-light-text);
}

.VoteCardPager_disabledPageDot__2Fj8y {
  opacity: 0.5;
}

.VoteCardPager_paginationArrowsWrapper__16MKK {
  display: flex;
  justify-content: center;
}

.VoteCardPager_paginationArrowBtnWrapper__3cNKt {
  border: none;
  background-color: transparent;
}

.VoteCardPager_paginationArrowBtnWrapper__3cNKt:disabled {
  opacity: 0.5;
}

.VoteCardPager_paginationArrow__3IBFg {
  height: 28px;
  width: 28px;
  color: var(--brand-gray-light-text);
}

.VoteCardPager_disabled__7Pbuv {
  opacity: 0.25;
}

.VoteProgressBar_wrapper__a8wkH {
  border-radius: 6px;
  height: 1rem;
}

.VoteProgressBar_progressBar__35-Ke {
  height: 100%;
  border-radius: 6px;
}

.VoteProgressBar_forWrapper__RjvRz {
  background-color: var(--brand-color-green-translucent);
}

.VoteProgressBar_forProgressBar__3JlTZ {
  background-color: var(--brand-color-green);
}

.VoteProgressBar_againstWrapper__IukLN {
  background-color: var(--brand-color-red-translucent);
}

.VoteProgressBar_againstProgressBar__15RmJ {
  background-color: var(--brand-color-red);
}

.VoteProgressBar_abstainWrapper__13Z8S {
  background-color: var(--brand-gray-light-text-translucent);
}

.VoteProgressBar_abstainProgressBar__HzhI2 {
  background-color: var(--brand-gray-light-text);
}

.VoteCard_voteCardHeaderTextEn__1ABnI {
  font-size: 22px;
  font-family: 'Londrina Solid';
}

.VoteCard_voteCardHeaderTextNonEn__ft_XX {
  font-size: 16px;
  font-family: 'Londrina Solid';
}

.VoteCard_voteCardVoteCount__1ij4- {
  font-size: 22px;
  font-family: 'PT Root UI';
  font-weight: bold;
  margin-top: 0.15rem;
}

.VoteCard_for__2Csem {
  color: var(--brand-color-green);
}

.VoteCard_against__3Xhru {
  color: var(--brand-color-red);
}

.VoteCard_abstain__1szzM {
  color: var(--brand-gray-light-text);
}

.VoteCard_voteCountCard__2YaCa {
  margin-top: 1rem;
  padding: 0.5rem;
  border-radius: 12px;
  background-color: #f4f4f8;
  min-height: 18rem;
}

.VoteCard_voteCountCard__2YaCa p {
  display: flex;
  justify-content: space-between;
}

.VoteCard_nounProfilePics__27dmE {
  margin-top: 1.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.VoteCard_mobileVoteCountWrapper__191hv {
  display: none !important;
}

.VoteCard_unitTextEn__2mAmE {
  opacity: 0.5;
  font-size: 18px;
  font-weight: 500;
}

.VoteCard_unitTextNonEn__3pL9x {
  opacity: 0.5;
  font-size: 14px;
  font-weight: 500;
}

.VoteCard_smallerVoteCountText__3iQJD {
  font-size: 16px;
}

@media (max-width: 1200px) {
  .VoteCard_voteCountCard__2YaCa {
    min-height: 0rem;
  }

  .VoteCard_unitTextEn__2mAmE {
    font-size: 16px;
  }

  .VoteCard_unitTextNonEn__3pL9x {
    font-size: 13px;
  }

  .VoteCard_mobileVoteCountWrapper__191hv {
    display: flex !important;
    flex-direction: column;
  }

  .VoteCard_voteCardVoteCount__1ij4- {
    margin-left: auto;
    margin-right: auto;
  }

  .VoteCard_wrapper__1RqUm {
    width: 33%;
  }

  .VoteCard_voteCardHeaderTextEn__1ABnI {
    margin-left: auto;
    margin-right: auto;
  }

  .VoteCard_voteCardHeaderTextNonEn__ft_XX {
    margin-left: auto;
    margin-right: auto;
    font-size: 16px;
  }

  .VoteCard_nounProfilePics__27dmE {
    display: none;
  }
}

.DelegateHoverCard_wrapper__2R0D9 {
  display: flex;
  flex-direction: column;
  max-width: 11rem;
}

.DelegateHoverCard_stackedNounWrapper__11AJJ {
  display: flex;
}

.DelegateHoverCard_address__L2D-i {
  font-family: 'Londrina Solid';
  font-size: 24px;
  width: 100%;
  text-align: left;
}

.DelegateHoverCard_nounsRepresented__-JNhe {
  color: var(--brand-gray-dark-text);
  display: flex;
  margin-top: 0.25rem;
  font-weight: 500;
  font-size: 15px;
}

.DelegateHoverCard_bold__2T6j0 {
  font-weight: bold;
  margin-right: 0.25rem;
  margin-left: 0.25rem;
}

.DelegateHoverCard_spinnerWrapper__1wfGt {
  color: var(--brand-gray-light-text);
  width: 100%;
  height: 185px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.DelegateHoverCard_spinner__1Pvd8 {
  display: flex;
  width: 100%;
  justify-content: center;
}

.DelegateHoverCard_nounInfoWrapper__3p3q2 {
  margin-top: 0.25rem;
  margin-bottom: 0.75rem;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 140%;
  /* identical to box height, or 21px */

  display: flex;
  align-items: center;
  font-feature-settings: 'tnum' on, 'lnum' on, 'ss06' on, 'ss01' on, 'liga' off;

  color: var(--brand-gray-dark-text);
}

.DelegateHoverCard_icon__31v09 {
  margin-bottom: 5px;
  margin-right: 6px;
}

.DelegateGroupedNounImageVoteTable_wrapper__GLPLV {
  margin-left: auto;
  margin-right: auto;
  margin-top: -20px;
}

.DelegateGroupedNounImageVoteTable_nounCell__QPC3S {
  width: 55px;
  text-align: center;
}

.DynamicQuorumInfoModal_backdrop__3sp1o {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: rgba(75, 75, 75, 0.5);
  -webkit-backdrop-filter: blur(24px);
          backdrop-filter: blur(24px);
}

.DynamicQuorumInfoModal_content__2Po18 {
  padding: 1rem;
  max-height: 90vh;
  overflow-y: hidden;
}

.DynamicQuorumInfoModal_modal__1Egtc {
  font-family: 'PT Root UI', sans-serif;
  font-weight: bold;
  position: fixed;
  top: 10vh;
  left: 10%;
  z-index: 100;
  background-color: white;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.05);
  padding: 1rem;
  border-radius: 24px;
  left: calc(50% - 35rem);
  width: 70rem;
}

.DynamicQuorumInfoModal_modal__1Egtc .DynamicQuorumInfoModal_header__3Fzv_ {
  display: flex;
}

.DynamicQuorumInfoModal_modal__1Egtc .DynamicQuorumInfoModal_title__32DuZ {
  font-family: 'Londrina Solid';
  display: flex;
  flex-direction: column;
  margin-bottom: -1rem;
}

.DynamicQuorumInfoModal_modal__1Egtc .DynamicQuorumInfoModal_mainCopy__2knWj {
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.DynamicQuorumInfoModal_modal__1Egtc .DynamicQuorumInfoModal_moreDetailsCopy__1t3ir {
  opacity: 50%;
  font-size: 14px;
  font-weight: normal;
  margin-left: 0rem;
}

.DynamicQuorumInfoModal_underline__1FbyZ {
  text-decoration: underline;
  cursor: pointer;
}

.DynamicQuorumInfoModal_graphWrapper__3-FDA {
  display: flex;
}

.DynamicQuorumInfoModal_yAxisText__31BmZ {
  -ms-writing-mode: tb-rl;
      writing-mode: vertical-rl;
  -webkit-text-orientation: mixed;
          text-orientation: mixed;
  display: flex;
  justify-content: center;
}

.DynamicQuorumInfoModal_xAxisText__26sOV {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
}

@media (max-width: 1200px) {
  .DynamicQuorumInfoModal_modal__1Egtc .DynamicQuorumInfoModal_title__32DuZ {
    color: white;
  }

  .DynamicQuorumInfoModal_modal__1Egtc .DynamicQuorumInfoModal_mainCopy__2knWj {
    color: white;
  }

  .DynamicQuorumInfoModal_modal__1Egtc .DynamicQuorumInfoModal_moreDetailsCopy__1t3ir {
    color: white;
  }
}

.DynamicQuorumInfoModal_modal__1Egtc .DynamicQuorumInfoModal_title__32DuZ h2 {
  font-size: 24px;
  margin-top: 0.5rem;
  color: rgba(140, 141, 146, 1);
}

.DynamicQuorumInfoModal_modal__1Egtc .DynamicQuorumInfoModal_title__32DuZ h1 {
  line-height: 25px;
  height: 2rem;
  font-size: 42px;
}

.DynamicQuorumInfoModal_nounWrapper__PLRdu {
  height: 96px;
  width: 96px;
  margin-right: 1rem;
}

.DynamicQuorumInfoModal_bidWrapper__3AspQ {
  background-color: rgba(224, 224, 231, 1);
  overflow-y: scroll;
  box-shadow: inset 0px -12px 16px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  margin-top: 1rem;

  height: 35vh;
  padding: 0.75rem;
}

.DynamicQuorumInfoModal_bidWrapper__3AspQ ul {
  list-style: none;
  padding: 0;
}

.DynamicQuorumInfoModal_closeBtnWrapper__1IMft {
  padding: 1rem 2rem;
  display: flex;
  justify-content: flex-end;
}

.DynamicQuorumInfoModal_closeBtn__1tqUH {
  z-index: 100;
  position: fixed;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  transition: all 0.125s ease-in-out;
  border: 0;
}

.DynamicQuorumInfoModal_closeBtn__1tqUH:hover {
  background-color: rgba(255, 255, 255, 0.5);
  cursor: pointer;
}

.DynamicQuorumInfoModal_closeBtn__1tqUH .DynamicQuorumInfoModal_icon__3-nyo {
  height: 24px;
  width: 24px;
}

.DynamicQuorumInfoModal_nullStateText__2nRGB {
  font-size: 24px;
  text-align: center;
  margin-top: 3rem;
}

@media (max-width: 1200px) {
  .DynamicQuorumInfoModal_backdrop__3sp1o {
    background: rgba(0, 0, 0, 0.74);
  }

  .DynamicQuorumInfoModal_content__2Po18 {
    max-height: 100%;
    height: 100%;
  }

  .DynamicQuorumInfoModal_modal__1Egtc {
    top: 5vh;
    left: 0;
    bottom: 0;
    width: 100%;
    max-height: 100%;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    background-color: transparent;
    box-shadow: none;
  }

  .DynamicQuorumInfoModal_modal__1Egtc .DynamicQuorumInfoModal_header__3Fzv_ h2 {
    color: rgba(140, 141, 146, 1);
  }

  .DynamicQuorumInfoModal_modal__1Egtc .DynamicQuorumInfoModal_header__3Fzv_ h1 {
    color: white;
    line-height: 0px;
  }

  .DynamicQuorumInfoModal_bidWrapper__3AspQ {
    background-color: transparent;
    box-shadow: none;
    border-radius: 0;
    padding: 0rem;
    height: 100%;
  }

  .DynamicQuorumInfoModal_nullStateText__2nRGB {
    color: white;
  }
}

.DynamicQuorumInfoModal_graphContainer__2vmYE {
  border-radius: 14px;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.05);
  padding: 1rem;
}

.DynamicQuorumInfoModal_outterGraphContainer__1KJUS {
  background-color: rgba(244, 244, 248, 1);
  padding-left: 1rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-right: 3rem;
  margin-top: 2rem;
}

@media (max-width: 1200px) {
  .DynamicQuorumInfoModal_outterGraphContainer__1KJUS {
    display: none;
  }
}

.DynamicQuorumInfoModal_mobileQuorumInfo__QznCJ {
  display: flex;
  justify-content: space-between;
  font-size: large;
  color: white;
  font-weight: 500;
}

.DynamicQuorumInfoModal_mobileQuorumInfo__QznCJ span {
  font-size: x-large;
  font-weight: bold;
}

.DynamicQuorumInfoModal_mobileQuorumWrapper__19ZmT {
  margin-top: 3rem;
}

.DynamicQuorumInfoModal_innerGraphContainer__2jEJ8 {
  background-color: white;
  box-shadow: none;
  margin-left: 1rem;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  overflow-y: hidden;
  padding-bottom: 0;
  margin-top: 1rem;
}

.NoundersPage_noundersPage__2BU9Y h1,
.NoundersPage_noundersPage__2BU9Y h2,
.NoundersPage_noundersPage__2BU9Y h3 {
  font-family: 'Londrina Solid';
}

.NoundersPage_noundersPage__2BU9Y h2 {
  margin-bottom: 2rem;
}

.NoundersPage_noundersPage__2BU9Y a {
  color: var(--brand-black);
}
.NoundersPage_noundersPage__2BU9Y a:hover {
  color: var(--brand-dark-red);
}

.NoundersPage_bioGroup__26YGW {
  padding-right: 5px;
  padding-left: 5px;
}
.NoundersPage_bioGroup__26YGW a {
  font-size: 1rem;
}

.NoundersPage_noundersPage__2BU9Y img {
  border-radius: 50%;
  width: 50%;
}

.NoundersPage_marginBottom__dDAL1 {
  margin-bottom: 0rem;
}

.NoundersPage_bioGroup__26YGW {
  margin-bottom: 2rem;
  text-align: center;
}
.NoundersPage_bioGroup__26YGW a:hover svg {
  fill: var(--brand-dark-red);
}
.NoundersPage_bioGroup__26YGW img {
  margin-bottom: 1rem;
}

.NoundersPage_twitterIcon__3L0yj {
  width: 20px;
  color: #aaa;
  margin-right: 5px;
}

.NoundersPage_card__1wIOD {
  border: none;
}

.NoundersPage_card__1wIOD,
.NoundersPage_headerWrapper__1PZ9J {
  font-size: 1.3rem;
}
.NoundersPage_cardHeader__1GQQt {
  border: none;
  background-color: transparent;
  font-family: 'Londrina Solid';
  font-size: 2.5rem;
  cursor: pointer;
}

.NotFound_heading__3xG74 {
  display: inline-block;
  font-weight: bold;
  font-size: 3rem;
}

.Playground_headerRow__1PbPl {
  margin: 2rem 0;
}
.Playground_headerRow__1PbPl span {
  color: #8c8d92;
  font-size: 24px;
  font-family: 'Londrina Solid';
}
.Playground_headerRow__1PbPl h1 {
  color: #14161b;
  font-size: 56px;
  font-family: 'Londrina Solid';
}
.Playground_headerRow__1PbPl p {
  color: #14161b;
}

.Playground_primaryBtn__3BNgS {
  width: 100%;
  height: 4rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  border-radius: 12px;
  background-color: var(--brand-dark-red);
  border: var(--brand-dark-red);
}
.Playground_primaryBtn__3BNgS:focus,
.Playground_primaryBtn__3BNgS:hover {
  background-color: var(--brand-dark-red) !important;
  box-shadow: 0 0 0 0.2rem rgb(214, 60, 94, 0.75);
}
.Playground_primaryBtn__3BNgS:active {
  background-color: var(--brand-dark-red) !important;
}
.Playground_traitForm__2IWAH {
  height: 4rem;
}
.Playground_traitFormBtn__iYP0D {
  height: 100% !important;
  width: 100%;
  margin: 0.5rem 0;
  border-radius: 12px;
  background-color: white !important;
  border-color: #e2e3e8 !important;
  font-size: 18px;
  font-weight: bold;
  color: #14161b;
}
.Playground_traitFormBtn__iYP0D:hover,
.Playground_traitFormBtn__iYP0D:focus {
  border-color: #e2e3e8 !important;
  background-color: #f4f4f8 !important;
  box-shadow: none !important;
}
.Playground_floatingLabel__2VKJB,
.Playground_fileUpload__udPZ9 {
  font-size: 15px;
  color: #8c8d92;
}
.Playground_nounYearsFooter__3eTZ2 {
  font-style: italic;
}
.Playground_nounImg__3cni_ {
  border-radius: 16px;
}
.Playground_nounImg__3cni_:hover {
  cursor: pointer;
  transform: scale(1.01);
}
.Playground_nounWrapper__1TyU3 {
  margin-bottom: 1rem;
}

.NounModal_modal__24Xjq {
  position: fixed;
  top: 15vh;
  z-index: 100;
  padding: 2rem;
  text-align: center;
  border-radius: 15px;
  left: calc(50% - 12.5rem);
  width: 25rem;
}

.NounModal_displayNounFooter__9N9FF {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.NounModal_displayNounFooter__9N9FF span {
  color: white;
  font-weight: bold;
  margin-bottom: 1rem;
}
.NounModal_displayNounFooter__9N9FF button {
  width: 50%;
  background: rgba(255, 255, 255, 0.3);
  border: none;
}

.NounModal_displayNounFooter__9N9FF button:hover,
.NounModal_displayNounFooter__9N9FF button:active,
.NounModal_displayNounFooter__9N9FF button:focus {
  background: rgba(255, 255, 255, 0.4) !important;
  box-shadow: none !important;
}

.NounModal_nounImg__1_lzZ {
  border-radius: 16px;
}
.NounModal_nounWrapper__14FkD {
  margin-bottom: 1rem;
}

@media (max-width: 992px) {
  .NounModal_modal__24Xjq {
    width: 80% !important;
    left: 10% !important;
  }
}

.Collections_headerRow__Q3rVm {
  margin: 2rem 0;
}
.Collections_headerRow__Q3rVm span {
  color: #8c8d92;
  font-size: 24px;
  font-family: 'Londrina Solid';
}
.Collections_headerRow__Q3rVm h1 {
  color: #14161b;
  font-size: 56px;
  font-family: 'Londrina Solid';
}
.Collections_headerRow__Q3rVm p {
  color: #14161b;
}

.Collections_categoryHeader__vtVFe  {
  color: #14161b;
  font-family: 'Londrina Solid';
}

.Collections_itemGroup__3CrtX {
  /*
  padding-right: 5px;
  padding-left: 5px;
  */
  padding: 5px;
}
.Collections_itemGroup__3CrtX a {
  font-size: 1rem;
}

.Collections_itemGroup__3CrtX img {
  /*border-radius: 50%;*/
  width: 50%;
  max-width: 100px;

  border: 1px solid #000;
  box-shadow: 2px 5px 5px 2px rgb(0 0 0 / 25%);
  border-radius: 12px;  
}

.Collections_marginBottom__XqEu4 {
  margin-bottom: 0rem;
}

.Collections_itemGroup__3CrtX {
  margin-bottom: 2rem;
  text-align: center;
  /* 
  margin: 0.5rem;
  border: 1px solid #000;
  box-shadow: 2px 5px 5px 2px rgb(0 0 0 / 25%);
  border-radius: 12px;  
  */
}
.Collections_itemGroup__3CrtX a:hover svg {
  fill: var(--brand-dark-red);
}
.Collections_itemGroup__3CrtX img {
  margin-bottom: 0.5rem;
}

.Collections_twitterIcon__2AV0Z {
  width: 20px;
  color: #aaa;
  margin-right: 5px;
}

.Collections_card__L4p2Y {
  border: none;
}

.Collections_card__L4p2Y,
.Collections_headerWrapper__1-CjW {
  font-size: 1.3rem;
}
.Collections_cardHeader__2xMnq {
  border: none;
  background-color: transparent;
  font-family: 'Londrina Solid';
  font-size: 2.5rem;
  cursor: pointer;
}

.Collections_cardTitle__2ELu4 {
    margin-bottom: 0;
    font-size: 1rem;
}

.Collections_cardText__X4UJH {
    padding-top: 0;
}

.Collections_primaryBtnItem__2UZC5 {
  width: 50%;
  height: 2rem;
}

.Collections_primaryBtnSearch__3eZmg {
  width: 100%;
  height: 3rem;
}

.Collections_primaryBtn__icN52, .Collections_primaryBtnItem__2UZC5, .Collections_primaryBtnSearch__3eZmg {
  font-weight: bold;
  margin-bottom: 0.5rem;
  border-radius: 12px;
  background-color: var(--brand-dark-red);
  border: var(--brand-dark-red);
}
.Collections_primaryBtn__icN52:focus,
.Collections_primaryBtn__icN52:hover,
.Collections_primaryBtnItem__2UZC5:focus,
.Collections_primaryBtnItem__2UZC5:hover,
.Collections_primaryBtnSearch__3eZmg:focus,
.Collections_primaryBtnSearch__3eZmg:hover 

{
  background-color: var(--brand-dark-red) !important;
  box-shadow: 0 0 0 0.2rem rgb(214, 60, 94, 0.75);
}

.Collections_primaryBtn__icN52:active,
.Collections_primaryBtnItem__2UZC5:active,
.Collections_primaryBtnSearch__3eZmg:active {
  background-color: var(--brand-dark-red) !important;
}

.Collections_primaryBtn__icN52:disabled,
.Collections_primaryBtnItem__2UZC5:disabled,
.Collections_primaryBtnSearch__3eZmg:disabled {
  color: var(--brand-gray-dark-text) !important;
  background-color: var(--brand-gray-background) !important;
  border-color: var(--brand-gray-border) !important;  
  border-style: solid;
}


.Collections_traitForm__1Rg0z {
  height: 4rem;
}
.Collections_traitFormBtn__3eSvj {
  height: 100% !important;
  width: 100%;
  margin: 0.5rem 0;
  border-radius: 12px;
  background-color: white !important;
  border-color: #e2e3e8 !important;
  font-size: 18px;
  font-weight: bold;
  color: #14161b;
}
.Collections_traitFormBtn__3eSvj:hover,
.Collections_traitFormBtn__3eSvj:focus {
  border-color: #e2e3e8 !important;
  background-color: #f4f4f8 !important;
  box-shadow: none !important;
}
.Collections_floatingLabel__Z0g4h,
.Collections_fileUpload__1KLbj {
  font-size: 15px;
  color: #8c8d92;
}
.Collections_nounYearsFooter__QmZdT {
  font-style: italic;
}
.Collections_nounImg__1XoxU {
  border-radius: 16px;
}
.Collections_nounImg__1XoxU:hover {
  cursor: pointer;
  transform: scale(1.01);
}
.Collections_nounWrapper__1NIy0 {
  margin-bottom: 1rem;
}
.CollectionForm_modal__2yJUc {
  position: fixed;
  top: 10vh;
  z-index: 100;
  padding: 1rem;
  border-radius: 12px;
  left: calc(50% - 15rem);
  width: 30rem;
  
  height: 410px;
  background: white;
  overflow: hidden;
}

.CollectionForm_modal__2yJUc h2 {
	font-family: 'Londrina Solid';
	text-align: center;
}

.CollectionForm_formSection__2D6L6 {
	margin: 5px;
}

.CollectionForm_primaryBtn__1xySn {
  width: 50%;
}

.CollectionForm_primaryBtn__1xySn {
  font-weight: bold;
  margin-bottom: 0.5rem;
  border-radius: 12px;
  background-color: var(--brand-dark-red);
  border: var(--brand-dark-red);
}
.CollectionForm_primaryBtn__1xySn:focus,
.CollectionForm_primaryBtn__1xySn:hover
{
  background-color: var(--brand-dark-red) !important;
  box-shadow: 0 0 0 0.2rem rgb(214, 60, 94, 0.75);
}

.CollectionForm_primaryBtn__1xySn:active {
  background-color: var(--brand-dark-red) !important;
}

.CollectionForm_primaryBtn__1xySn:disabled {
  color: var(--brand-gray-dark-text) !important;
  background-color: var(--brand-gray-background) !important;
  border-color: var(--brand-gray-border) !important;  
  border-style: solid;
}


.CollectionForm_displayNounFooter__242PL {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.CollectionForm_displayNounFooter__242PL span {
  color: white;
  font-weight: bold;
  margin-bottom: 1rem;
}
.CollectionForm_displayNounFooter__242PL button {
  width: 50%;
  background: rgba(255, 255, 255, 0.3);
  border: none;
}

.CollectionForm_displayNounFooter__242PL button:hover,
.CollectionForm_displayNounFooter__242PL button:active,
.CollectionForm_displayNounFooter__242PL button:focus {
  background: rgba(255, 255, 255, 0.4) !important;
  box-shadow: none !important;
}

.CollectionForm_nounContainer__37wFu {
  margin-top: 10px;
}

.CollectionForm_nounImg__2tGd4 {
  border-radius: 16px;
}
.CollectionForm_nounWrapper__12V_k {
  margin-bottom: 0.1rem;
}
.CollectionForm_nounOuterWrapper__Tld1O {
  font-size: small;
  margin-bottom: 0.5rem;
}

.CollectionForm_nounImg__2tGd4:hover {
  cursor: pointer;
  transform: scale(1.01);
}

@media (max-width: 992px) {
  .CollectionForm_modal__2yJUc {
    width: 80% !important;
    left: 10% !important;
  }
}

.TooltipInfo_infoIcon__3nWGN {
  margin: 0 0 .25rem .25rem !important;
  border: 0 !important;
  border-radius: 0 !important;
}

.Collection_headerRow__TxFiN {
  margin: 0.5rem 0;
}
.Collection_headerRow__TxFiN span {
  color: #8c8d92;
  font-size: 24px;
  font-family: 'Londrina Solid';
}
.Collection_headerRow__TxFiN h1 {
  color: #14161b;
  font-size: 56px;
  font-family: 'Londrina Solid';
  margin: 0;
  padding: 0;
}
.Collection_headerRow__TxFiN p {
  color: #14161b;
}

.Collection_headerBannerRow__19841 {
  margin: 0.5rem 0;
}

.Collection_bannerRow__1IRif {
  position: relative;
}

.Collection_bannerImage__1XbiS {
  width: 100%;
  min-height: 100px;
  max-height: 200px;
  object-fit: cover;
}

.Collection_thumbnailImage__3fxrV {
  position: absolute;
  height: 100px;
  width: 100px;
  left: 10px;
  bottom: -10px;
  
  border: 1px solid #fff;
  box-shadow: 2px 5px 5px 2px rgb(0 0 0 / 25%);
  border-radius: 12px;    
}

.Collection_descriptionRow__mrRYo {
  margin: 0.5rem 0;	
}

.Collection_categoryHeader__3DtLQ  {
  color: #14161b;
  font-family: 'Londrina Solid';
}

.Collection_itemRow__2TUFR {
  border: 1px solid #e2e3e8;
  margin: 0.5rem 0;
  border-radius: 12px;  
  padding: 0.5rem;
}

.Collection_itemGroup__1Q5w0 {
  /*
  padding-right: 5px;
  padding-left: 5px;
  */
  padding: 5px;
}
.Collection_itemGroup__1Q5w0 a {
  font-size: 1rem;
}

.Collection_itemGroup__1Q5w0 img {
  /*border-radius: 50%;*/
  width: 50%;
  max-width: 100px;

  border: 1px solid #000;
  box-shadow: 2px 5px 5px 2px rgb(0 0 0 / 25%);
  border-radius: 12px;  
}

.Collection_marginBottom__2GSYk {
  margin-bottom: 0rem;
}

.Collection_itemGroup__1Q5w0 {
  margin-bottom: 0rem;
  text-align: center;
  /* 
  margin: 0.5rem;
  border: 1px solid #000;
  box-shadow: 2px 5px 5px 2px rgb(0 0 0 / 25%);
  border-radius: 12px;  
  */
}
.Collection_itemGroup__1Q5w0 a:hover svg {
  fill: var(--brand-dark-red);
}
.Collection_itemGroup__1Q5w0 img {
  margin-bottom: 0.5rem;
}

.Collection_twitterIcon__1F3Jj {
  width: 20px;
  color: #aaa;
  margin-right: 5px;
}

.Collection_card__16Ujb {
  border: none;
}

.Collection_card__16Ujb,
.Collection_headerWrapper__1lM3u {
  font-size: 1.3rem;
}
.Collection_cardHeader__2N_mE {
  border: none;
  background-color: transparent;
  font-family: 'Londrina Solid';
  font-size: 2.5rem;
  cursor: pointer;
}

.Collection_cardTitle___O2u8 {
    margin-bottom: 0;
    font-size: 1rem;
}

.Collection_cardText__AFsqY {
    padding-top: 0;
}

.Collection_primaryBtnItem__1MvvP {
  width: 50%;
  height: 2rem;
}

.Collection_primaryBtnSearch__3Yjwe {
  width: 100%;
  height: 3rem;
}

.Collection_primaryBtnUploader__1e0gl {
  max-width: 200px;
}


.Collection_primaryBtn__1BagD, .Collection_primaryBtnItem__1MvvP, .Collection_primaryBtnUploader__1e0gl {
  font-weight: bold;
  margin-bottom: 0.5rem;
  border-radius: 12px;
  background-color: var(--brand-dark-red);
  border: var(--brand-dark-red);
}
.Collection_primaryBtn__1BagD:focus,
.Collection_primaryBtn__1BagD:hover,
.Collection_primaryBtnItem__1MvvP:focus,
.Collection_primaryBtnItem__1MvvP:hover,
.Collection_primaryBtnUploader__1e0gl:focus,
.Collection_primaryBtnUploader__1e0gl:hover 

{
  background-color: var(--brand-dark-red) !important;
  box-shadow: 0 0 0 0.2rem rgb(214, 60, 94, 0.75);
}

.Collection_primaryBtn__1BagD:active,
.Collection_primaryBtnItem__1MvvP:active,
.Collection_primaryBtnUploader__1e0gl:active {
  background-color: var(--brand-dark-red) !important;
}

.Collection_primaryBtn__1BagD:disabled,
.Collection_primaryBtnItem__1MvvP:disabled,
.Collection_primaryBtnUploader__1e0gl:disabled {
  color: var(--brand-gray-dark-text) !important;
  background-color: var(--brand-gray-background) !important;
  border-color: var(--brand-gray-border) !important;  
  border-style: solid;
}


.Collection_traitForm__Et7SX {
  height: 4rem;
}
.Collection_traitFormBtn__21G4l {
  height: 100% !important;
  width: 100%;
  margin: 0.5rem 0;
  border-radius: 12px;
  background-color: white !important;
  border-color: #e2e3e8 !important;
  font-size: 18px;
  font-weight: bold;
  color: #14161b;
}
.Collection_traitFormBtn__21G4l:hover,
.Collection_traitFormBtn__21G4l:focus {
  border-color: #e2e3e8 !important;
  background-color: #f4f4f8 !important;
  box-shadow: none !important;
}
.Collection_floatingLabel__yLj5O,
.Collection_fileUpload__1LoRK {
  font-size: 15px;
  color: #8c8d92;
}
.Collection_nounYearsFooter__2p4N2 {
  font-style: italic;
}
.Collection_nounImg__Qwlao {
  border-radius: 16px;
}
.Collection_nounImg__Qwlao:hover {
  cursor: pointer;
  transform: scale(1.01);
}
.Collection_nounWrapper__1EAFE {
  margin-bottom: 1rem;
}

.Collection_spinner__U-JgK {
  display: flex;
  justify-content: center;
  color: var(--brand-gray-light-text);
}
.ListingForm_modal__18NKu {
  position: fixed;
  top: 10vh;
  z-index: 100;
  padding: 1rem;
  border-radius: 12px;
  left: calc(50% - 15rem);
  width: 35rem;
  
  height: 500px;
  background: white;
  overflow-x: hidden;
  overflow-y: auto;
  
  text-align: center;
}

.ListingForm_modal__18NKu h2 {
	font-family: 'Londrina Solid';
}

.ListingForm_formSection__1z-6S {
	/*margin: 5px;*/
}

.ListingForm_itemRows__dH7xU {
  border: 1px solid #e2e3e8;
  margin: 0.5rem 0;
  border-radius: 12px;  
  padding: 0.5rem;  
}

.ListingForm_itemRows__dH7xU img {
  border: 1px solid #000;
  border-radius: 12px;  
}

.ListingForm_itemGroup__1Yjyj {
  text-align: center;
}

.ListingForm_primaryBtn__2L4LF {
  width: 50%;
}

.ListingForm_primaryBtnOptions__1-is6 {
  width: 25%;
}

.ListingForm_primaryBtn__2L4LF,
.ListingForm_primaryBtnOptions__1-is6 {
  font-weight: bold;
  margin-bottom: 0.5rem;
  border-radius: 12px;
  background-color: var(--brand-dark-red);
  border: var(--brand-dark-red);
}
.ListingForm_primaryBtn__2L4LF:focus,
.ListingForm_primaryBtn__2L4LF:hover,
.ListingForm_primaryBtnOptions__1-is6:focus,
.ListingForm_primaryBtnOptions__1-is6:hover
{
  background-color: var(--brand-dark-red) !important;
  box-shadow: 0 0 0 0.2rem rgb(214, 60, 94, 0.75);
}

.ListingForm_primaryBtn__2L4LF:active,
.ListingForm_primaryBtnOptions__1-is6:active
{
  background-color: var(--brand-dark-red) !important;
}

.ListingForm_primaryBtn__2L4LF:disabled,
.ListingForm_primaryBtnOptions__1-is6:disabled
{
  color: var(--brand-gray-dark-text) !important;
  background-color: var(--brand-gray-background) !important;
  border-color: var(--brand-gray-border) !important;  
  border-style: solid;
}

.ListingForm_displayNounFooter__3zjil {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ListingForm_displayNounFooter__3zjil span {
  color: white;
  font-weight: bold;
  margin-bottom: 1rem;
}
.ListingForm_displayNounFooter__3zjil button {
  width: 50%;
  background: rgba(255, 255, 255, 0.3);
  border: none;
}

.ListingForm_displayNounFooter__3zjil button:hover,
.ListingForm_displayNounFooter__3zjil button:active,
.ListingForm_displayNounFooter__3zjil button:focus {
  background: rgba(255, 255, 255, 0.4) !important;
  box-shadow: none !important;
}

.ListingForm_nounContainer__3_-qH {
  margin-top: 10px;
}

.ListingForm_nounImg__21Dt0 {
  border-radius: 16px;
}
.ListingForm_nounWrapper__gzbhF {
  margin-bottom: 0.1rem;
}
.ListingForm_nounOuterWrapper__3xN9S {
  font-size: small;
  margin-bottom: 0.5rem;
}

.ListingForm_nounImg__21Dt0:hover {
  cursor: pointer;
  transform: scale(1.01);
}

@media (max-width: 992px) {
  .ListingForm_modal__18NKu {
    width: 80% !important;
    left: 10% !important;
  }
}

.EditCollection_modal__2MYIf {
  position: fixed;
  top: 10vh;
  z-index: 100;
  padding: 1rem;
  border-radius: 12px;
  left: calc(50% - 15rem);
  width: 30rem;
  
  height: 410px;
  background: white;
  overflow-x: hidden;
}

.EditCollection_modal__2MYIf h2 {
	font-family: 'Londrina Solid';
	text-align: center;
}

.EditCollection_formSection__1I6CI {
	margin: 5px;
}

.EditCollection_primaryBtn__wJTaq {
  width: 50%;
}

.EditCollection_primaryBtn__wJTaq {
  font-weight: bold;
  margin-bottom: 0.5rem;
  border-radius: 12px;
  background-color: var(--brand-dark-red);
  border: var(--brand-dark-red);
}
.EditCollection_primaryBtn__wJTaq:focus,
.EditCollection_primaryBtn__wJTaq:hover
{
  background-color: var(--brand-dark-red) !important;
  box-shadow: 0 0 0 0.2rem rgb(214, 60, 94, 0.75);
}

.EditCollection_primaryBtn__wJTaq:active {
  background-color: var(--brand-dark-red) !important;
}

.EditCollection_primaryBtn__wJTaq:disabled {
  color: var(--brand-gray-dark-text) !important;
  background-color: var(--brand-gray-background) !important;
  border-color: var(--brand-gray-border) !important;  
  border-style: solid;
}


.EditCollection_displayNounFooter__1vjAN {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.EditCollection_displayNounFooter__1vjAN span {
  color: white;
  font-weight: bold;
  margin-bottom: 1rem;
}
.EditCollection_displayNounFooter__1vjAN button {
  width: 50%;
  background: rgba(255, 255, 255, 0.3);
  border: none;
}

.EditCollection_displayNounFooter__1vjAN button:hover,
.EditCollection_displayNounFooter__1vjAN button:active,
.EditCollection_displayNounFooter__1vjAN button:focus {
  background: rgba(255, 255, 255, 0.4) !important;
  box-shadow: none !important;
}

.EditCollection_nounContainer__2XpeV {
  margin-top: 10px;
}

.EditCollection_nounImg__1wXG9 {
  border-radius: 16px;
}
.EditCollection_nounWrapper__1aHcU {
  margin-bottom: 0.1rem;
}
.EditCollection_nounOuterWrapper__2ZeJY {
  font-size: small;
  margin-bottom: 0.5rem;
}

.EditCollection_nounImg__1wXG9:hover {
  cursor: pointer;
  transform: scale(1.01);
}

@media (max-width: 992px) {
  .EditCollection_modal__2MYIf {
    width: 80% !important;
    left: 10% !important;
  }
}

.ListingHistory_modal__1MwGY {
  position: fixed;
  top: 10vh;
  z-index: 100;
  padding: 1rem;
  border-radius: 12px;
  left: calc(50% - 15rem);
  width: 35rem;
  
  height: 500px;
  background: white;
  overflow-x: hidden;
  overflow-y: auto;
}

.ListingHistory_modal__1MwGY .ListingHistory_title__25nfh {
  font-family: 'Londrina Solid';
  display: flex;
  flex-direction: column;
}

.ListingHistory_modal__1MwGY .ListingHistory_title__25nfh h2 {
  font-size: 24px;
  margin-top: 0.5rem;
  color: rgba(140, 141, 146, 1);
}

.ListingHistory_modal__1MwGY .ListingHistory_title__25nfh h1 {
  line-height: 25px;
  height: 2rem;
  font-size: 42px;
}

.ListingHistory_formSection__aa8Js {
	/*margin: 5px;*/
}

.ListingHistory_itemRows__1kRzg {
  border: 1px solid #e2e3e8;
  margin: 0.5rem 0;
  border-radius: 12px;  
  padding: 0.5rem;
}

.ListingHistory_itemRows__1kRzg img {
  border: 1px solid #000;
  border-radius: 12px;  
}

.ListingHistory_itemGroup__zp3jJ {
  text-align: center;
}

.ListingHistory_imageSection__1HvZ9 img {
  border: 1px solid #000;
  box-shadow: 2px 5px 5px 2px rgb(0 0 0 / 25%);
  border-radius: 12px;  
}


.ListingHistory_primaryBtn__H92aZ {
  width: 50%;
}

.ListingHistory_primaryBtn__H92aZ {
  font-weight: bold;
  margin-bottom: 0.5rem;
  border-radius: 12px;
  background-color: var(--brand-dark-red);
  border: var(--brand-dark-red);
}
.ListingHistory_primaryBtn__H92aZ:focus,
.ListingHistory_primaryBtn__H92aZ:hover
{
  background-color: var(--brand-dark-red) !important;
  box-shadow: 0 0 0 0.2rem rgb(214, 60, 94, 0.75);
}

.ListingHistory_primaryBtn__H92aZ:active {
  background-color: var(--brand-dark-red) !important;
}

.ListingHistory_primaryBtn__H92aZ:disabled {
  color: var(--brand-gray-dark-text) !important;
  background-color: var(--brand-gray-background) !important;
  border-color: var(--brand-gray-border) !important;  
  border-style: solid;
}


.ListingHistory_displayNounFooter__2JilT {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ListingHistory_displayNounFooter__2JilT span {
  color: white;
  font-weight: bold;
  margin-bottom: 1rem;
}
.ListingHistory_displayNounFooter__2JilT button {
  width: 50%;
  background: rgba(255, 255, 255, 0.3);
  border: none;
}

.ListingHistory_displayNounFooter__2JilT button:hover,
.ListingHistory_displayNounFooter__2JilT button:active,
.ListingHistory_displayNounFooter__2JilT button:focus {
  background: rgba(255, 255, 255, 0.4) !important;
  box-shadow: none !important;
}

.ListingHistory_nounContainer__2x1Yp {
  margin-top: 10px;
}

.ListingHistory_nounImg__2L1MF {
  border-radius: 16px;
}
.ListingHistory_nounWrapper__1cPA2 {
  margin-bottom: 0.1rem;
}
.ListingHistory_nounOuterWrapper__3wmuI {
  font-size: small;
  margin-bottom: 0.5rem;
}

.ListingHistory_nounImg__2L1MF:hover {
  cursor: pointer;
  transform: scale(1.01);
}

@media (max-width: 992px) {
  .ListingHistory_modal__1MwGY {
    width: 80% !important;
    left: 10% !important;
  }
}

.Profile_headerRow__1qaJG {
  margin: 0.5rem 0;
}
.Profile_headerRow__1qaJG span {
  color: #8c8d92;
  font-size: 24px;
  font-family: 'Londrina Solid';
}
.Profile_headerRow__1qaJG h1 {
  color: #14161b;
  font-size: 56px;
  font-family: 'Londrina Solid';
  margin: 0;
  padding: 0;
}
.Profile_headerRow__1qaJG p {
  color: #14161b;
}

.Profile_headerBannerRow__2Ux7t {
  margin: 0.5rem 0;
}

.Profile_bannerRow__26CA2 {
  position: relative;
}

.Profile_bannerImage__V0PJh {
  width: 100%;
  min-height: 100px;
  max-height: 200px;
  object-fit: cover;
}

.Profile_thumbnailImage__3IPNE {
  position: absolute;
  height: 100px;
  width: 100px;
  left: 10px;
  bottom: -10px;
  
  border: 1px solid #fff;
  box-shadow: 2px 5px 5px 2px rgb(0 0 0 / 25%);
  border-radius: 12px;    
}

.Profile_descriptionRow__fJTpz {
  margin: 0.5rem 0;	
}

.Profile_categoryHeader__3Og0D  {
  color: #14161b;
  font-family: 'Londrina Solid';
}

.Profile_itemRow__1KLNo {
  border: 1px solid #e2e3e8;
  margin: 0.5rem 0;
  border-radius: 12px;  
  padding: 0.5rem;
}

.Profile_itemGroup__1aTCe {
  /*
  padding-right: 5px;
  padding-left: 5px;
  */
  padding: 5px;
}
.Profile_itemGroup__1aTCe a {
  font-size: 1rem;
}

.Profile_itemGroup__1aTCe img {
  /*border-radius: 50%;*/
  width: 50%;
  max-width: 100px;

  border: 1px solid #000;
  box-shadow: 2px 5px 5px 2px rgb(0 0 0 / 25%);
  border-radius: 12px;  
}

.Profile_marginBottom__2a8T7 {
  margin-bottom: 0rem;
}

.Profile_itemGroup__1aTCe {
  margin-bottom: 0rem;
  text-align: center;
  /* 
  margin: 0.5rem;
  border: 1px solid #000;
  box-shadow: 2px 5px 5px 2px rgb(0 0 0 / 25%);
  border-radius: 12px;  
  */
}
.Profile_itemGroup__1aTCe a:hover svg {
  fill: var(--brand-dark-red);
}
.Profile_itemGroup__1aTCe img {
  margin-bottom: 0.5rem;
}

.Profile_twitterIcon__29C7o {
  width: 20px;
  color: #aaa;
  margin-right: 5px;
}

.Profile_card__2Hjcc {
  border: none;
}

.Profile_card__2Hjcc,
.Profile_headerWrapper__2dVaM {
  font-size: 1.3rem;
}
.Profile_cardHeader__WffYG {
  border: none;
  background-color: transparent;
  font-family: 'Londrina Solid';
  font-size: 2.5rem;
  cursor: pointer;
}

.Profile_cardTitle__1Sao4 {
    margin-bottom: 0;
    font-size: 1rem;
}

.Profile_cardText__1mLfZ {
    padding-top: 0;
}

.Profile_primaryBtnItem__2VEnP {
  width: 50%;
  height: 2rem;
}

.Profile_primaryBtnSearch__2RC6B {
  width: 100%;
  height: 3rem;
}

.Profile_primaryBtnUploader__32cLU {
  max-width: 200px;
}


.Profile_primaryBtn__1MvUR, .Profile_primaryBtnItem__2VEnP, .Profile_primaryBtnUploader__32cLU {
  font-weight: bold;
  margin-bottom: 0.5rem;
  border-radius: 12px;
  background-color: var(--brand-dark-red);
  border: var(--brand-dark-red);
}
.Profile_primaryBtn__1MvUR:focus,
.Profile_primaryBtn__1MvUR:hover,
.Profile_primaryBtnItem__2VEnP:focus,
.Profile_primaryBtnItem__2VEnP:hover,
.Profile_primaryBtnUploader__32cLU:focus,
.Profile_primaryBtnUploader__32cLU:hover 

{
  background-color: var(--brand-dark-red) !important;
  box-shadow: 0 0 0 0.2rem rgb(214, 60, 94, 0.75);
}

.Profile_primaryBtn__1MvUR:active,
.Profile_primaryBtnItem__2VEnP:active,
.Profile_primaryBtnUploader__32cLU:active {
  background-color: var(--brand-dark-red) !important;
}

.Profile_primaryBtn__1MvUR:disabled,
.Profile_primaryBtnItem__2VEnP:disabled,
.Profile_primaryBtnUploader__32cLU:disabled {
  color: var(--brand-gray-dark-text) !important;
  background-color: var(--brand-gray-background) !important;
  border-color: var(--brand-gray-border) !important;  
  border-style: solid;
}


.Profile_traitForm__IaHJm {
  height: 4rem;
}
.Profile_traitFormBtn__Ejf5d {
  height: 100% !important;
  width: 100%;
  margin: 0.5rem 0;
  border-radius: 12px;
  background-color: white !important;
  border-color: #e2e3e8 !important;
  font-size: 18px;
  font-weight: bold;
  color: #14161b;
}
.Profile_traitFormBtn__Ejf5d:hover,
.Profile_traitFormBtn__Ejf5d:focus {
  border-color: #e2e3e8 !important;
  background-color: #f4f4f8 !important;
  box-shadow: none !important;
}
.Profile_floatingLabel__agaFf,
.Profile_fileUpload__1ew0j {
  font-size: 15px;
  color: #8c8d92;
}
.Profile_nounYearsFooter__PlUiG {
  font-style: italic;
}
.Profile_nounImg__2Sl0A {
  border-radius: 16px;
}
.Profile_nounImg__2Sl0A:hover {
  cursor: pointer;
  transform: scale(1.01);
}
.Profile_nounWrapper__3A-D2 {
  margin-bottom: 1rem;
}

.Profile_spinner__11cl2 {
  display: flex;
  justify-content: center;
  color: var(--brand-gray-light-text);
}
.EditProfile_modal__YuRVO {
  position: fixed;
  top: 10vh;
  z-index: 100;
  padding: 1rem;
  border-radius: 12px;
  left: calc(50% - 15rem);
  width: 30rem;
  
  height: 410px;
  background: white;
  overflow-x: hidden;
}

.EditProfile_modal__YuRVO h2 {
	font-family: 'Londrina Solid';
	text-align: center;
}

.EditProfile_formSection__2bqEv {
	margin: 5px;
}

.EditProfile_primaryBtn__1OjOI {
  width: 50%;
}

.EditProfile_primaryBtn__1OjOI {
  font-weight: bold;
  margin-bottom: 0.5rem;
  border-radius: 12px;
  background-color: var(--brand-dark-red);
  border: var(--brand-dark-red);
}
.EditProfile_primaryBtn__1OjOI:focus,
.EditProfile_primaryBtn__1OjOI:hover
{
  background-color: var(--brand-dark-red) !important;
  box-shadow: 0 0 0 0.2rem rgb(214, 60, 94, 0.75);
}

.EditProfile_primaryBtn__1OjOI:active {
  background-color: var(--brand-dark-red) !important;
}

.EditProfile_primaryBtn__1OjOI:disabled {
  color: var(--brand-gray-dark-text) !important;
  background-color: var(--brand-gray-background) !important;
  border-color: var(--brand-gray-border) !important;  
  border-style: solid;
}


.EditProfile_displayNounFooter__1wavI {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.EditProfile_displayNounFooter__1wavI span {
  color: white;
  font-weight: bold;
  margin-bottom: 1rem;
}
.EditProfile_displayNounFooter__1wavI button {
  width: 50%;
  background: rgba(255, 255, 255, 0.3);
  border: none;
}

.EditProfile_displayNounFooter__1wavI button:hover,
.EditProfile_displayNounFooter__1wavI button:active,
.EditProfile_displayNounFooter__1wavI button:focus {
  background: rgba(255, 255, 255, 0.4) !important;
  box-shadow: none !important;
}

.EditProfile_nounContainer__2CkkF {
  margin-top: 10px;
}

.EditProfile_nounImg__2nRE6 {
  border-radius: 16px;
}
.EditProfile_nounWrapper__1z2q_ {
  margin-bottom: 0.1rem;
}
.EditProfile_nounOuterWrapper__K00wF {
  font-size: small;
  margin-bottom: 0.5rem;
}

.EditProfile_nounImg__2nRE6:hover {
  cursor: pointer;
  transform: scale(1.01);
}

@media (max-width: 992px) {
  .EditProfile_modal__YuRVO {
    width: 80% !important;
    left: 10% !important;
  }
}

.Composer_headerRow__3Vw_T {
  margin: 2rem 0;
  margin-bottom: 0;
}
.Composer_headerRow__3Vw_T span {
  color: #8c8d92;
  font-size: 24px;
  font-family: 'Londrina Solid';
}
.Composer_headerRow__3Vw_T h1 {
  color: #14161b;
  font-size: 56px;
  font-family: 'Londrina Solid';
}
.Composer_headerRow__3Vw_T p {
  color: #14161b;
  padding-top: 0;
}

.Composer_categoryHeader__2GirF  {
  color: #14161b;
  font-family: 'Londrina Solid';
}

.Composer_droppableItemIconNew__32X3k svg {
  margin-top: 100%;
}

.Composer_spanFooter__OJuN1 a {
  text-decoration: none;
  font-weight: bold;
}

.Composer_bioGroup__1EYn5 {
  /*
  padding-right: 5px;
  padding-left: 5px;
  */
  padding: 5px;
}
.Composer_bioGroup__1EYn5 a {
  font-size: 1rem;
}

.Composer_bioGroup__1EYn5 img {
  /*border-radius: 50%;*/
  width: 50%;
  max-width: 64px;

  border: 1px solid #000;
  box-shadow: 2px 5px 5px 2px rgb(0 0 0 / 25%);
  border-radius: 12px;  
}

.Composer_marginBottom__2s-WW {
  margin-bottom: 0rem;
}

.Composer_bioGroup__1EYn5 {
  /*margin-bottom: 2rem;*/
  text-align: center;
  /* 
  margin: 0.5rem;
  border: 1px solid #000;
  box-shadow: 2px 5px 5px 2px rgb(0 0 0 / 25%);
  border-radius: 12px;  
  */
}
.Composer_bioGroup__1EYn5 a:hover svg {
  fill: var(--brand-dark-red);
}
.Composer_bioGroup__1EYn5 img {
  margin-bottom: 1rem;
}

.Composer_twitterIcon__3llz3 {
  width: 20px;
  color: #aaa;
  margin-right: 5px;
}

.Composer_card__2fnJC {
  border: none;
}

.Composer_card__2fnJC,
.Composer_headerWrapper__3s1dA {
  font-size: 1.3rem;
}
.Composer_cardHeader__uM8r4 {
  border: none;
  background-color: transparent;
  font-family: 'Londrina Solid';
  font-size: 2.5rem;
  cursor: pointer;
}

.Composer_cardTitle__23Ftz {
    margin-bottom: 0.5rem;
    font-size: 1rem;
}

.Composer_primaryBtnSelecter__318XZ {
  margin-bottom: 0.5rem;	
}

.Composer_primaryBtnSaver__aPQvK {
}

.Composer_primaryBtnUploader__2KBq5 {
  margin-top: 0.5rem;	
}

.Composer_primaryTxtSearch__Q-cf3 {
  width: 67%;
  max-width: 200px;
  border-radius: 12px;
}

.Composer_primaryBtnSearch__fxp96 {
  width: 33% !important;
  max-width: 100px !important;
  border-radius: 12px;
}

.Composer_primaryBtn__2JBh5, .Composer_primaryBtnSelecter__318XZ, .Composer_primaryBtnSaver__aPQvK, .Composer_primaryBtnUploader__2KBq5, .Composer_primaryBtnSearch__fxp96 {
  width: 50%;
  height: 2.5rem;
  font-weight: bold;
  border-radius: 12px;
  background-color: var(--brand-dark-red);
  border: var(--brand-dark-red);
  max-width: 150px;
}
.Composer_primaryBtn__2JBh5:focus,
.Composer_primaryBtn__2JBh5:hover,
.Composer_primaryBtnSelecter__318XZ:focus,
.Composer_primaryBtnSelecter__318XZ:hover,
.Composer_primaryBtnSaver__aPQvK:focus,
.Composer_primaryBtnSaver__aPQvK:hover,
.Composer_primaryBtnUploader__2KBq5:focus,
.Composer_primaryBtnUploader__2KBq5:hover,
.Composer_primaryBtnSearch__fxp96:focus,
.Composer_primaryBtnSearch__fxp96:hover
 {
  background-color: var(--brand-dark-red) !important;
  box-shadow: 0 0 0 0.2rem rgb(214, 60, 94, 0.75);
}

.Composer_primaryBtn__2JBh5:active,
.Composer_primaryBtnSelecter__318XZ:active,
.Composer_primaryBtnSaver__aPQvK:active,
.Composer_primaryBtnUploader__2KBq5:active,
.Composer_primaryBtnSearch__fxp96:active {
  background-color: var(--brand-dark-red) !important;
}

.Composer_primaryBtn__2JBh5:disabled,
.Composer_primaryBtnSelecter__318XZ:disabled,
.Composer_primaryBtnSaver__aPQvK:disabled,
.Composer_primaryBtnUploader__2KBq5:disabled,
.Composer_primaryBtnSearch__fxp96:disabled {
  color: var(--brand-gray-dark-text) !important;
  background-color: var(--brand-gray-background) !important;
  border-color: var(--brand-gray-border) !important;  
  border-style: solid;
}

.Composer_traitForm__2tGWs {
  height: 4rem;
}
.Composer_traitFormBtn__paE7t {
  height: 100% !important;
  width: 100%;
  margin: 0.5rem 0;
  border-radius: 12px;
  background-color: white !important;
  border-color: #e2e3e8 !important;
  font-size: 18px;
  font-weight: bold;
  color: #14161b;
}
.Composer_traitFormBtn__paE7t:hover,
.Composer_traitFormBtn__paE7t:focus {
  border-color: #e2e3e8 !important;
  background-color: #f4f4f8 !important;
  box-shadow: none !important;
}
.Composer_floatingLabel__3sQzl,
.Composer_fileUpload__Up1ss {
  font-size: 15px;
  color: #8c8d92;
}
.Composer_nounYearsFooter__3C15Q {
  font-style: italic;
}
.Composer_dropList__4Cg4G {
  flex-wrap: wrap;
}
.Composer_nounImgDragNew__3tPWt {

  height: 60px;
  width: 60px;
  max-width: 100px;
  max-height: 100px;

  border-radius: 16px;

  border: 1px solid #000;
  box-shadow: 2px 5px 5px 2px rgb(0 0 0 / 25%);
  border-radius: 12px;
  
  left: 25%;
  top: 5%;
}
.Composer_nounImgDragHighlightNew__ZxxLW {

  height: 60px;
  width: 60px;
  max-width: 100px;
  max-height: 100px;

  border-radius: 16px;

  border: 2px solid #000;
  box-shadow: 2px 5px 5px 2px rgb(0 0 0 / 25%);
  border-radius: 12px;
  
  left: 25%;
  top: 5%;
}

.Composer_nounWrapperDragNew__zSKGy {
  margin-bottom: 0rem;
  padding-top: 70%;  
}


.Composer_nounImgDrag__3SPH2 {

  height: 80px;
  width: 80px;
  max-width: 100px;
  max-height: 100px;

  border-radius: 16px;

  border: 1px solid #000;
  box-shadow: 2px 5px 5px 2px rgb(0 0 0 / 25%);
  border-radius: 12px;
  
  left: 15%;
  top: 5%;
}
.Composer_nounImgDragHighlight__2kH3W {

  height: 80px;
  width: 80px;
  max-width: 100px;
  max-height: 100px;

  border-radius: 16px;

  border: 2px solid #000;
  box-shadow: 2px 5px 5px 2px rgb(0 0 0 / 25%);
  border-radius: 12px;
  
  left: 15%;
  top: 5%;
}
.Composer_nounImg__EPUZA {
  border-radius: 16px;
}
.Composer_nounImg__EPUZA:hover {
  cursor: pointer;
  transform: scale(1.01);
}
.Composer_nounWrapper__3HbI0 {
  margin-bottom: 1rem;
}
.Composer_nounWrapperDrag__3ZQee {
  margin-bottom: 0rem;
  padding-top: 90%;  
}
.Composer_txtImageDrag__2HS1_ {
  padding: 0px;
  margin: 0px;
  text-align: center;
}

.Composer_spinner__nXnnk {
  display: flex;
  justify-content: center;
  color: var(--brand-gray-light-text);
}

@media (max-width: 992px) {
  .Composer_modal__j5Zci {
    width: 80% !important;
    left: 10% !important;
  }
  
}

.Pagination_paginationBar__3UnNQ {
  width: 100%;
  display: flex;
  justify-content: center;
}

.Pagination_paginationHeader__10iuv {
  width: 100%;
  justify-content: center;
  text-align: center;
}

.Pagination_paginationHeader__10iuv span {
  font-size: 13px;
  font-weight: bold;	
}

.Pagination_paginationContainer__FQdl_ {
  display: flex;
  list-style-type: none;
}

.Pagination_paginationItem__PU1Qg {
    padding: 0 12px;
    height: 32px;
    text-align: center;
    margin: auto 4px;
    color: rgba(0, 0, 0, 0.87);
    display: flex;
    box-sizing: border-box;
    align-items: center;
    letter-spacing: 0.01071em;
    border-radius: 16px;
    line-height: 1.43;
    font-size: 13px;
    min-width: 32px;
}

.Pagination_paginationItem__PU1Qg:hover {
	background-color: rgba(0, 0, 0, 0.04);
    cursor: pointer;
}

.Pagination_selected__2wwa8 {
	background-color: rgba(0, 0, 0, 0.08);
}

.Pagination_dots__1FtzW:hover {
	background-color: transparent;
	cursor: default;
}

.Pagination_selected__2wwa8 {
	background-color: rgba(0, 0, 0, 0.08);
}

.Pagination_arrow__3na1i::before {
	position: relative;
	/* top: 3pt; Uncomment this to lower the icons as requested in comments*/
	content: '';
	/* By using an em scale, the arrows will size with the font */
	display: inline-block;
	width: 0.4em;
	height: 0.4em;
	border-right: 0.12em solid rgba(0, 0, 0, 0.87);
	border-top: 0.12em solid rgba(0, 0, 0, 0.87);
}

.Pagination_left__JXZwh {
	transform: rotate(-135deg) translate(-50%);
}

.Pagination_right__1sUVZ {
	transform: rotate(45deg);
}

.Pagination_disabled__38iGE {
	pointer-events: none;
}

.Pagination_disabled__38iGE .Pagination_arrow__3na1i::before {
	border-right: 0.12em solid rgba(0, 0, 0, 0.43);
	border-top: 0.12em solid rgba(0, 0, 0, 0.43);
}

.Pagination_disbled__3ZUul:hover {
	background-color: transparent;
	cursor: default;
}

.NounModal_modal__4uqPE {
  position: fixed;
  top: 10vh;
  z-index: 100;
  padding: 2rem;
  text-align: center;
  border-radius: 15px;
  left: calc(50% - 15rem);
  width: 30rem;
}

.NounModal_displayNounFooter__2WIAf {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.NounModal_displayNounFooter__2WIAf span {
  color: white;
  font-weight: bold;
  margin-bottom: 1rem;
}
.NounModal_displayNounFooter__2WIAf button {
  width: 50%;
  background: rgba(255, 255, 255, 0.3);
  border: none;
}

.NounModal_displayNounFooter__2WIAf button:hover,
.NounModal_displayNounFooter__2WIAf button:active,
.NounModal_displayNounFooter__2WIAf button:focus {
  background: rgba(255, 255, 255, 0.4) !important;
  box-shadow: none !important;
}

.NounModal_nounImg__q_OdM {
  border-radius: 16px;
}
.NounModal_nounWrapper__yWVm- {
  margin-bottom: 1rem;
}

@media (max-width: 992px) {
  .NounModal_modal__4uqPE {
    width: 80% !important;
    left: 10% !important;
  }
}

.NounPicker_modal__2cpnp {
  position: fixed;
  top: 10vh;
  z-index: 100;
  padding: 1rem;
  text-align: center;
  border-radius: 12px;
  left: calc(50% - 15rem);
  width: 30rem;
  
  height: 500px;
  background: white;
  overflow-y: auto;
  font-weight: bold;
}

.NounPicker_displayNounFooter___1ns0 {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.NounPicker_displayNounFooter___1ns0 span {
  color: white;
  font-weight: bold;
  margin-bottom: 1rem;
}
.NounPicker_displayNounFooter___1ns0 button {
  width: 50%;
  background: rgba(255, 255, 255, 0.3);
  border: none;
}

.NounPicker_displayNounFooter___1ns0 button:hover,
.NounPicker_displayNounFooter___1ns0 button:active,
.NounPicker_displayNounFooter___1ns0 button:focus {
  background: rgba(255, 255, 255, 0.4) !important;
  box-shadow: none !important;
}

.NounPicker_nounContainer__3eeml {
  margin-top: 10px;
}

.NounPicker_nounImg__3wHB1 {
  border-radius: 16px;
}
.NounPicker_nounWrapper__2KMGp {
  margin-bottom: 0.1rem;
}
.NounPicker_nounOuterWrapper__3yCMp {
  font-size: small;
  margin-bottom: 0.5rem;
}

.NounPicker_nounImg__3wHB1:hover {
  cursor: pointer;
  transform: scale(1.01);
}

@media (max-width: 992px) {
  .NounPicker_modal__2cpnp {
    width: 80% !important;
    left: 10% !important;
  }
}

.SaveModal_modal__3qqe7 {
  position: fixed;
  top: 10vh;
  z-index: 100;
  padding: 1rem;
  border-radius: 12px;
  left: calc(50% - 15rem);
  width: 35rem;
  
  height: 500px;
  background: white;
  overflow-x: hidden;
  overflow-y: auto;
}

.SaveModal_modal__3qqe7 .SaveModal_title__YXGZi {
  font-family: 'Londrina Solid';
  display: flex;
  flex-direction: column;
}

.SaveModal_modal__3qqe7 .SaveModal_title__YXGZi h2 {
  font-size: 24px;
  margin-top: 0.5rem;
  color: rgba(140, 141, 146, 1);
}

.SaveModal_modal__3qqe7 .SaveModal_title__YXGZi h1 {
  line-height: 25px;
  height: 2rem;
  font-size: 42px;
}

.SaveModal_formSection__3gWkA {
	/*margin: 5px;*/
}

.SaveModal_itemRows__2wp6s {
  border: 1px solid #e2e3e8;
  margin: 0.5rem 0;
  border-radius: 12px;  
  padding: 0.5rem;
}

.SaveModal_itemRows__2wp6s img {
  border: 1px solid #000;
  border-radius: 12px;  
}

.SaveModal_itemGroup__25JXX {
  text-align: center;
}

.SaveModal_imageSection__uBnDk img {
  border: 1px solid #000;
  box-shadow: 2px 5px 5px 2px rgb(0 0 0 / 25%);
  border-radius: 12px;  
}


.SaveModal_primaryBtn__24yRl {
  width: 50%;
}

.SaveModal_primaryBtn__24yRl {
  font-weight: bold;
  margin-bottom: 0.5rem;
  border-radius: 12px;
  background-color: var(--brand-dark-red);
  border: var(--brand-dark-red);
}
.SaveModal_primaryBtn__24yRl:focus,
.SaveModal_primaryBtn__24yRl:hover
{
  background-color: var(--brand-dark-red) !important;
  box-shadow: 0 0 0 0.2rem rgb(214, 60, 94, 0.75);
}

.SaveModal_primaryBtn__24yRl:active {
  background-color: var(--brand-dark-red) !important;
}

.SaveModal_primaryBtn__24yRl:disabled {
  color: var(--brand-gray-dark-text) !important;
  background-color: var(--brand-gray-background) !important;
  border-color: var(--brand-gray-border) !important;  
  border-style: solid;
}


.SaveModal_displayNounFooter__3N0oG {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.SaveModal_displayNounFooter__3N0oG span {
  color: white;
  font-weight: bold;
  margin-bottom: 1rem;
}
.SaveModal_displayNounFooter__3N0oG button {
  width: 50%;
  background: rgba(255, 255, 255, 0.3);
  border: none;
}

.SaveModal_displayNounFooter__3N0oG button:hover,
.SaveModal_displayNounFooter__3N0oG button:active,
.SaveModal_displayNounFooter__3N0oG button:focus {
  background: rgba(255, 255, 255, 0.4) !important;
  box-shadow: none !important;
}

.SaveModal_nounContainer__380Yv {
  margin-top: 10px;
}

.SaveModal_nounImg__3kePK {
  border-radius: 16px;
}
.SaveModal_nounWrapper__VhRUg {
  margin-bottom: 0.1rem;
}
.SaveModal_nounOuterWrapper__1HY_s {
  font-size: small;
  margin-bottom: 0.5rem;
}

.SaveModal_nounImg__3kePK:hover {
  cursor: pointer;
  transform: scale(1.01);
}

@media (max-width: 992px) {
  .SaveModal_modal__3qqe7 {
    width: 80% !important;
    left: 10% !important;
  }
}

.ComposerTour_primaryBtnTour__3Xt4h {
  background-color: var(--brand-dark-red) !important;
  border: var(--brand-dark-red) !important;
  font-weight: bold;
  color: #fff !important;
  border-radius: 12px !important;
}

.ComposerTour_secondaryBtnTour__14FQd {
  color: var(--brand-gray-dark-text) !important;
  background-color: var(--brand-gray-background) !important;
  border-color: var(--brand-gray-border) !important;  
  border-style: solid !important;
  border-width: thin !important;
  border-radius: 12px !important;
}

.ComposerTour_primaryBtnTour__3Xt4h:focus,
.ComposerTour_primaryBtnTour__3Xt4h:hover
 {
  background-color: var(--brand-dark-red) !important;
  box-shadow: 0 0 0 0.2rem rgb(214, 60, 94, 0.75);
}

.ComposerTour_primaryBtnTour__3Xt4h:active {
  background-color: var(--brand-dark-red) !important;
}

/* stylelint-disable selector-max-id */

#shepherdModalOverlayContainer {
  -ms-filter: "progid:dximagetransform.microsoft.gradient.alpha(Opacity=50)";
  filter: alpha(opacity=50);
  height: 100%;
  left: 0;
  opacity: 0.5;
  position: fixed;
  top: 0;
  transition: all 0.3s ease-out;
  width: 100%;
  z-index: 9997;
}

.shepherd-modal.shepherd-enabled {
  position: relative;
  z-index: 9998;
}

/**
 * Block clicks except for those that would occur
 * on Shepherd elements or on the target element.
*/

.shepherd-active.shepherd-modal-is-visible :not(.shepherd-target) {
  pointer-events: none;
}

.shepherd-active.shepherd-modal-is-visible .shepherd-target, .shepherd-active.shepherd-modal-is-visible .shepherd-cancel-link, .shepherd-active.shepherd-modal-is-visible .shepherd-button {
  pointer-events: auto;
}

/* stylelint-enable */

/**
 * Styles to support usage of Tippy.js tooltips (https://atomiks.github.io/tippyjs)
 */

/**
 * Tippy tooltips have the following base structure:
 *
 * <div class="tippy-popper" x-placement="top">
 *   <div class="tippy-tooltip">
 *     <div class="tippy-content">
 *       [[ Content Here ]]
 *     </div>
 *   </div>
 * </div>
 */

.tippy-popper {
  z-index: 9999;
}

.tippy-popper .tippy-arrow {
  border: 16px solid transparent;
  content: '';
  display: block;
  height: 0;
  pointer-events: none;
  position: absolute;
  width: 0;
}

.tippy-popper[x-placement^='top'] {
  margin-bottom: 16px;
}

.tippy-popper[x-placement^='top'] .tippy-arrow {
  border-bottom: 0;
  border-top-color: #ffffff;
  left: calc(50% - 16px);
}

.tippy-popper[x-placement^='bottom'] {
  margin-top: 16px;
}

.tippy-popper[x-placement^='bottom'] .tippy-arrow {
  border-bottom-color: #eeeeee;
  border-top: 0;
  left: calc(50% - 16px);
}

.tippy-popper[x-placement^='left'] {
  margin-right: 16px;
}

.tippy-popper[x-placement^='left'] .tippy-arrow {
  border-left-color: #ffffff;
  border-right: 0;
  margin-top: -16px;
  top: calc(50% - 16px);
}

.tippy-popper[x-placement^='right'] {
  margin-left: 16px;
}

.tippy-popper[x-placement^='right'] .tippy-arrow {
  border-left: 0;
  border-right-color: #ffffff;
  top: calc(50% - 16px);
}

.tippy-popper .tippy-tooltip {
  background-color: rgba(0, 0, 0, 0);
  max-height: 100%;
  max-width: 100%;
  padding: 0;
  width: 420px;
}

.shepherd-element, .shepherd-element:after, .shepherd-element:before, .shepherd-element *, .shepherd-element *:after, .shepherd-element *:before {
  box-sizing: border-box;
}

.shepherd-element {
  filter: drop-shadow(0 1px 4px rgba(0, 0, 0, 0.2));
}

.shepherd-element.shepherd-has-title .shepherd-content header {
  background: #eeeeee;
  padding: 1em;
}

.shepherd-element .shepherd-content {
  background: #ffffff;
  font-size: inherit;
  padding: 0;
}

.shepherd-element .shepherd-content header {
  *zoom: 1;
  align-items: center;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  display: flex;
  justify-content: flex-end;
  line-height: 2em;
  padding: 0.75em 0.75em 0;
}

.shepherd-element .shepherd-content header:after {
  clear: both;
  content: '';
  display: table;
}

.shepherd-element .shepherd-content header .shepherd-title, .shepherd-element .shepherd-content header .shepherd-cancel-link {
  font-weight: normal;
  margin: 0;
  padding: 0;
  position: relative;
  vertical-align: middle;
}

.shepherd-element .shepherd-content header .shepherd-title {
  color: rgba(0, 0, 0, 0.75);
  display: flex;
  flex: 1 0 auto;
  font-size: 1.1em;
  font-weight: bold;
}

.shepherd-element .shepherd-content header .shepherd-cancel-link {
  color: rgba(179, 179, 179, 0.75);
  font-size: 2em;
  margin-left: auto;
  text-decoration: none;
  transition: color 0.5s ease;
}

.shepherd-element .shepherd-content header .shepherd-cancel-link:before {
  content: '\00d7';
}

.shepherd-element .shepherd-content header .shepherd-cancel-link:hover {
  color: rgba(0, 0, 0, 0.75);
}

.shepherd-element .shepherd-content .shepherd-text {
  color: rgba(0, 0, 0, 0.75);
  line-height: 1.3em;
  padding: 0.75em;
}

.shepherd-element .shepherd-content .shepherd-text a, .shepherd-element .shepherd-content .shepherd-text a:visited, .shepherd-element .shepherd-content .shepherd-text a:active {
  border-bottom: 1px dotted;
  border-bottom-color: rgba(0, 0, 0, 0.75);
  color: rgba(0, 0, 0, 0.75);
  text-decoration: none;
}

.shepherd-element .shepherd-content .shepherd-text a:hover, .shepherd-element .shepherd-content .shepherd-text a:visited:hover, .shepherd-element .shepherd-content .shepherd-text a:active:hover {
  border-bottom-style: solid;
}

.shepherd-element .shepherd-content .shepherd-text p {
  margin-top: 0;
}

.shepherd-element .shepherd-content .shepherd-text p:last-child {
  margin-bottom: 0;
}

.shepherd-element .shepherd-content footer {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 0 0.75em 0.75em;
}

.shepherd-element .shepherd-content footer .shepherd-buttons {
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: right;
}

.shepherd-element .shepherd-content footer .shepherd-buttons li {
  display: inline;
  margin: 0 0.5em 0 0;
}

.shepherd-element .shepherd-content footer .shepherd-buttons li:last-child {
  margin-right: 0;
}

.shepherd-element .shepherd-content footer .shepherd-buttons li .shepherd-button {
  display: inline-block;
  *display: inline;
  vertical-align: middle;
  *vertical-align: auto;
  *zoom: 1;
  background: #557ca8;
  border: 0;
  border-radius: 3px;
  color: rgba(255, 255, 255, 0.75);
  cursor: pointer;
  font-family: inherit;
  font-size: 0.8em;
  letter-spacing: 0.1em;
  line-height: 1em;
  padding: 0.75em 2em;
  text-transform: uppercase;
  transition: all 0.5s ease;
}

.shepherd-element .shepherd-content footer .shepherd-buttons li .shepherd-button:hover {
  background: #0a1632;
}

.shepherd-element .shepherd-content footer .shepherd-buttons li .shepherd-button.shepherd-button-secondary {
  background: #e5e5e5;
  color: rgba(0, 0, 0, 0.75);
}

.shepherd-element .shepherd-content footer .shepherd-buttons li .shepherd-button.shepherd-button-secondary:hover {
  background: #cbcbcb;
  color: rgba(0, 0, 0, 0.75);
}

.Market_headerRow__1JCjT {
  margin: 2rem 0;
}
.Market_headerRow__1JCjT span {
  color: #8c8d92;
  font-size: 24px;
  font-family: 'Londrina Solid';
}
.Market_headerRow__1JCjT h1 {
  color: #14161b;
  font-size: 56px;
  font-family: 'Londrina Solid';
}
.Market_headerRow__1JCjT p {
  color: #14161b;
}

.Market_categoryHeader__MTnNL  {
  color: #14161b;
  font-family: 'Londrina Solid';
}


.Market_itemGroup__3EB5j {
  /*
  padding-right: 5px;
  padding-left: 5px;
  */
  padding: 5px;
}
.Market_itemGroup__3EB5j a {
  font-size: 1rem;
}

.Market_itemGroup__3EB5j img {
  /*border-radius: 50%;*/
  width: 50%;
  max-width: 100px;

  border: 1px solid #000;
  box-shadow: 2px 5px 5px 2px rgb(0 0 0 / 25%);
  border-radius: 12px;  
}

.Market_searchFooter__ggsn_ a {
  text-decoration: none;
  font-weight: bold;
}

.Market_marginBottom__CvoHZ {
  margin-bottom: 0rem;
}

.Market_itemGroup__3EB5j {
  margin-bottom: 2rem;
  text-align: center;
  /* 
  margin: 0.5rem;
  border: 1px solid #000;
  box-shadow: 2px 5px 5px 2px rgb(0 0 0 / 25%);
  border-radius: 12px;  
  */
}
.Market_itemGroup__3EB5j a:hover svg {
  fill: var(--brand-dark-red);
}
.Market_itemGroup__3EB5j img {
  margin-bottom: 0.5rem;
}

.Market_twitterIcon__1DNQ7 {
  width: 20px;
  color: #aaa;
  margin-right: 5px;
}

.Market_card__28A35 {
  border: none;
}

.Market_card__28A35,
.Market_headerWrapper__3lb7I {
  font-size: 1.3rem;
}
.Market_cardHeader__2cgnu {
  border: none;
  background-color: transparent;
  font-family: 'Londrina Solid';
  font-size: 2.5rem;
  cursor: pointer;
}

.Market_cardTitle__16Uvd {
    margin-bottom: 0;
    font-size: 1rem;
}

.Market_cardText__1EvaG {
    padding-top: 0;
}

.Market_primaryBtnItem__kiQJi {
  width: 50%;
  height: 2rem;
}

.Market_primaryTxtSearch__378q4 {
  width: 67%;
  max-width: 200px;
  border-radius: 12px;
}

.Market_primaryBtnSearch__1nozc {
  width: 33%;
  max-width: 100px;
  border-radius: 12px;
}

.Market_primaryBtn__zx0B0, .Market_primaryBtnItem__kiQJi, .Market_primaryBtnSearch__1nozc {
  font-weight: bold;
  margin-bottom: 0rem;
  background-color: var(--brand-dark-red);
  border: var(--brand-dark-red);
}
.Market_primaryBtn__zx0B0:focus,
.Market_primaryBtn__zx0B0:hover,
.Market_primaryBtnItem__kiQJi:focus,
.Market_primaryBtnItem__kiQJi:hover,
.Market_primaryBtnSearch__1nozc:focus,
.Market_primaryBtnSearch__1nozc:hover 

{
  background-color: var(--brand-dark-red) !important;
  box-shadow: 0 0 0 0.2rem rgb(214, 60, 94, 0.75);
}

.Market_primaryBtn__zx0B0:active,
.Market_primaryBtnItem__kiQJi:active,
.Market_primaryBtnSearch__1nozc:active {
  background-color: var(--brand-dark-red) !important;
}

.Market_primaryBtn__zx0B0:disabled,
.Market_primaryBtnItem__kiQJi:disabled,
.Market_primaryBtnSearch__1nozc:disabled {
  color: var(--brand-gray-dark-text) !important;
  background-color: var(--brand-gray-background) !important;
  border-color: var(--brand-gray-border) !important;  
  border-style: solid;
}


.Market_traitForm__2LsXQ {
  height: 4rem;
}
.Market_traitFormBtn__3QCg7 {
  height: 100% !important;
  width: 100%;
  margin: 0.5rem 0;
  border-radius: 12px;
  background-color: white !important;
  border-color: #e2e3e8 !important;
  font-size: 18px;
  font-weight: bold;
  color: #14161b;
}
.Market_traitFormBtn__3QCg7:hover,
.Market_traitFormBtn__3QCg7:focus {
  border-color: #e2e3e8 !important;
  background-color: #f4f4f8 !important;
  box-shadow: none !important;
}
.Market_floatingLabel__jLSzH,
.Market_fileUpload__3pmJ4 {
  font-size: 15px;
  color: #8c8d92;
}
.Market_nounYearsFooter__8AQjL {
  font-style: italic;
}
.Market_nounImg__3q_EZ {
  border-radius: 16px;
}
.Market_nounImg__3q_EZ:hover {
  cursor: pointer;
  transform: scale(1.01);
}
.Market_nounWrapper__1QM7T {
  margin-bottom: 1rem;
}

.Market_spinner__f_J7h {
  display: flex;
  justify-content: center;
  color: var(--brand-gray-light-text);
}
