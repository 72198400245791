.shortAddress {
  display: flex;
  flex-flow: row nowrap;
  gap: 6px;
  align-items: center;
}

.shortAddressLink {
  display: flex;
  flex-flow: row nowrap;
  gap: 6px;
  align-items: center;
  margin-bottom: 5px;
}


.shortAddress > div {
  padding-top: -2px;
}

.shortAddress, .shortAddressLink span {
  letter-spacing: 0.2px;
  font-family: 'PT Root UI';
  font-weight: bold;
}

.shortAddress, .shortAddressLink a {
  text-decoration: none;
  color: inherit;
}
