.modal {
  position: fixed;
  top: 10vh;
  z-index: 100;
  padding: 1rem;
  border-radius: 12px;
  left: calc(50% - 15rem);
  width: 30rem;
  
  height: 410px;
  background: white;
  overflow: hidden;
}

.modal h2 {
	font-family: 'Londrina Solid';
	text-align: center;
}

.formSection {
	margin: 5px;
}

.primaryBtn {
  width: 50%;
}

.primaryBtn {
  font-weight: bold;
  margin-bottom: 0.5rem;
  border-radius: 12px;
  background-color: var(--brand-dark-red);
  border: var(--brand-dark-red);
}
.primaryBtn:focus,
.primaryBtn:hover
{
  background-color: var(--brand-dark-red) !important;
  box-shadow: 0 0 0 0.2rem rgb(214, 60, 94, 0.75);
}

.primaryBtn:active {
  background-color: var(--brand-dark-red) !important;
}

.primaryBtn:disabled {
  color: var(--brand-gray-dark-text) !important;
  background-color: var(--brand-gray-background) !important;
  border-color: var(--brand-gray-border) !important;  
  border-style: solid;
}


.displayNounFooter {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.displayNounFooter span {
  color: white;
  font-weight: bold;
  margin-bottom: 1rem;
}
.displayNounFooter button {
  width: 50%;
  background: rgba(255, 255, 255, 0.3);
  border: none;
}

.displayNounFooter button:hover,
.displayNounFooter button:active,
.displayNounFooter button:focus {
  background: rgba(255, 255, 255, 0.4) !important;
  box-shadow: none !important;
}

.nounContainer {
  margin-top: 10px;
}

.nounImg {
  border-radius: 16px;
}
.nounWrapper {
  margin-bottom: 0.1rem;
}
.nounOuterWrapper {
  font-size: small;
  margin-bottom: 0.5rem;
}

.nounImg:hover {
  cursor: pointer;
  transform: scale(1.01);
}

@media (max-width: 992px) {
  .modal {
    width: 80% !important;
    left: 10% !important;
  }
}
