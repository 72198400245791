.nounWrapper {
  align-self: flex-end;
  width: 100%;
}

.nounContentCol {
  display: flex;
}

.nounWrapper img {
  /*
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  */
  position: initial;
  top: auto;
  left: auto;
  width: 100%;
  height: 100%;
  max-height: 450px; 
  max-width: 450px; 
  vertical-align: middle;
}

.nounWrapper div {
  /*
  position: relative;
  padding-top: 100%;
  width: 100%;
  height: 0;
  */
  position: initial;
  padding-top: 0;
  width: 100%;
  height: auto;
  text-align: center;
}

.auctionActivityCol {
  padding-left: 5rem;
  padding-bottom: 0rem;
  min-height: 450px; /*520px*/
  align-self: flex-end !important;
  padding-top: 150px;

}

@media (max-width: 992px) {
  .nounWrapper {
    width: 70%;
    margin-left: 15%;
    margin-right: 15%;
  }
  .auctionActivityCol {
    padding-top: 5%;
    padding-right: 5%;
    padding-left: 5%;
    width: 100%;
    text-align: center;
    min-height: 250px; /*520px*/
  }
}

@media (max-width: 568px) {
  .auctionActivityCol {
    width: 100%;
    margin-left: unset;
    margin-right: unset;
    padding-bottom: 0rem;
    padding-top: 2rem;
    padding-left: unset;
    padding-right: 0;
  }

  .nounWrapper {
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 0rem;
  }

  .nounContentCol {
    padding: 0rem;
  }
}
