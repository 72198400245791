.headerRow {
  margin: 0.5rem 0;
}
.headerRow span {
  color: #8c8d92;
  font-size: 24px;
  font-family: 'Londrina Solid';
}
.headerRow h1 {
  color: #14161b;
  font-size: 56px;
  font-family: 'Londrina Solid';
  margin: 0;
  padding: 0;
}
.headerRow p {
  color: #14161b;
}

.headerBannerRow {
  margin: 0.5rem 0;
}

.bannerRow {
  position: relative;
}

.bannerImage {
  width: 100%;
  min-height: 100px;
  max-height: 200px;
  object-fit: cover;
}

.thumbnailImage {
  position: absolute;
  height: 100px;
  width: 100px;
  left: 10px;
  bottom: -10px;
  
  border: 1px solid #fff;
  box-shadow: 2px 5px 5px 2px rgb(0 0 0 / 25%);
  border-radius: 12px;    
}

.descriptionRow {
  margin: 0.5rem 0;	
}

.categoryHeader  {
  color: #14161b;
  font-family: 'Londrina Solid';
}

.itemRow {
  border: 1px solid #e2e3e8;
  margin: 0.5rem 0;
  border-radius: 12px;  
  padding: 0.5rem;
}

.itemGroup {
  /*
  padding-right: 5px;
  padding-left: 5px;
  */
  padding: 5px;
}
.itemGroup a {
  font-size: 1rem;
}

.itemGroup img {
  /*border-radius: 50%;*/
  width: 50%;
  max-width: 100px;

  border: 1px solid #000;
  box-shadow: 2px 5px 5px 2px rgb(0 0 0 / 25%);
  border-radius: 12px;  
}

.marginBottom {
  margin-bottom: 0rem;
}

.itemGroup {
  margin-bottom: 0rem;
  text-align: center;
  /* 
  margin: 0.5rem;
  border: 1px solid #000;
  box-shadow: 2px 5px 5px 2px rgb(0 0 0 / 25%);
  border-radius: 12px;  
  */
}
.itemGroup a:hover svg {
  fill: var(--brand-dark-red);
}
.itemGroup img {
  margin-bottom: 0.5rem;
}

.twitterIcon {
  width: 20px;
  color: #aaa;
  margin-right: 5px;
}

.card {
  border: none;
}

.card,
.headerWrapper {
  font-size: 1.3rem;
}
.cardHeader {
  border: none;
  background-color: transparent;
  font-family: 'Londrina Solid';
  font-size: 2.5rem;
  cursor: pointer;
}

.cardTitle {
    margin-bottom: 0;
    font-size: 1rem;
}

.cardText {
    padding-top: 0;
}

.primaryBtnItem {
  width: 50%;
  height: 2rem;
}

.primaryBtnSearch {
  width: 100%;
  height: 3rem;
}

.primaryBtnUploader {
  max-width: 200px;
}


.primaryBtn, .primaryBtnItem, .primaryBtnUploader {
  font-weight: bold;
  margin-bottom: 0.5rem;
  border-radius: 12px;
  background-color: var(--brand-dark-red);
  border: var(--brand-dark-red);
}
.primaryBtn:focus,
.primaryBtn:hover,
.primaryBtnItem:focus,
.primaryBtnItem:hover,
.primaryBtnUploader:focus,
.primaryBtnUploader:hover 

{
  background-color: var(--brand-dark-red) !important;
  box-shadow: 0 0 0 0.2rem rgb(214, 60, 94, 0.75);
}

.primaryBtn:active,
.primaryBtnItem:active,
.primaryBtnUploader:active {
  background-color: var(--brand-dark-red) !important;
}

.primaryBtn:disabled,
.primaryBtnItem:disabled,
.primaryBtnUploader:disabled {
  color: var(--brand-gray-dark-text) !important;
  background-color: var(--brand-gray-background) !important;
  border-color: var(--brand-gray-border) !important;  
  border-style: solid;
}


.traitForm {
  height: 4rem;
}
.traitFormBtn {
  height: 100% !important;
  width: 100%;
  margin: 0.5rem 0;
  border-radius: 12px;
  background-color: white !important;
  border-color: #e2e3e8 !important;
  font-size: 18px;
  font-weight: bold;
  color: #14161b;
}
.traitFormBtn:hover,
.traitFormBtn:focus {
  border-color: #e2e3e8 !important;
  background-color: #f4f4f8 !important;
  box-shadow: none !important;
}
.floatingLabel,
.fileUpload {
  font-size: 15px;
  color: #8c8d92;
}
.nounYearsFooter {
  font-style: italic;
}
.nounImg {
  border-radius: 16px;
}
.nounImg:hover {
  cursor: pointer;
  transform: scale(1.01);
}
.nounWrapper {
  margin-bottom: 1rem;
}

.spinner {
  display: flex;
  justify-content: center;
  color: var(--brand-gray-light-text);
}