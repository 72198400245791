.wrapper h1 {
  font-family: 'Londrina Solid';
  font-size: 5rem;
}

.bannerSection h4 {
  font-family: 'PT Root UI';
  font-weight: bold;
  font-size: 16px;
  opacity: 1;
  color: rgb(95, 95, 95);  
  margin-bottom: 0px !important;
}

.bannerSection h2 {
  font-family: 'PT Root UI';
  font-weight: bold;
  font-size: 28px;
  margin-bottom: 0px !important;
}

.bannerSection {
  padding: 0 !important;
}

.homeSection {
  padding: 0 !important;
}

.bannerRow {
  justify-content: center;
}

.bannerSection img {
  /*width: auto;*/
}

.sectionHeader {
  font-family: 'Londrina Solid';
  font-weight: bold;
  font-size: 24px;
  opacity: 1;
  color: rgb(95, 95, 95);  
}

.sectionFooter a {
  text-decoration: none;
  font-weight: bold;
}

.bannerItems {
	max-width: 150px;
	text-align: center;
}

@media (max-width: 992px) {
  .wrapper {
    padding: 2rem;
  }

  .wrapper h1 {
    font-size: 3.75rem;
  }  
}

@media (min-width: 992px) {
  .wrapper h1 {
    font-size: 6rem;
    margin-left: 2rem;
  }
  
  .homeSection {
  	padding: 0 !important;
  }
}